
























import { Component, Prop, Emit, Watch } from 'nuxt-property-decorator'
import { mapGetters } from 'vuex'
import TitleInput from './TitleInput.vue'
import CheckboxInput from './Checkbox.vue'
import BaseInputComponent from './BaseInput.vue'
import Spinner from '~/components/shared/Spinner.vue'

@Component({
  components: {
    TitleInput,
    CheckboxInput,
    Spinner,
  },
  computed: {
    ...mapGetters('filters', {
      areFilterloading: 'areFilterloading',
    }),
  },
})
export default class CheckboxGroupInput extends BaseInputComponent {
  @Prop({ default: (): any[] => [] }) options: any[]
  @Prop({ default: false }) singleSelection: boolean
  @Prop({ default: false }) singleSelectionNoDisable: boolean
  @Prop({ default: () => ({}) }) preSelected: object

  selected: Array<string | number> = []
  areFilterloading: boolean

  mounted() {
    if (this.preSelected) {
      const preSelected = Object.values(this.preSelected)
      if (preSelected.length > 0) {
        this.selected = preSelected
      }
    }
  }

  onChange(id: number | string, $event: any): void {
    const input: any = $event.target
    const checked: boolean = input.checked

    this.setCheckboxeIds(id, checked)
  }

  @Emit('input')
  setCheckboxeIds(id: number | string, checked: boolean): any[] {
    return this.updateIds(id, checked)
  }

  @Watch('value')
  onValueChanged() {
    this.selected = this.value ? this.value : []
  }

  @Emit('validateField')
  handleBlur(): any {}

  updateIds(id: string | number, checked: boolean): any[] {
    if (
      this.singleSelectionNoDisable &&
      checked &&
      !this.selected.includes(id)
    ) {
      this.selected = []
      this.selected.push(id)
      return this.selected
    }
    if (checked && !this.selected.includes(id)) {
      this.selected.push(id)
    } else {
      this.selected = this.selected.filter((_id) => _id !== id)
    }
    return this.selected
  }
}
