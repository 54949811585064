






























import { Component, Vue, Emit, Prop } from 'nuxt-property-decorator'
import TextInput from '../shared/inputs/TextInput.vue'
import MapDropdownFilter from '~/components/sales_map_v3/MapDropdownFilter.vue'

@Component({
  components: {
    MapDropdownFilter,
    TextInput,
  },
})
export default class SqftFilter extends Vue {
  @Prop({ default: '' }) title: String
  @Prop({ default: '' }) openedMenu: String
  @Prop({ default: (): any => {} }) sqftMin: any
  @Prop({ default: (): any => {} }) sqftMax: any
  @Prop({ default: null }) minimumAvailableSqft: number
  @Prop({ default: null }) maximumAvailableSqft: number

  get sqftMinValue() {
    return this.sqftMin ? this.sqftMin?.value : this.minimumAvailableSqft
  }

  get sqftMaxValue() {
    return this.sqftMax ? this.sqftMax?.value : this.maximumAvailableSqft
  }

  @Emit('updateSqftMinFilter')
  updateSqftMinFilter(e: any) {
    return e.target.value
  }

  @Emit('updateSqftMaxFilter')
  updateSqftMaxFilter(e: any) {
    return e.target.value
  }

  handleChangeMenu(e: any) {
    e ? this.$emit('changeMenu', this.title) : this.$emit('changeMenu', '')
  }
}
