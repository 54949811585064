import { prop, required } from '@rxweb/reactive-forms'
import FormControl from './FormControl'
import { InputType } from '~/enums/inputType'
import { LabelSizes } from '~/enums/labelSizes'

export default class ReviewClosingHomeForm {
  @required({ message: 'This field is required' }) status: string = ''
  @prop() description: string = ''

  static getControls(): FormControl[] {
    return [
      {
        id: 'status',
        label: 'Status',
        required: true,
        type: InputType.select,
        settings: {
          description: '',
          defaultValue: null,
          titleSize: LabelSizes.medium,
          options: [
            {
              value: 'approved',
              label: 'Approve',
            },
            {
              value: 'denied',
              label: 'Deny',
            },
          ],
        },
      },
      {
        id: 'description',
        heapId: 'comments',
        label: 'Comments',
        required: false,
        type: InputType.text,
        settings: {
          description: '',
          titleSize: LabelSizes.medium,
          isTextArea: true,
        },
      },
    ]
  }
}
