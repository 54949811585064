var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full relative",attrs:{"tabindex":"-1"},on:{"focusout":_vm.handleBlur}},[(_vm.showSideRanges)?_c('date-range-picker',{ref:"picker",attrs:{"opens":_vm.opens,"single-date-picker":_vm.singleDatePicker,"date-range":{
      startDate: null,
      endDate: null,
    }},on:{"update":_vm.handleInput,"select":_vm.select},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [_vm._v("\n      "+_vm._s(picker.startDate
          ? _vm.$dateFns.format(picker.startDate, 'MM/dd/yyyy')
          : '')+"\n      -\n      "+_vm._s(picker.endDate ? _vm.$dateFns.format(picker.endDate, 'MM/dd/yyyy') : '')+"\n    ")]}}],null,false,409334028),model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}):_c('date-range-picker',{ref:"picker",attrs:{"opens":_vm.opens,"single-date-picker":_vm.singleDatePicker,"date-range":{
      startDate: null,
      endDate: null,
    },"ranges":_vm.showSideRanges,"locale-data":{ firstDay: 1 },"auto-apply":true},on:{"update":_vm.onUpdate},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [_vm._v("\n      "+_vm._s(picker.startDate
          ? _vm.$dateFns.format(picker.startDate, 'MM/dd/yyyy')
          : '')+"\n      -\n      "+_vm._s(picker.endDate ? _vm.$dateFns.format(picker.endDate, 'MM/dd/yyyy') : '')+"\n    ")]}}]),model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_vm._v(" "),_c('div',{staticClass:"absolute top-2 right-2"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.dateRange.startDate && _vm.dateRange.endDate),expression:"dateRange.startDate && dateRange.endDate"}],attrs:{"type":"button"},on:{"click":function($event){return _vm.clearDate()}}},[_c('fa',{attrs:{"icon":['axis', 'clear'],"size":"lg"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }