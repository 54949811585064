import { required, email, notEmpty } from '@rxweb/reactive-forms'

export default class LoginForm {
  @required()
  @email()
  email: string

  @required()
  @notEmpty()
  password: string
}
