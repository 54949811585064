











































import { Component, Emit, Prop } from 'nuxt-property-decorator'
import BaseInputComponent from './BaseInput.vue'
import TitleInput from './TitleInput.vue'

@Component({
  components: {
    TitleInput,
  },
})
export default class CheckboxInput extends BaseInputComponent {
  @Prop({ default: false }) checked: boolean
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: false }) hideLabel: boolean

  isCheked: boolean = false
  isDisabled: boolean = false

  get uId(): any {
    // using the milliseconds can create duplicated ids
    return this.id ?? new Date().getUTCMilliseconds()
  }

  get _checked() {
    return this.checked ?? this.isCheked
  }

  get _disabled() {
    return this.disabled ?? this.isDisabled
  }

  @Emit('input')
  handleInput($event: any): any {
    this.isCheked = $event.target.checked
    return $event
  }

  @Emit('validateField')
  handleBlur(): any {}
}
