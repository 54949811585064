
















import { Component, Emit, Watch } from 'nuxt-property-decorator'
import { setValue } from 'vue-currency-input'
import BaseInputComponent from './BaseInput.vue'

@Component({})
export default class CurrencyInput extends BaseInputComponent {
  currency: number | null = null

  constructor() {
    super()
    if (this.value) {
      this.currency = +this.value
    }
  }

  @Watch('value')
  onValueChanged(value: number): void {
    setValue(this.$refs.input as HTMLInputElement, value)
  }

  @Watch('currency')
  onCurrencyChanged(): void {
    const numberValue = (this.$refs.input as any).$ci.numberValue
    this.handleInput(numberValue)
  }

  @Emit('validateField')
  handleBlur(): any {}
}
