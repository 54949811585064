import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { LotDetails } from '~/models'
import { getLotDetail, deleteSpec, patchLot } from '~/api/lot.api'
import Architectural from '~/models/architectural'
import Sale from '~/models/sale'
import Document from '~/models/document'
import { uiStore } from '~/store'
import BaseState from '~/models/state/baseState'

@Module({
  name: 'lot',
  stateFactory: true,
  namespaced: true,
})
export default class Lot extends VuexModule implements BaseState {
  lot: LotDetails = null
  previousUrl: string = null

  get lotDetails(): LotDetails {
    return this.lot
  }

  get architecturalSubmission(): Architectural | null {
    return this.lot?.architectural_submission
  }

  get sale(): Sale | null {
    return this.lot?.sale
  }

  get documents(): Document[] {
    return this.lot?.documents
  }

  @Action({ rawError: true })
  async getLotDetails(slug: string): Promise<LotDetails> {
    try {
      uiStore.updateLoding(true)
      const response = await getLotDetail(slug)
      this.setLot(response)
      uiStore.updateLoding(false)
      return response
    } catch (err) {
      uiStore.updateLoding(false)
      throw err
    }
  }

  @Action({ rawError: true })
  async editLot(params: any): Promise<void> {
    const response = await patchLot(params.slug, params.form)
    this.setLot(response)
  }

  @Action({ rawError: true })
  async deleteSpecAction(slug: string): Promise<void> {
    await deleteSpec(slug)
    await this.getLotDetails(slug)
  }

  @Mutation
  setLot(lot: LotDetails) {
    this.lot = lot
    if (lot) {
      this.lot.photo = `${lot?.photo}?timeStamp=${(new Date()).valueOf()}`
    }
  }

  @Mutation
  setUrl(path: string) {
    this.previousUrl = path
  }

  @Mutation
  deleteSale(): void {
    this.lot.sale = null
  }

  @Mutation
  clear() {
    this.lot = null
    this.previousUrl = null
  }
}
