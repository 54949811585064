let throttleTimer: any

export default function throttle(callback: Function, time: any) {
  if (throttleTimer) return
  throttleTimer = true
  setTimeout(() => {
    callback()
    throttleTimer = false
  }, time)
}
