import {
  prop,
  required,
  numeric,
  NumericValueType,
} from '@rxweb/reactive-forms'
import { NormalReport } from '../trafficReport'
import FormControl from './FormControl'
import { InputType } from '~/enums/inputType'
import { LabelSizes } from '~/enums/labelSizes'

export default class NormalTrafficForm {
  @prop()
  @required({ message: 'No report week selected' })
  week_range: string[]

  @prop()
  @required({ message: 'Lot size required' })
  size: number

  @prop()
  @required({ message: 'Visits is required' })
  @numeric({
    allowDecimal: false,
    acceptValue: NumericValueType.PositiveNumber,
    message: 'Values must be greater than 0',
  })
  visits: number

  @prop()
  @required({ message: 'Return visits is required' })
  @numeric({
    allowDecimal: false,
    acceptValue: NumericValueType.PositiveNumber,
    message: 'Values must be greater than 0',
  })
  return_visits: number

  @prop()
  @required({ message: 'Realtor visits is required' })
  @numeric({
    allowDecimal: false,
    acceptValue: NumericValueType.PositiveNumber,
    message: 'Values must be greater than 0',
  })
  realtor_visits: number

  constructor(report: NormalReport) {
    this.visits = 0
    this.return_visits = 0
    this.realtor_visits = 0

    if (report) {
      this.week_range = [report.start_date, report.end_date]
      this.size = report.size
      this.visits = report.visits
      this.return_visits = report.return_visits
      this.realtor_visits = report.realtor_visits
    }
  }

  static CONTROLS: Array<FormControl> = [
    {
      id: 'size',
      label: 'Model Home Lot Size',
      type: InputType.select,
      parentClass: 'mb-2',
      required: true,
      settings: {
        description:
          'Select the lot size associated with the model home of this traffic.',
        options: [],
        titleSize: LabelSizes.extraLarge,
      },
    },
    {
      id: 'week_range',
      label: 'Report Week',
      type: InputType.dateRange,
      required: true,
      parentClass: 'mb-2',
      settings: {
        opens: 'right',
        showSideRanges: false,
        singleDatePicker: true,
        weekRange: true,
        description:
          'Select the week to submit or update a weekly traffic report.',
        titleSize: LabelSizes.extraLarge,
      },
    },
    {
      id: 'visits',
      type: InputType.number,
      label: 'New Family Visits',
      required: false,
      parentClass: 'mb-2',
      settings: {
        description:
          'Enter the total number of unique first-time family visitors for the week.',
        titleSize: LabelSizes.extraLarge,
      },
    },
    {
      id: 'return_visits',
      type: InputType.number,
      label: 'Return Family Visits',
      required: false,
      parentClass: 'mb-2',
      settings: {
        description:
          'Enter the total number of unique families who returned for a visit for the week.',
        titleSize: LabelSizes.extraLarge,
      },
    },
    {
      id: 'realtor_visits',
      type: InputType.number,
      label: 'Realtor Visits',
      required: false,
      parentClass: '',
      settings: {
        description:
          'Enter the total number of unique realtor visits for the week.',
        titleSize: LabelSizes.extraLarge,
      },
    },
  ]
}
