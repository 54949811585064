import { prop, required } from '@rxweb/reactive-forms'
import { InputType } from '~/enums/inputType'
import { LabelSizes } from '~/enums/labelSizes'
import { Elevation } from '~/models'
import FormControl from './FormControl'

export default class ReviewElevationForm {
  @required({ message: 'This field is required' }) status: string = 'approved'
  @prop() description: string
  @prop() file: number
  @prop() matrix_elevations: number[]
  @prop() high_profile: boolean = false

  constructor(elevation: Elevation) {
    this.high_profile = elevation ? elevation.high_profile : false
  }

  static getControls(isHighProfile: boolean, elevation?: Elevation): FormControl[] {
    const controls: FormControl[] = [
      {
        id: 'status',
        label: 'Status',
        required: false,
        type: InputType.select,
        settings: {
          description:
            'Select the status for this elevation review. This will trigger an email to the builder user.',
          defaultValue: null,
          titleSize: LabelSizes.extraLarge,
          options: [
            {
              value: 'approved',
              label: 'Approve',
            },
            {
              value: 'denied',
              label: 'Deny',
            },
          ],
        },
      },
      {
        id: 'high_profile',
        label: 'Enhanced Elevation',
        required: false,
        type: InputType.checkbox,
        settings: {
          description:
            'Toggle on to consider this elevation as an enhanced elevation. High Profile lots will only show enhanced elevations',
          defaultValue: null,
          titleSize: LabelSizes.extraLarge,
          options: [],
        },
      },
      {
        id: 'description',
        heapId: 'comments',
        label: 'Comments',
        required: false,
        type: InputType.text,
        settings: {
          description:
            'Upload any additional feedback to further assist builder with approval',
          titleSize: LabelSizes.extraLarge,
          isTextArea: true,
        },
      },
      {
        id: 'file',
        label: 'Include a File',
        required: false,
        type: InputType.fileUpload,
        settings: {
          description:
            'Upload any additional feedback to further assist builder with approval',
          files: [],
          titleSize: LabelSizes.extraLarge,
          category: 'document',
        },
      },
      {
        id: 'matrix_elevations',
        label: 'Similar Elevation(s)',
        required: false,
        type: InputType.select,
        settings: {
          isMultiSelect: true,
          options: [],
          defaultValue: null,
          defaultValues: elevation?.matrix_elevations || [],
          description:
            'Select elevations that should be filtered by repetition rules for being "similar"',
          titleSize: LabelSizes.extraLarge,
        },
      },
    ]

    if (isHighProfile) {
      return controls
    }

    return controls.filter((control) => control.id !== 'high_profile')
  }
}
