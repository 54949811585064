export enum ArchitecturalStatus {
  PENDING = 'pending',
  AWAITING = 'awaiting',
  DENIED = 'denied',
  APPROVED = 'approved',
  DRAFT = 'draft',
}

export enum VarianceReviewStatus {
  PENDING = 'pending',
  DENIED = 'denied',
  APPROVED = 'approved',
}

export enum PendingFieldsUpdated {
  PLAN_ELEVATION = 'plan_elevation',
  MATERIAL = 'material',
  FILES = 'files',
}
