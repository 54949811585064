import Vue from 'vue'

Vue.filter('mbDistance', function (value) {
  if (!value) return ''
  const distance = value * 0.000621
  return Math.round(distance) // 1 meter is 0.000621 miles
})

Vue.filter('mbDuration', function (value) {
  if (!value) return ''
  const time = value / 60 // value is time in seconds
  return Math.floor(time)
})

Vue.filter('thousandsSeparated', function (value) {
  const inputMaskRe = /(\d\d\d)(?=[^$])/g
  const replacement = '$1,'
  return !value 
    ? ''
    : value.toString().split(' ').map(chunk => {
        return isNaN(Number(chunk)) 
          ? chunk
          : chunk.toString().split('').reverse().join('').replace(inputMaskRe, replacement).split('').reverse().join('')
      }).join(' ')
})
