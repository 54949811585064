const CONFIG_PATH = '/config/environment_config.json'

type Config = {
  apiURL?: string
  lotManagementUrl?: string | null
  inspectionsOnly?: boolean
}

export default class EnvironmentConfig {
  /**
   * Setting default config values.  These will only be used if no environment-config.json exists.
   * These are only set here for development and never should be used in production
   */

  /**
   * apiURL": "localhost:8000/o/api",
   * lotManagementUrl": null,
   * inspectionsOnly": false
   */

  config: Config = {}

  async loadConfigAsync() {
    try {
      const response = await fetch(CONFIG_PATH)
      this.config = await response.json()
    } catch (error) {
      console.warn(
        'Error getting config file, using default global config',
        error
      )
    }
  }

  get baseURL(): string {
    let baseUrl: string = this.config?.apiURL
    if (!baseUrl) {
      baseUrl = process.env.baseURL
    }

    if (!baseUrl) {
      baseUrl = 'http://localhost:8000/o/api/'
    }

    return baseUrl
  }

  get inspectionsOnly(): boolean {
    let inspectionsOnly: boolean = this.config?.inspectionsOnly
    if (!inspectionsOnly) {
      inspectionsOnly = JSON.parse(process.env.inspectionsOnly || 'false')
    }

    if (!inspectionsOnly) {
      inspectionsOnly = false
    }

    return inspectionsOnly
  }

  get lotManagementUrl(): string | null {
    let managementURL: string = this.config?.lotManagementUrl
    if (!managementURL) {
      managementURL = process.env.lotManagementUrl
    }

    return managementURL
  }
}
