



































































































































import { Component, Prop, Emit, Watch } from 'nuxt-property-decorator'
import { mapGetters } from 'vuex'
import Select from '../Select.vue'
import BaseInputComponent from './BaseInput.vue'
import TitleInput from './TitleInput.vue'
import { InputType } from '~/enums/inputType'
import Spinner from '~/components/shared/Spinner.vue'
import { postMedia, putMedia } from '~/api/media.api'
import Document from '~/models/document'
import Subcategory from '~/models/subcategory'
import { MAX_FILES_AVAILABLE_TO_UPLOAD } from '~/constants/files'
import { getSubcategories } from '~/api/subcategories.api'

@Component({
  components: {
    Spinner,
    Select,
    TitleInput,
  },
  computed: {
    ...mapGetters('user', {
      requiredDocuments: 'requiredDocuments',
    }),
  },
})
export default class FileInput extends BaseInputComponent {
  requiredDocuments: Array<string>

  loading: boolean = false
  inputType: typeof InputType = InputType
  files: Document[] = []
  subcategories: Subcategory[] = []
  isDragover: boolean = false

  @Prop({ default: (): Array<Document> => [] }) documents: Array<Document>
  @Prop() accept: string
  @Prop({ default: true }) uploadToMedia: boolean
  @Prop({ default: true }) multipleFilesUpload: boolean
  @Prop({ default: 'none' }) category: string
  @Prop({ default: null }) allowedFilesCount: number
  @Prop({ default: false }) isCompactMode: boolean
  @Prop({ default: false }) hasRequiredDocuments: boolean
  @Prop({ default: false }) isSubcategoryRequired: boolean

  constructor() {
    super()
    this.files = this.documents
  }

  get filteredSubCategories() {
    if (this.subcategories.length === 0) {
      return []
    }

    return this.subcategories.filter((s) => s.category === this.category)
  }

  created() {
    this.getSubCategories()
  }

  async getSubCategories(): Promise<void> {
    try {
      const response = await getSubcategories()
      this.subcategories = response
    } catch (error) {
      console.error('Error getting subcategories', error)
    }
  }

  @Emit('input')
  handleInput(): any[] {
    if (this.uploadToMedia) {
      return this.files.map((file) => file.id)
    }
    return this.files
  }

  @Emit('documents-changed')
  handleDocumentsChanged() {
    return this.files
  }

  @Emit('validateField')
  handleBlur(): any {}

  subcategoryName(subcategory: number | string) {
    if (typeof subcategory === 'string') {
      return subcategory
    }

    const subcategoryId = subcategory as number
    return this.subcategories.find(
      (subcategory: Subcategory) => subcategory.id === subcategoryId
    ).name
  }

  async addSubcategory($event: any, file: Document) {
    if (this.uploadToMedia) {
      file.sub_category.push($event.id)
      const bodyFormData = new FormData()
      bodyFormData.append('category', 'document')
      bodyFormData.append('sub_category', $event.id)
      await putMedia(file.id, bodyFormData)
      this.handleDocumentsChanged()
    }
  }

  onChange(): void {
    const input: HTMLInputElement = this.$refs[this.id] as HTMLInputElement
    this.loading = true
    if (this.uploadToMedia) {
      this.handleUploadFile(input.files)
    } else {
      this.files = input.files as any
      this.handleInput()
      this.handleDocumentsChanged()
      this.loading = false
    }
  }

  onFileHover(): void {
    this.isDragover = true
  }

  onFileLeave(): void {
    this.isDragover = false
  }

  handleDrop(e: any): void {
    if (this.isFileInputDisable()) return
    this.loading = true
    const input: HTMLInputElement = this.$refs[this.id] as HTMLInputElement
    const files = e.dataTransfer.files
    input.files = e.dataTransfer.files
    if (this.uploadToMedia) {
      this.handleUploadFile(files)
    } else {
      this.files = input.files as any
      this.handleInput()
      this.handleDocumentsChanged()
      this.loading = false
    }
  }

  async handleUploadFile(files: FileList): Promise<void> {
    try {
      await this.submitFiles(files)
      this.handleInput()
      this.handleDocumentsChanged()
    } catch (exception) {
      throw new Error(exception)
    } finally {
      this.loading = false
      this.handleBlur()
    }
  }

  private async submitFiles(files: FileList): Promise<void> {
    const maxFilesAllowed: number =
      MAX_FILES_AVAILABLE_TO_UPLOAD >= files.length
        ? files.length
        : MAX_FILES_AVAILABLE_TO_UPLOAD

    for (let i = 0; i < maxFilesAllowed; i++) {
      const bodyFormData = new FormData()
      bodyFormData.append('uploadedId', '-1')
      bodyFormData.append('name', files[i].name)
      bodyFormData.append('type', files[i].type)
      bodyFormData.append('source', files[i])
      const document: Document = await postMedia(bodyFormData)
      this.files.push(document)
    }
  }

  removeFile(document: Document) {
    if (this.files.filter) {
      this.files = this.files.filter((doc: Document) => doc.id !== document.id)
    }
    if (!this.files.filter) {
      const input: HTMLInputElement = this.$refs[this.id] as HTMLInputElement
      input.value = ''
      input.files = new DataTransfer().files
      this.files = []
    }
    this.handleInput()
    this.handleDocumentsChanged()
  }

  isFileInputDisable(): boolean {
    if (this.id === 'floor_plan_images') {
      return this.allowedFilesCount <= this.files.length
    }
    return MAX_FILES_AVAILABLE_TO_UPLOAD <= this.files.length
  }

  @Watch('documents')
  onDucumentsChange() {
    this.files = this.documents
  }

  @Watch('requiredDocuments')
  onRequiredChanged() {
    console.log('requiredDocuments', this.requiredDocuments)
  }
}
