














































































































































































































import { Component, Prop, Watch, mixins, Emit } from 'nuxt-property-decorator'
import { mapState } from 'vuex'
import RentalsCounter from './RentalsCounter.vue'
import MapSelect from '~/components/rental_map_v3/mapSelect.vue'
import IconButton from '~/components/shared/buttons/IconButton.vue'
import BedroomButtonGroup from '~/components/sales_map_v3/BedroomButtonGroup.vue'
import BedsBathsFilter from '~/components/sales_map_v3/BedsBathsFilter.vue'
import SqftFilter from '~/components/sales_map_v3/SqftFilter.vue'
import AvailableDateFilter from '~/components/rental_map_v3/AvailableDateFilter.vue'
import PriceFilter from '~/components/rental_map_v3/RentalPriceFilter.vue'
import MapSearchButton from '~/components/rental_map_v3/MapSearchButton.vue'
import FiltersBox from '~/components/sales_map_v3/FiltersBox.vue'
import { getRentalFilters } from '~/api/rental_map.api'
import { FilterFields } from '~/models/filters'
import globalStyling from '~/mixins/globalStyling'
import { getLocalStorageItem, storageAvailable } from '~/utils/localStorage'
import { rentalFavoritesStore } from '~/store'
import { ConsumerMapRoutes } from '~/enums/consumerMap'
import SeasonPicker from '~/components/sales_map_v3/SeasonPicker.vue'
import { Project } from '~/models'
import { Events } from '~/utils/eventLayers'

@Component({
  components: {
    MapSelect,
    IconButton,
    BedroomButtonGroup,
    MapSearchButton,
    FiltersBox,
    BedsBathsFilter,
    SqftFilter,
    AvailableDateFilter,
    PriceFilter,
    RentalsCounter,
    SeasonPicker,
  },
  mixins: [globalStyling],

  computed: {
    ...mapState('consumer-map', {
      currentProject: 'currentProject',
    }),
  },
})
export default class MapFiltersV3 extends mixins(globalStyling) {
  @Prop({ default: 'map' }) activeView: string
  @Prop({
    default() {
      return {
        price: true,
        bedrooms: true,
        sqft: true,
        available: true,
        floorplan: true,
      }
    },
  })
  // This is here to let typescript know that it's okay there's no variable called
  // filtersVisibilitySettings in this component. Required for the globalStyling mixin.
  filtersVisibilitySettings: Object

  prices: FilterFields[]
  styles: FilterFields[]
  beds: FilterFields[]
  mFilters: boolean = false
  openedMenu: string = ''
  searchActive: boolean = false
  priceOptions: any[] = []
  bedroomOptions: any[] = []
  selectedPriceOptions: any[] = []
  selectedBedRooms: any = null
  isShareWithMeVisible: boolean = false
  floorplanOptions: any[] = []

  bathsOptions: any[] = [
    { value: 2, label: '2+', type: 'baths' },
    { value: 3, label: '3+', type: 'baths' },
    { value: 4, label: '4+', type: 'baths' },
    { value: 5, label: '5+', type: 'baths' },
  ]

  garagesOptions: any[] = [
    { value: 0, label: '0+', type: 'garages' },
    { value: 1, label: '1+', type: 'garages' },
    { value: 2, label: '2+', type: 'garages' },
    { value: 3, label: '3+', type: 'garages' },
  ]

  storiesOptions: any[] = [
    { value: 1, label: '1+', type: 'stories' },
    { value: 2, label: '2+', type: 'stories' },
    { value: 3, label: '3+', type: 'stories' },
  ]

  selectedBaths: any = null
  selectedGarages: any = null
  selectedStories: any = null
  sqftMin: any = null
  sqftMax: any = null
  priceMin: any = null
  priceMax: any = null
  minimumAvailablePrice: number = 500
  maximumAvailablePrice: number = 10000
  moveInDate: any = null
  currentProject: Project
  selectedFloorplanOptions: any[] = []

  @Emit('seasonChange')
  seasonChange(value: boolean): boolean {
    return value
  }

  get seasonEnabled() {
    return !!this.currentProject?.config?.enable_season
  }

  get favCount() {
    return rentalFavoritesStore.favCount
  }

  async mounted(): Promise<void> {
    const filters = await Promise.all([
      getRentalFilters(this.$route.params.slug, 'prices'),
      getRentalFilters(this.$route.params.slug, 'bedrooms'),
      getRentalFilters(this.$route.params.slug, 'floorplans'),
    ])

    this.priceOptions = filters[0].map((filter) => ({
      ...filter,
      type: 'price',
    }))

    this.bedroomOptions = filters[1].map((filter) => ({
      ...filter,
      type: 'bedrooms',
    }))

    this.floorplanOptions = filters[2].map((filter) => ({
      ...filter,
      type: 'floor_plan',
    }))

    const queries = { ...this.$route.query }
    if (queries.price) {
      const queryPrice = queries.price

      if (Array.isArray(queryPrice)) {
        this.selectedPriceOptions = queryPrice.map((prices: any) =>
          this.priceOptions.find(({ value }) => prices === value)
        )
      } else {
        this.selectedPriceOptions = [
          this.priceOptions.find(({ value }) => queryPrice === value),
        ]
      }
    }
    if (queries.beds) {
      const queryBeds = queries.beds
      this.selectedBedRooms = this.bedroomOptions.find(
        ({ value }) => +queryBeds === value
      )
      if (this.selectedBedRooms) {
        this.updateRoomsFilter(this.selectedBedRooms.value)
      }
    }
    if (queries.baths) {
      const queryBaths = queries.baths
      this.selectedBaths = this.bathsOptions.find(
        ({ value }) => +queryBaths === value
      )
      if (this.selectedBaths) {
        this.updateBathsFilter(this.selectedBaths.value)
      }
    }
    if (queries.stories) {
      const queryStories = queries.stories
      this.selectedStories = this.storiesOptions.find(
        ({ value }) => +queryStories === value
      )
      if (this.selectedStories) {
        this.updateStoriesFilter(this.selectedStories.value)
      }
    }
    if (queries.garages) {
      const queryGarages = queries.garages
      this.selectedGarages = this.garagesOptions.find(
        ({ value }) => +queryGarages === value
      )
      if (this.selectedGarages) {
        this.updateGaragesFilter(this.selectedGarages.value)
      }
    }
    if (queries.sqft_min) {
      const querySqftMin = queries.sqft_min

      this.updateSqftMinFilter(querySqftMin as string)
    }
    if (queries.sqft_max) {
      const querySqftMax = queries.sqft_max

      this.updateSqftMaxFilter(querySqftMax as string)
    }
    if (queries.price_min) {
      const queryPriceMin = queries.price_min

      this.updatePriceMinFilter(queryPriceMin as string)
    }
    if (queries.price_max) {
      const queryPriceMax = queries.price_max

      this.updatePriceMaxFilter(queryPriceMax as string)
    }
    if (queries.move_in_date) {
      const queryMoveInDate = queries.move_in_date

      this.updateMoveInDateFilter(queryMoveInDate as string)
    }
    if (queries.floor_plan) {
      const queryFloorplan = queries.floor_plan
      if (Array.isArray(queryFloorplan)) {
        this.selectedFloorplanOptions = queryFloorplan.map((builder: string) =>
          this.floorplanOptions.find(({ value }) => +builder === value)
        )
      } else {
        this.selectedFloorplanOptions = [
          this.floorplanOptions.find(({ value }) => +queryFloorplan === value),
        ]
      }
    }

    this.isShareWithMeVisible =
      storageAvailable() && !!getLocalStorageItem('shared-with-me')

    rentalFavoritesStore.loadFavorites()
  }

  @Watch('filtersSelected')
  onUpdateRoomsFilterChange(value: []) {
    this.$emit('handleFilterChange', value)
  }

  updateRoomsFilter(rooms: number): void {
    const selectedOptions = {
      value: rooms,
      label: `Beds ${rooms}`,
      type: 'bedrooms',
    }

    this.selectedBedRooms = selectedOptions
  }

  @Watch('filtersSelected')
  onUpdateBathsFilterChange(value: []) {
    this.$emit('handleFilterChange', value)
  }

  updateBathsFilter(baths: number): void {
    const selectedOptions = {
      value: baths,
      label: `Baths ${baths}`,
      type: 'baths',
    }

    this.selectedBaths = selectedOptions
  }

  @Watch('filtersSelected')
  @Emit('handleFilterChange')
  onUpdateGaragesFilterChange(value: []) {
    return value
  }

  updateGaragesFilter(garages: number): void {
    const selectedOptions = {
      value: garages,
      label: `Garages ${garages}`,
      type: 'garages',
    }

    this.selectedGarages = selectedOptions
  }

  @Watch('filtersSelected')
  @Emit('handleFilterChange')
  onUpdateStoriesFilterChange(value: []) {
    return value
  }

  updateStoriesFilter(stories: number): void {
    const selectedOptions = {
      value: stories,
      label: `Stories ${stories}`,
      type: 'stories',
    }

    this.selectedStories = selectedOptions
  }

  updateMoveInDateFilter(value: string): void {
    const moveInDate = {
      value,
      label: `Move in Date ${value}`,
      type: 'move_in_date',
    }
    this.moveInDate = value ? moveInDate : null
  }

  updateSqftMinFilter(value: string): void {
    if (!this.isBelowZeroValue(value)) {
      const sqftMin = {
        value,
        label: `Sqft Min ${value}`,
        type: 'sqft_min',
      }
      this.sqftMin = sqftMin
    } else {
      this.sqftMin = null
    }
  }

  updateSqftMaxFilter(value: string): void {
    if (!this.isBelowZeroValue(value)) {
      const sqftMax = {
        value,
        label: `Sqft Max ${value}`,
        type: 'sqft_max',
      }
      this.sqftMax = sqftMax
    } else {
      this.sqftMax = null
    }
  }

  updatePriceMinFilter(value: string): void {
    if (
      !this.isBelowZeroValue(value) &&
      !this.isSetToMin(value, this.minimumAvailablePrice)
    ) {
      const priceMin = {
        value,
        label: `Rent Min ${value}`,
        type: 'price_min',
      }
      this.priceMin = priceMin
    } else {
      this.priceMin = null
    }
  }

  updatePriceMaxFilter(value: string): void {
    if (
      !this.isBelowZeroValue(value) &&
      !this.isSetToMax(value, this.maximumAvailablePrice)
    ) {
      const priceMax = {
        value,
        label: `Rent Max ${value}`,
        type: 'price_max',
      }
      this.priceMax = priceMax
    } else {
      this.priceMax = null
    }
  }

  isSetToMax(value: string | number, maxValue: string | number) {
    return value === maxValue
  }

  isSetToMin(value: string | number, minValue: string | number) {
    return value === minValue
  }

  isBelowZeroValue(value: string) {
    const price = parseInt(value as string)
    return isNaN(price) || price <= 0
  }

  get filtersSelected(): any[] {
    const filtersSelected: any[] = [
      ...this.selectedPriceOptions,
      ...this.selectedFloorplanOptions,
    ]

    const pushableFilters = [
      'selectedBedRooms',
      'selectedBaths',
      'selectedSTories',
      'selectedGarages',
      'sqftMin',
      'sqftMax',
      'priceMin',
      'priceMax',
      'moveInDate',
    ]

    pushableFilters.forEach((filterName) => {
      if (this[filterName]) filtersSelected.push(this[filterName])
    })

    return filtersSelected
  }

  handleMenuChange(menu: string) {
    this.openedMenu = menu
  }

  handleSearchButton() {
    this.searchActive = !this.searchActive
  }

  handlePriceCheck(selectedOptions: []) {
    this.selectedPriceOptions = selectedOptions
  }

  handleFloorplanCheck(selectedOptions: []) {
    this.selectedFloorplanOptions = [...selectedOptions]
  }

  @Emit('handleFilterChange')
  clearFilter(filter: FilterFields) {
    if (filter.type === 'price') {
      this.selectedPriceOptions = this.selectedPriceOptions.filter(
        (opt) => opt.value !== filter.value
      )
    } else if (filter.type === 'floor_plan')
      this.selectedFloorplanOptions = this.selectedFloorplanOptions.filter(
        (opt) => opt.value !== filter.value
      )
    this[this.translateFilterTypeToState(filter.type)] = null
  }

  translateFilterTypeToState(filterType: string): string {
    const translations = {
      bedrooms: 'selectedBedRooms',
      baths: 'selectedBaths',
      garages: 'selectedGarages',
      stories: 'selectedStories',
      sqft_min: 'sqftMin',
      sqft_max: 'sqftMax',
      price_min: 'priceMin',
      price_max: 'priceMax',
      move_in_date: 'moveInDate',
    }
    return translations[filterType]
  }

  clearFilters() {
    this.selectedPriceOptions = []
    this.selectedFloorplanOptions = []
    this.selectedBedRooms = null
    this.selectedBaths = null
    this.selectedGarages = null
    this.selectedStories = null
    this.sqftMin = null
    this.sqftMax = null
    this.priceMin = null
    this.priceMax = null
    this.moveInDate = null

    this.mFilters = false
  }

  @Watch('$route')
  clearOnRouteChange(
    route: { name: string; query: { preserve_filters: string } },
    oldRoute: { name: string }
  ): void {
    if (
      route.query.preserve_filters !== 'true' &&
      route.name !== oldRoute.name
    ) {
      this.clearFilters()
    }
  }

  getActiveRoute(): string {
    return this.$route.path.split('/').pop()
  }

  get isFavoritesOrSharedRoute(): boolean {
    return (
      this.getActiveRoute() === ConsumerMapRoutes.FAVORITES ||
      this.getActiveRoute() === ConsumerMapRoutes.SHARED
    )
  }

  get isFavoritesRoute(): boolean {
    return (
      this.getActiveRoute() === ConsumerMapRoutes.FAVORITES &&
      !this.isShareWithMeVisible
    )
  }

  onMapViewClicked(): void {
    Events.pages('page_clicked', 'Rental Map View')
  }

  onFavoritesClicked(): void {
    Events.pages('page_clicked', 'Rental Favorites')
  }
}
