

















import { Vue, Component, Prop } from 'nuxt-property-decorator'
import FilterCard from '~/components/sales_map_v3/FilterCard.vue'
import TagComponent from '~/components/shared/Tag.vue'

@Component({
  components: {
    FilterCard,
    TagComponent,
  },
})
export default class FiltersBox extends Vue {
  @Prop({ default: (): any[] => [] }) activeFilters: any[]
}
