






import { Component, Prop, Watch } from 'nuxt-property-decorator'
import BaseInputComponent from '../inputs/BaseInput.vue'

@Component({})
export default class ToggleButton extends BaseInputComponent {
    @Prop({ default: 'On' })
    valueOnText: string

    @Prop({ default: 'Off' })
    valueOffText: string

    @Prop({ default: false })
    value: boolean

    isOn: boolean = false

    constructor() {
        super()
        this.isOn = this.value
    }

    toggle() {
        this.isOn = !this.isOn
        this.handleInput(this.isOn)
    }

    @Watch('value')
    onValueChange(value: boolean) {
      this.isOn = value
    }
}
