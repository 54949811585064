var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'row bg-white focus:outline-none ease-in-out duration-500 mb-2 overflow-hidden shadow border-blueGray-300 border flex text-xs w-full mt-0 p-4',
    _vm.customClasses,
    {
      'hover:bg-blue-50 hover:border-blue-500 animate': !_vm.sticky,
    },
    {
      'animate-pulse focuse': _vm.isHighLighted
    }
  ],style:(_vm.customStyle)},[_vm._t("default",null,{"isOpen":_vm.isOpen}),_vm._v(" "),_c('div',{staticClass:"flex items-start"},[(_vm.expansible)?_c('button',{staticClass:"focus:outline-none",on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_c('fa',{staticClass:"text-blueGray-300",attrs:{"icon":['axis', _vm.isOpen ? 'upUi' : 'downUi'],"size":"2x"}})],1):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }