import { TableHeader } from '~/models'

export default class AdvancedTraffic {
  static WEEKLY_HEADERS: Array<TableHeader> = [
    { id: 'day', text: '', class: 'text-center' },
    { id: 'new', text: 'New', class: 'text-center' },
    { id: 'return', text: 'Return', class: 'text-center' },
    { id: 'realtors', text: 'realtors', class: 'text-center' },
  ]

  static REFERRAL_HEADERS: Array<TableHeader> = [
    { id: 'referral', text: '', class: 'text-center' },
    { id: 'new', text: 'New', class: 'text-center' },
    { id: 'return', text: 'Return', class: 'text-center' },
    { id: 'realtors', text: 'realtors', class: 'text-center' },
  ]

  static SALES_HEADERS: Array<TableHeader> = [
    { id: 'sales', text: '', class: 'text-center' },
    { id: 'visits', text: 'Visits', class: 'text-center' },
  ]

  static HOME_SALES_HEADERS: Array<TableHeader> = [
    { id: 'address', text: 'Address', class: 'text-center' },
    { id: 'sale_date', text: 'Sale Date', class: 'text-center' },
  ]

  static CANCELLATION_HEADERS: Array<TableHeader> = [
    { id: 'address', text: 'address', class: 'text-center' },
    {
      id: 'cancellation_date',
      text: 'Cancellation Date',
      class: 'text-center',
    },
  ]
}
