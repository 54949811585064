import axios from 'axios'
import { $axios } from '~/utils/api'
import PagedResponse from '~/models/pagedResponse'
import Lot from '~/models/lot'
import { LotInfo } from '~/models'

const END_POINT = '/map'
const END_POINT_V2 = 'v2/map'

const CancelToken = axios.CancelToken
const cancelTokens: {
  consumerMapHomes: any
  consumerMapHomesites: any
  propertiesList: any
} = {
  consumerMapHomes: null,
  consumerMapHomesites: null,
  propertiesList: null,
}

const getPriceParamString = (price: string[]) => {
  return price.length === 0
    ? `&price=[${price}]`
    : price
        .slice(1)
        .reduce(
          (accum: string, currentValue: string) => `${accum},[${currentValue}]`,
          `&price=[${price[0]}]`
        )
}

const parseHomeParams = (parameters: any): string => {
  const params = { ...parameters }
  let query = ''
  if (params.price) {
    query += getPriceParamString(params.price)
    delete params.price
  }
  if (params.page) {
    query += `&page=${params.page}`
    delete params.page
  }
  if (params.beds) {
    query += `&beds=${params.beds}`
    delete params.beds
  }
  if (params.baths) {
    query += `&baths=${params.baths}`
    delete params.baths
  }
  if (params.stories) {
    query += `&stories=${params.stories}`
    delete params.stories
  }
  if (params.garages) {
    query += `&garages=${params.garages}`
    delete params.garages
  }
  if (params.sqft_min) {
    query += `&sqft_min=${params.sqft_min}`
    delete params.sqft_min
  }
  if (params.sqft_max) {
    query += `&sqft_max=${params.sqft_max}`
    delete params.sqft_max
  }
  if (params.price_min) {
    query += `&price_range_min=${params.price_min}`
    delete params.price_min
  }
  if (params.price_max) {
    query += `&price_range_max=${params.price_max}`
    delete params.price_max
  }
  if (params.move_in_date) {
    query += `&move_in_date=${params.move_in_date}`
    delete params.move_in_date
  }
  if (params.builder) {
    const builder = params.builder.reduce(
      (tally: string, builder: number, i: number) => {
        if (i) tally += ','
        return (tally += builder)
      },
      ''
    )

    query += `&builder=${builder}`
    delete params.builder
  }
  if (params.style) {
    const style = params.style.reduce(
      (tally: string, style: number, i: number) => {
        if (i) tally += ','
        return (tally += style)
      },
      ''
    )

    query += `&style=${style}`
    delete params.style
  }

  if (params.neighborhood) {
    const neighborhood = params.neighborhood.reduce(
      (tally: string, style: number, i: number) => {
        if (i) tally += ','
        return (tally += style)
      },
      ''
    )

    query += `&neighborhood=${neighborhood}`
    delete params.neighborhood
  }

  if (params.acreage) {
    const acreage = params.acreage.reduce(
      (tally: string, price: number[], i: number) => {
        if (i) tally += ','
        return (tally += JSON.stringify(price))
      },
      ''
    )

    query += `&acreage=${acreage}`
    delete params.acreage
  }

  if (params.sort) {
    query += `&sort=${params.sort}`
    delete params.sort
  }

  if (params.condoAt) {
    query += `&condo_at=${params.condoAt}`
    delete params.condoAt
  }

  // processing any custom filter that could pop up
  Object.keys(params).forEach((key) => {
    query +=
      `&${key}=` +
      params[key].reduce((tally: string, element: number[], i: number) => {
        if (i) tally += ','
        return (tally += element)
      }, '')
  })

  return query
}

const getProject = (slug: string) =>
  $axios.$get(`${END_POINT}/consumer/projects/${slug}`)

const getLotsStatus = (slug: string, params: any = {}): Promise<any> => {
  let request = `${END_POINT}/consumer/lots?project=${slug}`
  request += parseHomeParams(params)

  return $axios.$get(request)
}

const getConsumerLot = (parcelId: string): Promise<Lot> =>
  $axios.$get(`${END_POINT}/consumer/lots?parcel_id=${parcelId}`)

const getModels = (slug: string): Promise<Lot[]> => {
  const request = `${END_POINT}/consumer/models?project=${slug}`
  return $axios.$get(request)
}

const getConsumerMapHomes = (
  slug: string,
  params: any
): Promise<PagedResponse<LotInfo>> => {
  let request = `${END_POINT_V2}/consumer/homes?project=${slug}`
  request += parseHomeParams(params)

  cancelPreviousToken('consumerMapHomes')
  cancelTokens.consumerMapHomes = CancelToken.source()
  return $axios.$get(request, {
    cancelToken: cancelTokens.consumerMapHomes.token,
  })
}

const cancelPreviousToken = (name: string): void => {
  if (cancelTokens[name]) {
    cancelTokens[name].cancel()
    cancelTokens[name] = null
  }
}

const getConsumerMapHomesites = (
  slug: string,
  params: any
): Promise<PagedResponse<LotInfo>> => {
  let request = `${END_POINT_V2}/consumer/homesites?project=${slug}`
  request += parseHomeParams(params)

  cancelPreviousToken('consumerMapHomesites')
  cancelTokens.consumerMapHomesites = CancelToken.source()

  return $axios.$get(request, {
    cancelToken: cancelTokens.consumerMapHomesites.token,
  })
}

const getLotAditionalPhotos = (slug: string): Promise<Array<string>> =>
  $axios.$get(`${END_POINT}/consumer/lots/${slug}/additional_photos`)

const getBulkConsumerMapProperties = (
  slug: string,
  body: any
): Promise<LotInfo[]> => {
  const request = `${END_POINT}/consumer/properties?project=${slug}`
  return $axios.$post(request, body)
}

const getConsumerMapFilter = (
  filter: string,
  slug: string
): Promise<Array<{ value: string; label: string }>> =>
  $axios.$get(`${END_POINT}/consumer/filters/${filter}?project=${slug}`)

const saveUsersRequestForMoreInformationAboutLotAsync = (
  slug: string,
  data: any
): Promise<any> => {
  return $axios.$post(`${END_POINT}/consumer/${slug}/request_info`, data)
}

const getParcelIdsByType = (
  type: string,
  project: string,
  params: { [key: string]: string } = {}
): Promise<Array<string>> =>
  $axios.get(
    `${END_POINT}/consumer/lots/${type}?project=${project}${parseHomeParams(
      params || {}
    )}`
  )

export {
  getProject,
  getModels,
  getLotsStatus,
  getConsumerLot,
  getConsumerMapHomes,
  getConsumerMapHomesites,
  getConsumerMapFilter,
  parseHomeParams,
  getParcelIdsByType,
  getBulkConsumerMapProperties,
  saveUsersRequestForMoreInformationAboutLotAsync,
  getLotAditionalPhotos,
}
