import createPersistedState from 'vuex-persistedstate'

export default ({ store }) => {
  try {
    createPersistedState()(store)
  } catch (error) {
    if (
      !window.location.href.includes('/access_disabled/cookies') &&
      !window.location.href.includes('/map') &&
      !window.location.href.includes('/rental_map')
    )
      window.location.href = '/access_disabled/cookies'
  }
}
