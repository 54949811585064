import { Middleware } from '@nuxt/types'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/vue'

// @ts-ignore
const logRocket: Middleware = ({ $environmentConfig }) => {
  const host: string = $environmentConfig.baseURL
  const appId: string = process.env.logRocketId

  if (!host.includes('localhost') && appId) {
    LogRocket.init(appId, {
      release: process.env.appVersionReleased,
      rootHostname: host.replace('/o/api/', ''),
    })

    LogRocket.getSessionURL((sessionURL) => {
      const scope = Sentry.getCurrentScope()
      scope.setExtra('sessionURL', sessionURL)
    })
  }
}

export default logRocket
