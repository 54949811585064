import throttle from '~/utils/throttle'

const didTheScrollReachTheBottom = (
  mainElement: any,
  containerHeight?: number
): boolean => {
  const scrollTop = mainElement.scrollTop

  let scrollHeight = mainElement.scrollHeight
  if (containerHeight) {
    scrollHeight = containerHeight
  }
  // Added to force requests for new info to occur just before
  // the user reaches the bottom of the page.
  // Using 500px OR 85% of the scrollHeight, which ever is less, to
  // rein in excessive requests
  const fifteenPercent = scrollHeight * 0.85
  const minus500 = scrollHeight - 500
  scrollHeight = fifteenPercent < minus500 ? fifteenPercent : minus500
  const maxHeight = mainElement.clientHeight
  return scrollTop + maxHeight >= scrollHeight
}

const setScrollEvent = (
  callback: Function,
  mainElement: any,
  callbackArgs?: object
): any => {
  const eventHandler = () => {
    const reachTheBottom = didTheScrollReachTheBottom(mainElement)
    if (reachTheBottom) {
      throttle(() => {
        callback(callbackArgs)
      }, 300)
    }
  }
  return eventHandler.bind({})
}

const setScrollOnBottomEvent = (callback: Function, element?: any): any => {
  let mainElement = document.querySelector('main')
  if (!mainElement) {
    return
  }

  if (element) {
    mainElement = element
  }

  const newEventHandler = setScrollEvent(callback, mainElement)
  mainElement.addEventListener('scroll', newEventHandler)
  return newEventHandler
}

const removeScrollListener = (element?: any, handler?: any): void => {
  let mainElement = document.querySelector('main')
  if (!mainElement) {
    return
  }

  if (element) {
    mainElement = element
  }

  mainElement.removeEventListener('scroll', handler)
}

const scrollToTop = (element?: any) => {
  let mainElement = document.querySelector('main')

  if (element) {
    mainElement = element
  }

  if (mainElement) {
    mainElement.scrollTop = 0
  }
}

const scrollToBottom = () => {
  const mainElement = document.querySelector('main')
  if (mainElement) {
    mainElement.scrollTop = mainElement.scrollHeight
  }
}

const isMainContainerFilled = (
  containerId: string,
  extraHeight: number = 0
): boolean => {
  const mainElement: any = document.querySelector('main')
  const container: any = document.getElementById(`${containerId}`)
  return container.clientHeight + extraHeight > mainElement.clientHeight
}

export {
  didTheScrollReachTheBottom,
  setScrollOnBottomEvent,
  removeScrollListener,
  scrollToTop,
  scrollToBottom,
  isMainContainerFilled,
}
