import { prop } from '@rxweb/reactive-forms'
import { FilterFields, FilterOptions as Options } from '../filters'
import IFormFilter from './IFormFilter'
import { InputType } from '~/enums/inputType'
import { FilterTypes } from '~/enums/filterTypes'
import FormControl from '~/models/forms/FormControl'

export default class LotFilters implements IFormFilter {
  @prop() builders: FilterFields[]
  @prop() construction_phases: FilterFields[]
  @prop() blocks: FilterFields[]
  @prop() lot_sizes: FilterFields[]
  @prop() styles: FilterFields[]
  @prop() lot_types: FilterFields[]
  @prop() architectural_status: FilterFields[]
  @prop() segmentation: FilterFields[]
  @prop() plats: FilterFields[]
  @prop() high_profile: FilterFields[]
  @prop() lot_available: FilterFields[]

  getControls(options?: Options): FormControl[] {
    return [
      {
        id: FilterTypes.BUILDERS,
        label: 'Builders',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          options: [],
        },
      },
      {
        id: FilterTypes.CONSTRUCTION_PHASES,
        label: 'Phases',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          options: [],
        },
      },
      {
        id: FilterTypes.PLATS,
        label: 'Plats',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          options: [],
        },
      },
      {
        id: FilterTypes.BLOCKS,
        label: 'Blocks',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          options: [],
        },
      },
      {
        id: FilterTypes.ARCHITECTURAL_STATUS,
        label: 'Architectural Status',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          options: [],
        },
      },
      {
        id: options.isSegmentationEnabled
          ? FilterTypes.SEGMENTATION
          : FilterTypes.LOT_SIZES,
        label: options.isSegmentationEnabled ? 'Segmentation' : 'Sizes',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          options: [],
        },
      },
      {
        id: FilterTypes.STYLES,
        label: 'Styles',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          options: [],
        },
      },
      {
        id: FilterTypes.LOT_TYPES,
        label: 'Lot Types',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          options: [],
        },
      },
      {
        id: FilterTypes.HIGH_PROFILE,
        label: 'High Profile Lot',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          hideLabel: false,
          options: [
            {
              id: 'true',
              value: 'High Profile',
            },
            {
              id: 'false',
              value: 'Not High Profile',
            },
          ],
          singleSelectionNoDisable: true,
        },
      },
      {
        id: FilterTypes.LOT_AVAILABLE,
        label: 'Visible on Consumer Map',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          hideLabel: false,
          options: [
            {
              id: 'true',
              value: 'Available',
            },
            {
              id: 'false',
              value: 'Unavailable',
            },
          ],
          singleSelectionNoDisable: true,
        },
      },
    ]
  }

  getQueryParamFor(name: string, isMany: boolean = false) {
    const queryParamsNames: any = {
      builders: 'builder__id',
      construction_phases: 'phase',
      lot_sizes: 'size',
      styles: 'architectural_submission__master_plan__style__id',
      lot_types: 'type',
      architectural_status: 'architectural_submission__status',
      segmentation: 'segmentation',
      blocks: 'block',
      plats: 'plat',
      high_profile: 'high_profile',
      lot_available: 'available',
    }

    const queryName = queryParamsNames[name]
    return isMany ? `${queryName}__in` : queryName
  }

  refresh(): IFormFilter {
    return new LotFilters()
  }

  populateFields(lotFilters: LotFilters): void {
    if (!lotFilters) {
      return
    }
    this.builders = lotFilters.builders?.length ? lotFilters.builders : []
    this.construction_phases = lotFilters.construction_phases?.length
      ? lotFilters.construction_phases
      : []
    this.blocks = lotFilters.blocks?.length ? lotFilters.blocks : []
    this.lot_sizes = lotFilters.lot_sizes?.length ? lotFilters.lot_sizes : []
    this.styles = lotFilters.styles?.length ? lotFilters.styles : []
    this.lot_types = lotFilters.lot_types?.length ? lotFilters.lot_types : []
    this.architectural_status = lotFilters.architectural_status?.length
      ? lotFilters.architectural_status
      : []
    this.segmentation = lotFilters.segmentation?.length
      ? lotFilters.segmentation
      : []
    this.plats = lotFilters.plats?.length ? lotFilters.plats : []
  }
}
