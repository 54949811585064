import { prop } from '@rxweb/reactive-forms'
import { FilterFields, FilterOptions as Options } from '../filters'
import IFormFilter from './IFormFilter'
import { InputType } from '~/enums/inputType'
import { FilterTypes } from '~/enums/filterTypes'
import FormControl from '~/models/forms/FormControl'
import { PendingFieldsUpdated } from '~/enums/architectural'

export default class ArchitecturalFilters implements IFormFilter {
  @prop() builders: FilterFields[]
  @prop() construction_phases: FilterFields[]
  @prop() lot_sizes: FilterFields[]
  @prop() styles: FilterFields[]
  @prop() lot_types: FilterFields[]
  @prop() segmentation: Array<FilterFields>
  @prop() order_by: Array<FilterFields>
  @prop() plats: Array<FilterFields>
  @prop() previous_status: Array<string>
  @prop() variance: Array<FilterFields>
  @prop() high_profile: Array<FilterFields>
  @prop() approval_type: Array<FilterFields>
  @prop() pending_fields_updated: Array<FilterFields>

  getControls(options: Options): FormControl[] {
    return [
      {
        id: FilterTypes.ORDER_BY,
        label: 'Sorting',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          options: [],
          singleSelectionNoDisable: true,
        },
      },
      {
        id: FilterTypes.BUILDERS,
        label: 'Builders',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          options: [],
        },
      },
      {
        id: FilterTypes.CONSTRUCTION_PHASES,
        label: 'Phases',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          options: [],
        },
      },
      {
        id: FilterTypes.PLATS,
        label: 'Plats',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          options: [],
        },
      },
      {
        id: options.isSegmentationEnabled
          ? FilterTypes.SEGMENTATION
          : FilterTypes.LOT_SIZES,
        label: options.isSegmentationEnabled ? 'Segmentation' : 'Sizes',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          options: [],
        },
      },
      {
        id: FilterTypes.STYLES,
        label: 'Styles',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          options: [],
        },
      },
      {
        id: FilterTypes.LOT_TYPES,
        label: 'Lot Types',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          options: [],
        },
      },
      {
        id: FilterTypes.ARCH_PENDING_VISUAL_TYPES,
        label: 'Pending Submittal Types',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          options: [
            {
              id: 'awaiting', // previous_status for backend
              value: 'New Submittal', // tag or type for UI
            },
            {
              id: 'approved',
              value: 'Approved Submittal',
            },
            {
              id: 'denied',
              value: 'Denied Resubmit',
            },
          ],
        },
      },
      {
        id: FilterTypes.VARIANCE,
        label: 'Variances',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          hideLabel: false,
          options: [
            {
              id: 'true',
              value: 'With  Variance',
            },
            {
              id: 'false',
              value: 'Without Variance',
            },
          ],
          singleSelectionNoDisable: true,
        },
      },
      {
        id: FilterTypes.ARCH_APPROVAL_TYPES,
        label: 'Approval Types',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          options: [
            {
              id: 'no_conditions',
              value: 'Approved with no conditions',
            },
            {
              id: 'with_conditions',
              value: 'Approved with conditions',
            },
          ],
          singleSelectionNoDisable: true,
        },
      },
      {
        id: FilterTypes.HIGH_PROFILE,
        label: 'High Profile Lot',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          hideLabel: false,
          options: [
            {
              id: 'true',
              value: 'High Profile',
            },
            {
              id: 'false',
              value: 'Not High Profile',
            },
          ],
          singleSelectionNoDisable: true,
        },
      },
      {
        id: FilterTypes.PENDING_FIELDS_UPDATED,
        label: 'Pending Fields Updated',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          singleSelectionNoDisable: false,
          options: [
            {
              id: PendingFieldsUpdated.PLAN_ELEVATION,
              value: 'Plan Elevation',
            },
            {
              id: PendingFieldsUpdated.MATERIAL,
              value: 'Material',
            },
            {
              id: PendingFieldsUpdated.FILES,
              value: 'Files',
            },
          ],
        },
      },
    ]
  }

  getQueryParamFor(name: string, isMany: boolean = false) {
    const queryParamsNames: any = {
      builders: 'lot__builder_id',
      construction_phases: 'lot__phase',
      lot_sizes: 'lot__size',
      styles: 'master_plan__style_id',
      lot_types: 'lot__type',
      plats: 'lot__plat',
      high_profile: 'lot__high_profile',
    }

    const queryName = queryParamsNames[name] ? queryParamsNames[name] : name
    if (queryName === 'previous_status') return queryName
    return isMany ? `${queryName}__in` : queryName
  }

  refresh(): IFormFilter {
    return new ArchitecturalFilters()
  }

  populateFields(architecturalFilters: ArchitecturalFilters): void {
    if (!architecturalFilters) {
      return
    }

    this.builders = architecturalFilters.builders?.length
      ? architecturalFilters.builders
      : []
    this.construction_phases = architecturalFilters.construction_phases?.length
      ? architecturalFilters.construction_phases
      : []
    this.lot_sizes = architecturalFilters.lot_sizes?.length
      ? architecturalFilters.lot_sizes
      : []
    this.styles = architecturalFilters.styles?.length
      ? architecturalFilters.styles
      : []
    this.lot_types = architecturalFilters.lot_types?.length
      ? architecturalFilters.lot_types
      : []
    this.segmentation = architecturalFilters.segmentation?.length
      ? architecturalFilters.segmentation
      : []
  }
}
