import { prop, required } from '@rxweb/reactive-forms'
import { InputType } from '~/enums/inputType'
import { Takedown } from '~/models'

export default class TakedownForm {
  @prop()
  id: number

  @required({ message: 'Date is a required field' })
  required_purchase_by_date: string

  @required({ message: 'Required lots is a required field' })
  required_purchase_lots_count: number

  @prop() purchased_lots_count: number

  get controls(): any[] {
    return [
      {
        id: 'required_purchase_by_date',
        label: 'Date',
        type: InputType.date,
        required: true,
        settings: {},
      },
      {
        id: 'required_purchase_lots_count',
        label: 'Required Lots',
        type: InputType.number,
        required: true,
        settings: {},
      },
    ]
  }

  populateFields(takedown: Takedown): void {
    if (!takedown) {
      return
    }

    this.id = takedown.id ?? null
    this.required_purchase_by_date = takedown.required_purchase_by_date ?? null
    this.required_purchase_lots_count =
      takedown.required_purchase_lots_count ?? null
    this.purchased_lots_count = takedown.purchased_lots_count ?? null
  }
}
