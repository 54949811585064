


















import { Component, Vue, Prop, Emit } from 'nuxt-property-decorator'
import { InputType } from '~/enums/inputType'
import { TagColors } from '~/enums/tagColors'
import { Tag } from '~/models'

@Component({
  components: {},
})
export default class TagComponent extends Vue {
  @Prop() id: string | number
  @Prop({ default: '' }) label: string
  @Prop({ default: false }) isClosable: boolean
  @Prop({ default: 'trasparent' }) tagColor: TagColors
  @Prop({ default: false }) textCentered: boolean
  @Prop({ default: '' }) control: string
  @Prop({ default: (): InputType => null }) type: InputType
  @Prop({ default: false }) tableRow: boolean

  @Emit('onCloseTag')
  onClose(): Tag {
    return {
      id: this.id,
      label: this.label,
      control: this.control,
      type: this.type,
    }
  }
}
