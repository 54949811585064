




























import { Component, Watch, Emit, Vue } from 'nuxt-property-decorator'

@Component({})
export default class SeasonPicker extends Vue {
  summerIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.536" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
  </svg>`

  winterIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="0.1" d="M21,11H18.87l1-1.45a1,1,0,1,0-1.66-1.1L16.46,11H13V7.54l2.55-1.71a1,1,0,0,0-1.1-1.66L13,5.13V3a1,1,0,0,0-2,0V5.13l-1.45-1a1,1,0,0,0-1.1,1.66L11,7.54V11H7.54L5.83,8.45a1,1,0,0,0-1.66,1.1l1,1.45H3a1,1,0,0,0,0,2H5.13l-1,1.45a1,1,0,0,0,.28,1.38A.94.94,0,0,0,5,16a1,1,0,0,0,.83-.45L7.54,13H11v3.46L8.45,18.17a1,1,0,0,0,1.1,1.66l1.45-1V21a1,1,0,0,0,2,0V18.87l1.45,1A.94.94,0,0,0,15,20a1,1,0,0,0,.55-1.83L13,16.46V13h3.46l1.71,2.55A1,1,0,0,0,19,16a.94.94,0,0,0,.55-.17,1,1,0,0,0,.28-1.38l-1-1.45H21a1,1,0,0,0,0-2Z" />
</svg>`

  summerSeason: Boolean = true

  @Watch('summerSeason')
  @Emit('seasonChange')
  onSeasonPickerChange(value: boolean): boolean {
    return value
  }

  toggleTheme() {
    this.summerSeason = !this.summerSeason
    this.switchTheme()
  }

  switchTheme() {
    const switchToggle = this.$refs.switchToggle as Element
    if (switchToggle) {
      if (!this.summerSeason) {
        switchToggle.classList.remove('bg-yellow-500', '-translate-x-2')
        switchToggle.classList.add('bg-gray-700', 'translate-x-10')
        setTimeout(() => {
          switchToggle.innerHTML = this.winterIcon
        }, 250)
      } else {
        switchToggle.classList.add('bg-yellow-500', '-translate-x-2')
        switchToggle.classList.remove('bg-gray-700', 'translate-x-10')
        setTimeout(() => {
          switchToggle.innerHTML = this.summerIcon
        }, 250)
      }
    }
  }
}
