import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import BaseState from '~/models/state/baseState'

@Module({
  name: 'builder',
  stateFactory: true,
  namespaced: true,
})
export default class Builder extends VuexModule implements BaseState {
  currentBuilder: number = null

  get activeBuilder(): number {
    return this.currentBuilder
  }

  @Action({ rawError: true })
  changeActiveBuilder(id: number) {
    this.setActiveBuilder(id)
  }

  @Mutation
  setActiveBuilder(id: number) {
    this.currentBuilder = id
  }

  @Mutation
  clear(): void {
    this.currentBuilder = null
  }
}
