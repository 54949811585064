import { prop, required } from '@rxweb/reactive-forms'
import FormControl from './FormControl'
import { InputType } from '~/enums/inputType'
import { MiscSubmittalStatus } from '~/enums/miscSubmittal'

export default class MiscSubmittalReviewForm {
  @required() status: string
  @prop() comments: string
  @prop() conditions: string
  @prop() files: Array<number>

  static CONTROLS: Array<FormControl> = [
    {
      label: 'Status',
      id: 'status',
      type: InputType.select,
      parentClass: 'w-full mb-2',
      required: true,
      settings: {
        description:
          'Select the status for this submittal review. This will teigger an email to the builder ser',
        options: [
          {
            id: MiscSubmittalStatus.APPROVED,
            label: MiscSubmittalStatus.APPROVED,
          },
          {
            id: MiscSubmittalStatus.DENIED,
            label: MiscSubmittalStatus.DENIED,
          },
        ],
      },
    },
    {
      label: 'Comments',
      id: 'comments',
      type: InputType.text,
      parentClass: 'w-full mb-2',
      required: false,
      settings: {
        description:
          'Include any additional comments to assist the builder with their approval',
        isTextArea: true,
      },
    },
    {
      label: 'Conditions',
      id: 'conditions',
      type: InputType.text,
      parentClass: 'w-full mb-2',
      required: false,
      settings: {
        description:
          'Include any additional conditions to assist the builder with their approval',
        isTextArea: true,
      },
    },
    {
      label: 'Include a File',
      id: 'files',
      type: InputType.fileUpload,
      parentClass: 'w-full',
      required: false,
      settings: {
        description:
          'Upload any additional feedback to further assist builder with approval',
      },
    },
  ]
}
