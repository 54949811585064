import { Agency } from '~/models'
import { $axios } from '~/utils/api'

const USERS_ENDPOINT = '/realtor_agency'

const getRealtorAgency = (id: number): Promise<Agency> =>
  $axios.$get(`${USERS_ENDPOINT}/${id}`)

const createRealtorAgency = (formData: any): Promise<Agency> => {
  return $axios.$post(`${USERS_ENDPOINT}`, formData)
}

const editRealtorAgency = (id: number, formData: any): Promise<Agency> =>
  $axios.$put(`${USERS_ENDPOINT}/${id}`, formData)

export { createRealtorAgency, getRealtorAgency, editRealtorAgency }
