


































































































import { Component, Prop, Emit } from 'nuxt-property-decorator'
import BaseInputComponent from './BaseInput.vue'

@Component({})
export default class TextInput extends BaseInputComponent {
  @Prop({ default: 'text' })
  type: string

  @Prop({ default: false })
  isTextArea: boolean

  @Prop({ default: 5 })
  textAreaRows: number

  @Prop({ default: false })
  isFeatureList: boolean

  @Prop({ default: false })
  isPhoneNumber: boolean

  @Prop({ default: (): string => null })
  prefix: string

  @Prop({ default: 0 })
  minValue: number

  @Prop({ default: 0 })
  validateZipCode: boolean

  bullet = '\u2022'
  previousLength: number = 0

  private handleInputBulletsList(event: any): void {
    const newLength = event.target.value.length
    const characterCode = event.target.value.substr(-1).charCodeAt(0)

    if (newLength > this.previousLength) {
      if (characterCode === 10) {
        event.target.value = `${event.target.value}${this.bullet} `
      } else if (newLength === 1) {
        event.target.value = `${this.bullet} ${event.target.value}`
      }
    }

    this.previousLength = newLength
  }

  parseFeatureList(value: string | Array<string>) {
    if (!value) return
    if (typeof value === 'string') {
      return value
    }
    return value
      .map((feature: string, index: number) => {
        if (value.length - 1 !== index)
          return `${this.bullet} ${feature.concat('\n')}`
        else return `${this.bullet} ${feature}`
      })
      .join('')
  }

  @Emit('input')
  handleInput(event: any): string | number | boolean | any {
    if (this.isFeatureList) {
      this.handleInputBulletsList(event)
      this.processFeatureList(event.target.value)
    }
    return event
  }

  @Emit('feature-list')
  processFeatureList(value: string) {
    return value
      .split(this.bullet)
      .map((feature) => feature.trim())
      .filter((feature) => feature)
      .map((feature) =>
        feature.endsWith('\n') ? feature.replace('\n', '') : feature
      )
  }

  @Emit('validateField')
  handleBlur(): any {}
}
