





















































































import { Component, Emit, Prop, Vue, Watch } from 'nuxt-property-decorator'
import TitleInput from './TitleInput.vue'
import Style from '~/models/style'
import ListRow from '~/components/shared/list/ListRow.vue'

@Component({
  components: {
    TitleInput,
    ListRow,
  },
})
export default class ButtonSelectInput extends Vue {
  @Prop()
  id: string

  @Prop()
  heapId: string

  @Prop()
  label: string

  @Prop()
  description: string

  @Prop()
  options: Style

  @Prop({ default: (): any => null })
  value: any[]

  selected: any[]

  @Prop({ default: 2 })
  columns: number

  @Prop()
  titleSize: string

  @Prop()
  subTitleSize: string

  @Prop({ default: false })
  required: boolean

  @Prop({ default: 'No Elements to display' })
  warningMessage: string

  @Prop({ default: false })
  disabled: boolean

  constructor() {
    super()
    if (this.value) {
      this.selected = this.value
    } else {
      this.selected = []
    }
  }

  @Watch('value')
  onValueChanged() {
    this.selected = this.value
  }

  @Emit('input')
  handleInput(selectedValue: any) {
    if (this.selected?.includes(selectedValue)) {
      const index = this.selected.indexOf(selectedValue)
      if (index > -1) {
        this.selected.splice(index, 1)
      }
    } else {
      this.selected.push(selectedValue)
    }

    return this.selected
  }

  @Emit('validateField')
  handleBlur(): any {}

  getOptions(disabled: boolean) {
    return { trigger: disabled ? 'mouseenter' : '' }
  }
}
