


























































































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import { TableHeader } from '@/models'
import CheckboxInput from '~/components/shared/inputs/Checkbox.vue'

@Component({
  components: {
    CheckboxInput,
  },
})
export default class Table extends Vue {
  @Prop({ default: (): TableHeader[] => [] }) headers: TableHeader[]
  @Prop({ default: false }) selectEnabled: boolean
  @Prop({ default: '' }) tableClass: string
  @Prop({ default: false }) showPagination: boolean
  @Prop({ default: 0 }) lastPage: number

  actualPage: number = 1
  selectAll: boolean = false

  sortId: string = null
  sortOrder: string = null

  handleSort(header: TableHeader) {
    if (this.sortOrder === 'asc') {
      this.sortId = null
      this.sortOrder = null
    } else {
      this.sortId = header.id
      this.sortOrder = this.sortOrder === null ? 'desc' : 'asc'
    }
    this.onSort()
  }

  handleSelect($event: any) {
    this.selectAll = $event.target.checked
    this.onSelect()
  }

  @Emit('onSelect')
  onSelect() {
    return this.selectAll
  }

  @Emit('onSort')
  onSort() {
    return { id: this.sortId, order: this.sortOrder }
  }

  resetSelect() {
    this.selectAll = false
  }

  @Emit('onPrevious')
  onPrevious(): number {
    if (this.actualPage > 1) {
      this.actualPage--
    }
    return this.actualPage
  }

  @Emit('onNext')
  onNext(): number {
    if (this.actualPage < this.lastPage) {
      this.actualPage++
    }
    return this.actualPage
  }
}
