import { Places } from '~/enums/heap'

export default (_, inject) => {
  inject('heap', (email, user, project, location) => {
    const heapID = process.env.heapId
    if (heapID) {
      const script = document.querySelector(
        `script[src="https://cdn.heapanalytics.com/js/heap-${heapID}.js"]`
      )

      const config = {
        secureCookie: true,
      }

      const events = [
        'addEventProperties',
        'addUserProperties',
        'clearEventProperties',
        'identify',
        'resetIdentity',
        'removeEventProperty',
        'setEventProperties',
        'track',
        'unsetEventProperty',
      ]

      let enableHeapMapv2 = true
      let enableHeapMapV3 = true
      let enableHeapXO = true

      if (project) {
        enableHeapMapV3 = project.config.enable_heap_consumer_map_v3
        enableHeapMapv2 = project.config.enable_heap_consumer_map_v2
        enableHeapXO = project.config.enable_heap_xo
      }

      const loadHeap = () => {
        if (!enableHeapMapv2 && Places.MAP_V2 === location) {
          return
        }

        if (!enableHeapMapV3 && Places.MAP_V3 === location) {
          return
        }

        if (!enableHeapXO && Places.DEFAULT_LAYOUT === location) {
          return
        }

        const heap = window.heap
        if (heap) {
          heap.load(heapID, config, events)
          heap.identify(email)
          heap.addUserProperties(user)
        }
      }

      if (!script) {
        setTimeout(() => loadHeap(), 300)
      }
    }
  })
}
