import { SourceCategories } from '~/enums/trafficReport'
import { AdvancedReport, NormalReport, Source } from '~/models'
import { $axios } from '~/utils/api'

const END_POINT = '/traffic_report'

const createReport = (
  data: NormalReport | AdvancedReport
): Promise<AdvancedReport | NormalReport> => $axios.$post(END_POINT, data)

const updateReport = (
  id: number,
  data: NormalReport | AdvancedReport
): Promise<AdvancedReport | NormalReport> =>
  $axios.$patch(`${END_POINT}/${id}`, data)

const retrieveSources = (category: SourceCategories): Promise<Array<Source>> =>
  $axios.get(`${END_POINT}/sources/${category}`)

const retrieveLotsData = (
  type: string,
  size: number,
  sold_on: Array<string>,
  cancelled_on: Array<string>
): Promise<Array<Source>> => {
  let url: string = `${END_POINT}/lots?type=${type}&size=${size}`

  if (sold_on) {
    url = `${url}&sold_on=${sold_on}`
  }

  if (cancelled_on) {
    url = `${url}&cancelled_on=${cancelled_on}`
  }
  return $axios.get(url)
}

const retrieveReport = (
  startDate: string,
  endDate: string,
  lotSize: number
): Promise<NormalReport | AdvancedReport> => {
  if (!startDate || !endDate || !lotSize) {
    return
  }

  return $axios.get(
    `${END_POINT}?start_date=${startDate}&end_date=${endDate}&size=${lotSize}`
  )
}

const lastWeekReport = (): Promise<boolean> =>
  $axios.get(`${END_POINT}/reports/last_week`)

export {
  createReport,
  updateReport,
  retrieveSources,
  retrieveReport,
  lastWeekReport,
  retrieveLotsData,
}
