var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav-bar w-full lg:hidden border-t border-b border-brand-neutral flex print-hidden"},[_c('div',{staticClass:"items-center border-r border-l border-brand-neutral flex-grow",class:{
      active: _vm.getActiveRoute() === _vm.$route.params.slug,
    }},[_c('NuxtLink',{attrs:{"to":{ name: 'v3-rental_map-slug' }}},[_c('button',{staticClass:"h-full px-4 text-lg"},[_c('fa',{attrs:{"icon":['axis', 'mapOutlined']}}),_vm._v(" "),_c('span',{staticClass:"text-sm"},[_vm._v("MAP VIEW ")])],1)])],1),_vm._v(" "),(_vm.isShareWithMeVisible)?_c('div',{staticClass:"items-center border-r border-brand-neutral flex-grow",class:{
      'ml-auto border-l': _vm.isFavoritesOrSharedRoute,
      active: _vm.getActiveRoute() === 'shared-with-me',
    }},[_c('NuxtLink',{attrs:{"to":{
        name: 'v3-rental_map-slug-shared-with-me',
        params: { slug: _vm.$route.params.slug },
      }}},[_c('button',{staticClass:"h-full px-4 text-lg"},[_c('fa',{attrs:{"icon":['axis', 'dataSharing']}}),_vm._v(" "),_c('span',{staticClass:"text-sm"},[_vm._v("SHARED "),_c('span',{staticClass:"whitespace-nowrap"},[_vm._v("WITH ME")])])],1)])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"items-center border-r border-brand-neutral flex-grow",class:{
      'ml-auto': _vm.isFavoritesRoute,
      active: _vm.getActiveRoute() === 'favorites',
    }},[_c('NuxtLink',{attrs:{"to":{
        name: 'v3-rental_map-slug-favorites',
        params: { slug: _vm.$route.params.slug },
      }}},[_c('button',{staticClass:"h-full px-4 text-lg"},[(_vm.favCount > 0)?_c('span',{staticClass:"fa-stack v-middle"},[_c('fa',{staticClass:"fa-stack-2x",attrs:{"icon":['axis', 'heart']}}),_vm._v(" "),_c('span',{staticClass:"fa-stack-1x fav-count"},[_vm._v(_vm._s(_vm.favCount))])],1):_c('span',{staticClass:"fa-stack v-middle"},[_c('fa',{staticClass:"fa-stack-1x",attrs:{"icon":['axis', 'heartOutlined']}})],1),_vm._v(" "),_c('span',{staticClass:"text-sm v-middle"},[_vm._v("FAVORITES")])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }