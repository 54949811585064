










































import { Component, mixins } from 'nuxt-property-decorator'
import { mapState } from 'vuex'
import MapFiltersV3 from '~/components/rental_map_v3/MapFiltersV3.vue'
import MobileNavBar from '~/components/rental_map_v3/MobileNavBar.vue'
import { Places } from '~/enums/heap'
import globalStyling from '~/mixins/globalStyling'
import { rentalMapStore } from '~/store'

@Component({
  components: {
    MapFiltersV3,
    MobileNavBar,
  },
  computed: {
    ...mapState('rental-map', {
      activeView: 'activeView',
      totalHomesFiltered: 'totalHomesFiltered',
      totalHomeSitesFiltered: 'totalHomeSitesFiltered',
      propertiesCount: 'propertiesCount',
      filterVisibilitySettings: 'filterVisibilitySettings',
      debouncedFilteredRequests: 'debouncedFilteredRequests',
    }),
  },
  mixins: [globalStyling],
})
export default class MapViewLayout extends mixins(globalStyling) {
  created(): void {
    this.loadProjectsSpecificStyle(this.$route.params.slug).then(() => {
      rentalMapStore.setFilterVisibilitySettings(
        this.getFilterVisibilitySettings
      )
    })
  }

  mounted() {
    // @ts-ignore
    if (this.$heap) this.$heap(null, null, null, Places.RENTAL_MAP)
  }

  handleFilterChange(filters: any[]): void {
    if (rentalMapStore.debouncedFilteredRequests) {
      rentalMapStore.debouncedFilteredRequests(filters)
    }
  }

  async loadProjectsSpecificStyle(projectId: string) {
    await import(`assets/css/${projectId}.css`)
  }

  get getFilterVisibilitySettings(): Object {
    return {
      price: this.isVisible('--ad-cm-filter-price'),
      bedrooms: this.isVisible('--ad-cm-filter-bedrooms'),
      builder: this.isVisible('--ad-cm-filter-builder'),
      available: this.isVisible('--ad-cm-filter-available'),
      architecture: this.isVisible('--ad-cm-filter-architecture'),
      floorplan: this.isVisible('--ad-rm-filter-floorplan'),
    }
  }

  seasonChange(value: boolean): void {
    this.$nuxt.$emit('rentalMap:v3:seasonChanged', value)
  }
}
