var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sm:col-span-4 overflow-visible",class:{ 'grayed-out-input': _vm.disabled },attrs:{"tabindex":"-1"}},[_c('div',{staticClass:"mt-1 flex rounded-md shadow-sm",attrs:{"tabindex":"0"},on:{"focusout":_vm.handleBlur}},[_c('span',{class:[
        'inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm',
        {
          'border-red-500': _vm.error,
        } ]},[_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z","clip-rule":"evenodd"}})])]),_vm._v(" "),_c('client-only',[_c('div',{staticClass:"relative w-full"},[_c('date-picker',{attrs:{"id":_vm.id,"calendar-class":{
            'right-0': _vm.align === 'right',
            'left-0': _vm.align === 'left',
            top: _vm.position === 'top',
          },"input-class":[
            'flex-1 focus:ring-blue-500 focus:border-blue-500 block rounded-none rounded-r-md sm:text-sm border-gray-300',
            {
              'border-red-500': _vm.error,
            },
            _vm.isLargeInput ? 'w-32' : 'w-full min-w-0' ],"placeholder":_vm.placeholder,"name":_vm.name,"disabled":_vm.disabled,"disabled-dates":_vm.disableDates(),"typeable":true},on:{"input":_vm.handleInput},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_vm._v(" "),(!_vm.disabled)?_c('div',{staticClass:"absolute top-2 right-2"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.date),expression:"date"}],attrs:{"type":"button"},on:{"click":_vm.clearDate}},[_c('fa',{attrs:{"icon":['axis', 'clear'],"size":"lg"}})],1)]):_vm._e()],1)])],1),_vm._v(" "),(_vm.error && _vm.errorText)?_c('small',{staticClass:"text-red-500"},[_vm._v("\n    "+_vm._s(_vm.errorText)+"\n  ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }