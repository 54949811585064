import { prop, required } from '@rxweb/reactive-forms'
import { AdvancedReport } from '../trafficReport'
import FormControl from './FormControl'
import { InputType } from '~/enums/inputType'
import { LabelSizes } from '~/enums/labelSizes'

export default class AdvancedTrafficForm {
  @prop()
  @required({ message: 'No report week selected' })
  week_range: string[]

  @prop()
  @required({ message: 'Lot size required' })
  size: number

  constructor(report: AdvancedReport) {
    if (report) {
      this.week_range = [report.start_date, report.end_date]
      this.size = report.size
    }
  }

  static CONTROLS: Array<FormControl> = [
    {
      id: 'size',
      label: 'Model Home Lot Size',
      type: InputType.select,
      parentClass: 'mb-2',
      required: true,
      settings: {
        description:
          'Select the lot size associated with the model home of this traffic.',
        options: [],
        titleSize: LabelSizes.extraLarge,
      },
    },
    {
      id: 'week_range',
      type: InputType.dateRange,
      required: true,
      label: 'Report Week',
      parentClass: 'mb-2',
      settings: {
        opens: 'right',
        showSideRanges: false,
        singleDatePicker: true,
        weekRange: true,
        description:
          'Select the week to submit or update a weekly traffic report.',
        titleSize: LabelSizes.extraLarge,
      },
    },
  ]
}
