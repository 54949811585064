








import { Component, mixins } from 'nuxt-property-decorator'
import { mapState } from 'vuex'
import globalStyling from '~/mixins/globalStyling'
import { consumerMapStore } from '~/store'
import XofYCounter from '../consumer_map_v3/XofYCounter.vue'

@Component({
    components: {
        XofYCounter,
    },
    computed: {
        ...mapState('consumer-map', {
            propertiesCount: 'propertiesCount',
            totalHomesFiltered: 'totalHomesFiltered',
            totalHomeSitesFiltered: 'totalHomeSitesFiltered',
        }),
    },
    mixins: [globalStyling],
})
export default class PropertiesCounter extends mixins(globalStyling) {
    get propertiesShown(): Number {
        return (
        consumerMapStore.totalHomesFiltered +
            consumerMapStore.totalHomeSitesFiltered || 0
        )
    }
}

