import { prop, propObject } from '@rxweb/reactive-forms'
import Sale from '../sale'
import Realtor from '../realtor'
import BuyerForm from './buyerForm'
import SpouseForm from './spouseForm'
import RealtorForm from './realtorForm'
import { InputType } from '~/enums/inputType'

export default class SaleForm {
  @prop() children_ages: string[] = []
  @prop() close_estimated_on: string
  @propObject() buyer: BuyerForm
  @propObject() spouse: SpouseForm
  @propObject() realtor: RealtorForm

  constructor() {
    this.buyer = new BuyerForm()
    this.spouse = new SpouseForm()
    this.realtor = new RealtorForm()
  }

  get controls(): any[] {
    return [
      {
        id: 'close_estimated_on',
        label: 'Estimating Closing',
        type: InputType.date,
      },
      {
        id: 'buyer',
        type: 'nestedForm',
        controls: this.buyer.controls,
      },
      {
        id: 'spouse',
        type: 'nestedForm',
        controls: this.spouse.controls,
      },
      {
        id: 'realtor',
        type: 'nestedForm',
        controls: this.realtor.controls,
      },
      {
        id: 'children_ages',
        label: 'Child Age',
        type: InputType.select,
        options: [],
      },
    ]
  }

  populateFields(sale: Sale): void {
    this.close_estimated_on = sale.close_estimated_on as string
    this.buyer.populateFields(sale.buyer)
    this.spouse.populateFields(sale.buyer)
    this.realtor.populateFields(sale.realtor as Realtor)
  }
}
