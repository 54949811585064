

























































































import {
  Component,
  Vue,
  Emit,
  PropSync,
  Prop,
  Watch,
} from 'nuxt-property-decorator'
import Spinner from '@/components/shared/Spinner.vue'

@Component({
  components: {
    Spinner,
  },
})
export default class ActionAlert extends Vue {
  opened: boolean = false
  isLoading: boolean = false

  @PropSync('isValid', { default: true })
  isFormValid: boolean

  @PropSync('styleClass', { type: String, default: 'default' })
  style: string

  @Prop({ default: 'max-w-6xl' })
  maxWidth: string

  @Prop({ default: false })
  loading: boolean

  @Prop({ default: false })
  hideActionButtons: boolean

  @Prop({ default: 'Cancel' })
  cancelButtonText: string

  mounted() {
    this.opened = true
  }

  beforeDestroy() {
    this.opened = false
  }

  @Watch('loading')
  loadingChanged() {
    this.isLoading = this.loading
  }

  @Emit('onClose')
  onClose(value: boolean) {
    return value
  }
}
