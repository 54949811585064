






































































import { Component, Prop, mixins } from 'nuxt-property-decorator'
import CheckboxInput from '~/components/shared/inputs/Checkbox.vue'
import globalStyling from '~/mixins/globalStyling'

@Component({
  components: {
    CheckboxInput,
  },
  mixins: [globalStyling],
})
export default class MapSelect extends mixins(globalStyling) {
  @Prop({ default: '' }) title: String
  @Prop({ default: '' }) openedMenu: String
  @Prop({ default: false }) count: Boolean
  @Prop({ default: (): any[] => [] }) options: any[]
  @Prop({ default: (): any[] => [] }) selectedOptions: any[]
  @Prop({ default: true }) showBuilderCount: Boolean
  @Prop({ default: true }) toUppercaseTitle: boolean
  @Prop({ default: false }) toBoldFont: boolean
  @Prop({ default: true }) useTextBrandBase: boolean
  @Prop({ default: true }) addPaddingTitle: boolean

  isDisabled(value: any, type: any) {
    if (!this.selectedOptions.length) return

    const regexAllAny = /^all_|^any_/

    const allOrAnySelected = this.selectedOptions.some((opt) =>
      regexAllAny.test(opt.value)
    )

    if (allOrAnySelected) return !regexAllAny.test(value)
    if (type === 'sort') return this.sortIsDisabled(value)
    return regexAllAny.test(value)
  }

  sortIsDisabled(value: any) {
    const isAnySortSelected = this.selectedOptions.some(
      (opt) => opt.type === 'sort'
    )

    if (isAnySortSelected) {
      const isCurrentSortSelected = this.selectedOptions.some(
        (opt) => opt.value === value && opt.type === 'sort'
      )
      return !isCurrentSortSelected
    }
  }

  handleCheck($event: any) {
    const selectedOpts = [...this.selectedOptions]
    const chkbxValue = $event.target.value
    const chkbxChecked = $event.target.checked

    if (chkbxChecked) {
      const option = this.options.find(
        (opt) => opt.value.toString() === chkbxValue
      )

      selectedOpts.push(option)
    } else {
      const optionIndex = selectedOpts.findIndex(
        (opt) => opt.value.toString() === chkbxValue
      )

      selectedOpts.splice(optionIndex, 1)
    }

    this.$emit('handleCheck', selectedOpts)
  }

  getLabel(label: string) {
    if (label === 'Condo') {
      return (
        this.getStyleVariableValue('--v3-cm-filter-condo-label')?.replace(
          /['"]+/g,
          ''
        ) || 'Condo'
      )
    }
    return label
  }
}
