import { prop, required } from '@rxweb/reactive-forms'
import FormControl from './FormControl'
import { InputType } from '~/enums/inputType'
import { LabelSizes } from '~/enums/labelSizes'

export type ControlOptions = {
  isAutoCancellationDate?: boolean
  options?: Array<any>
}

export default class CancelSaleForm {
  @prop() to_spec: boolean

  @prop()
  @required()
  cancelled_reason: string

  @prop()
  @required({ message: 'Cancelled date is required' })
  cancelled_on: string

  constructor() {
    this.to_spec = true
    const today: Date = new Date()
    this.cancelled_on = today.toLocaleDateString('en-US')
  }

  static getControls({
    isAutoCancellationDate,
    options
  }: ControlOptions = {}): Array<FormControl> {
    const controls: Array<FormControl> = [
      {
        id: 'cancelled_reason',
        label: 'Select a reason for this cancellation',
        type: InputType.select,
        required: true,
        settings: {
          titleSize: LabelSizes.small,
          options: options,
        },
      },
      {
        id: 'to_spec',
        label: 'Does this home need to be transferred to a spec?',
        type: InputType.toggle,
        required: false,
        settings: {
          titleSize: LabelSizes.small,
          prefix: 'No',
          suffix: 'Yes',
        },
      },
    ]

    if (isAutoCancellationDate) {
      return controls
    }

    return [
      ...controls.slice(0, 1),
      {
        id: 'cancelled_on',
        label: 'Select cancellation date',
        type: InputType.date,
        required: true,
        settings: {
          titleSize: LabelSizes.small,
        },
      },
      ...controls.slice(1),
    ]
  }
}
