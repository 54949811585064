




























































import { Component, Vue, Watch } from 'nuxt-property-decorator'
import { mapGetters } from 'vuex'
import { useIdle } from '@vueuse/core'
import LogRocket from 'logrocket'
import Sidenav from '~/components/sidenav/Sidenav.vue'
import Footer from '~/components/layout/Footer.vue'
import Header from '~/components/layout/Header.vue'
import Loader from '~/components/layout/Loader.vue'
import ActionAlert from '~/components/shared/alerts/ActionAlert.vue'
import TrafficReport from '~/components/traffic_report/TrafficReport.vue'
import { User, Project } from '~/models'
import { uiStore } from '~/store'
import { Places } from '~/enums/heap'
import NotificationDialog from '~/components/shared/NotificationDialog.vue'

@Component({
  middleware: ['authenticated', 'authorized', 'logrocket'],
  components: {
    Sidenav,
    Footer,
    Header,
    Loader,
    TrafficReport,
    ActionAlert,
    NotificationDialog,
  },
  computed: {
    ...mapGetters('user', {
      user: 'user',
      project: 'project',
      notificationDate: 'notificationDate',
    }),
    ...mapGetters('ui', {
      showFooter: 'showFooter',
      showTrafficReport: 'showTrafficReport',
    }),
  },
})
export default class DefaultLayout extends Vue {
  user: User
  project: Project
  notificationDate: string
  showFooter: boolean
  showTrafficReport: boolean

  idleObj: any = null
  showNav: boolean = false
  alertShown: boolean = false
  shouldLogOut: boolean = false

  showLastWeekNotification: boolean = false
  notification: NodeJS.Timeout = null

  handleNavToggle() {
    this.showNav = !this.showNav
  }

  handleLogOut() {
    this.$store.dispatch('user/logout')
    this.$router.push('/login')
  }

  created(): void {
    this.idleObj = useIdle(this.idleTime * 60 * 1000) // idleTime minutes
    if (this.user) {
      this.$intercom('boot', {
        email: this.user.email,
      })
    } else {
      this.$store.dispatch('user/getUser')
    }
  }

  mounted(): void {
    // @ts-ignore
    if (this.$heap && this.user) {
      const userData = {
        name: this.user.name,
        role: this.user.role,
        builder: this.user.builder_name,
        developer: this.user.developer_name,
      }
      // @ts-ignore
      this.$heap(this.user.email, userData, null, Places.DEFAULT_LAYOUT)
    }

    if (this.user) {
      LogRocket.identify(`${this.user.id}-${this.user.email}`, {
        name: this.user.name,
        email: this.user.email,
        subscriptionType: 'pro',
      })
    }

    this.initNotificationDialog()
  }

  get isIdleLogoutEnabled(): boolean {
    if (this.project && this.idleTime > 0) {
      return true
    }
    return false
  }

  get idleTime(): number {
    return this.project?.config?.idle_timeout
  }

  initNotificationDialog(): void {
    if (!this.notificationDate) {
      return
    }

    const notificationDate: Date = new Date(this.notificationDate)
    const today: Date = new Date()

    if (today.getDate() !== notificationDate.getDate()) {
      return
    }

    // @ts-ignore
    const milliseconds = notificationDate - today
    const minutes = Math.floor(milliseconds / 60000)
    if (minutes <= 0) {
      this.showLastWeekNotification = true
    }

    this.handleTimeout(milliseconds)
  }

  handleTimeout(milliseconds: number) {
    this.notification = setTimeout(() => {
      this.showNotification()
      this.clearTimeout()
    }, milliseconds)
  }

  showNotification() {
    this.showLastWeekNotification = true
  }

  clearTimeout() {
    clearTimeout(this.notification)
  }

  onCloseNotification(): void {
    this.showLastWeekNotification = false
  }

  // idleObj.idle is a vue3 generated ref object
  @Watch('idleObj.idle.value')
  onIdleChange(): void {
    if (!this.isIdleLogoutEnabled) {
      return
    }
    if (this.idleObj.idle.value) {
      this.shouldLogOut = this.idleObj.idle.value
      setTimeout(() => {
        this.handleLogOut()
        this.alertShown = true
      }, 3000)
    }
  }

  @Watch('showTrafficReport')
  onTrafficReportShow(): void {
    const dialog: HTMLDialogElement = this.$refs
      .trafficDialog as HTMLDialogElement

    if (this.showTrafficReport) {
      dialog.showModal()
    } else {
      dialog.close()
    }
  }

  @Watch('notificationDate')
  onNotificationChanged(): void {
    this.initNotificationDialog()
  }

  destroyed(): void {
    this.$intercom('shutdown')
    this.clearTimeout()
    uiStore.closeTrafficReport()
  }

  @Watch('project')
  onProjectChange(): void {
    if (this.isIdleLogoutEnabled) {
      this.resetIdleTimer() // Reset idle timer when switching projects
    }
  }

  resetIdleTimer(): void {
    this.idleObj = useIdle(this.idleTime * 60 * 1000)
    this.alertShown = false
    this.shouldLogOut = false
  }
}
