import {
  prop,
  required,
  numeric,
  NumericValueType,
  minNumber,
  maxNumber,
  pattern,
} from '@rxweb/reactive-forms'
import Plan from '../plan'
import FormStep from './FormStep'
import FormControl from '~/models/forms/FormControl'
import { InputType } from '~/enums/inputType'
import { LabelSizes } from '~/enums/labelSizes'
import { PlanFormSteps } from '~/enums/plan_steps'
import { ElevationBulk } from '~/models'

type Options = {
  isSegmentationEnabled?: boolean
  isDeveloper?: boolean
  isBulkElevations?: boolean
  showPlatsLabel: boolean
}

const overviewControls = (
  isDeveloper?: boolean,
  showPlatsLabel?: boolean
): Array<FormControl> => {
  const controls: Array<FormControl> = [
    {
      id: 'name',
      label: 'Name',
      heapId: 'plan_name',
      type: InputType.text,
      parentClass: 'w-full',
      required: true,
      settings: {
        description:
          'Enter the customer-facing plan name (match how it is shown on your own website)',
        titleSize: LabelSizes.extraLarge,
      },
    },
    {
      id: 'available',
      label: 'Available',
      type: InputType.toggle,
      parentClass: isDeveloper ? 'w-1/4 pr-6' : 'w-1/3 pr-6',
      required: false,
      settings: {
        description:
          'Is this plan currently being actively sold in the community?',
        titleSize: LabelSizes.extraLarge,
        prefix: 'No',
        suffix: 'Yes',
        options: [
          {
            value: false,
            label: 'No',
          },
          {
            value: true,
            label: 'Yes',
          },
        ],
      },
    },
    {
      id: 'property_type',
      label: 'Property Type',
      type: InputType.select,
      parentClass: isDeveloper ? 'w-1/4 pr-6' : 'w-1/3 pr-6',
      required: true,
      settings: {
        description: 'Select the structure type of this plan',
        titleSize: LabelSizes.extraLarge,
        options: [],
      },
    },
    {
      id: 'phases',
      label: showPlatsLabel ? 'Plats' : 'Phases',
      type: InputType.select,
      parentClass: isDeveloper ? 'w-1/4 pr-6' : 'w-1/3 pr-6',
      required: false,
      settings: {
        description: showPlatsLabel
          ? 'Select the plats for this plan'
          : 'Select the phases for this plan',
        titleSize: LabelSizes.extraLarge,
        options: [],
      },
    },
    {
      id: 'description',
      label: 'Description',
      type: InputType.richTextField,
      parentClass: 'w-full',
      required: false,
      settings: {
        description:
          'Supply the marketing description for this plan. It will be used on the consumer-facing website',
        titleSize: LabelSizes.extraLarge,
        isTextArea: true,
      },
    },
    {
      id: 'features',
      label: 'Home Features',
      type: InputType.text,
      parentClass: 'w-full',
      required: false,
      settings: {
        description: 'Supply the marketing feature list for this plan',
        titleSize: LabelSizes.extraLarge,
        isTextArea: true,
        isFeatureList: true,
      },
    },
  ]

  if (isDeveloper) {
    return [
      ...controls.splice(0, 3),
      {
        id: 'builder_id',
        label: 'Builder',
        type: InputType.select,
        parentClass: 'w-1/4 pr-6',
        required: true,
        settings: {
          description: 'Select a builder',
          titleSize: LabelSizes.extraLarge,
          options: [],
        },
      },
      ...controls.splice(0),
    ]
  }
  return controls
}

export default class PlanForm {
  pk: number

  @required({ message: 'This field is required' }) name: string
  @prop() description: string = ''
  @prop() available: boolean = true
  @prop() property_type: string = 'home'

  @required({ message: 'Garage loading is a required field' })
  garage_loading: string

  @required({ message: 'Size is a required field' }) size: string[]

  @numeric({
    acceptValue: NumericValueType.PositiveNumber,
    allowDecimal: false,
    message: 'Stories must be greater than 0',
  })
  @maxNumber({ value: 10, message: 'Stories must be less than 10' })
  @required({ message: 'Stories is a required field' })
  stories: number

  @numeric({
    acceptValue: NumericValueType.PositiveNumber,
    allowDecimal: false,
    message: 'Beds must be greater than 0',
  })
  @maxNumber({ value: 10, message: 'Beds must be less than 10' })
  @minNumber({ value: 1, message: 'Beds must be greater than 0' })
  @required({ message: 'Beds is a required field' })
  beds: number

  @numeric({
    acceptValue: NumericValueType.PositiveNumber,
    allowDecimal: true,
    message: 'Baths must be greater than 0',
  })
  @maxNumber({ value: 10, message: 'Baths must be less than 10' })
  @minNumber({ value: 1, message: 'Baths must be equal or greater than 1' })
  @required({ message: 'Baths is a required field' })
  baths: number

  @numeric({
    acceptValue: NumericValueType.PositiveNumber,
    allowDecimal: true,
    message: '',
  })
  @maxNumber({ value: 10, message: 'Garages must be less than 10' })
  @required({ message: 'Garage is a required field' })
  garage: number

  @numeric({
    acceptValue: NumericValueType.PositiveNumber,
    allowDecimal: false,
    message: 'Square must be greater than 0',
  })
  @maxNumber({
    value: 10000,
    message: 'Square footage amount must be less than 10,000',
  })
  @minNumber({
    value: 500,
    message: 'Square footage amount must be greater than 500',
  })
  @required({ message: 'SQFT is a required field' })
  sqft: number

  @numeric({
    acceptValue: NumericValueType.PositiveNumber,
    allowDecimal: false,
    message: 'Price must be greater than 0',
  })
  @maxNumber({
    value: 5000000,
    message: 'Starting price amount must be less than $5,000,000',
  })
  @required({ message: 'Price is a required field' })
  price: number = 0

  @prop() file: number[]
  @prop() floor_plan_images: Array<number>
  @prop() interior_photos: Array<number>
  @required({ message: 'Builder is a required field' })
  builder_id: number

  @required({ message: 'Segmentation is a required field' })
  segmentations: string[]

  @pattern({
    expression: {
      url: /http(s)?\:\/\/[a-z0-9]+(\.[a-z0-9]+)?(\.[a-z]{2,6}){1,1}(\/.*)?/,
    },
    message: 'Invalid url format',
  })
  virtual_tour_url: string

  @prop() features: Array<string> = []
  @prop() phases: Array<string>

  @maxNumber({
    value: 100,
    message: 'Max value should be 100',
  })
  @minNumber({
    value: 0,
    message: 'Min value should be 0',
  })
  @numeric({
    acceptValue: NumericValueType.PositiveNumber,
    allowDecimal: false,
    message: 'Percentage must be numeric value',
  })
  @prop()
  percentage: number

  elevations: ElevationBulk[]

  static getSteps(options: Options): Array<FormStep> {
    const sizeOrSegmentationField: FormControl = {
      id: options.isSegmentationEnabled ? 'segmentations' : 'size',
      heapId: options.isSegmentationEnabled
        ? 'plan_segmentations'
        : 'plan_size',
      label: options.isSegmentationEnabled ? 'Segmentations' : 'Size',
      type: InputType.buttonMultiSelect,
      parentClass: 'w-full',
      required: true,
      settings: {
        description: options.isSegmentationEnabled
          ? 'Choose the product segmentation that matches this neighborhood and builder'
          : 'Choose the lot sizes based on the width of the lot for this specific plan',
        titleSize: LabelSizes.extraLarge,
        options: [],
      },
    }

    return [
      {
        id: PlanFormSteps.OVERVIEW,
        title: 'Overview',
        step: 1,
        valid: false,
        controls: overviewControls(options.isDeveloper, options.showPlatsLabel),
        heapId: 'plan-form_overview',
      },
      {
        id: PlanFormSteps.HOME_DETAILS,
        title: 'Home Details',
        step: 2,
        valid: false,
        heapId: 'plan-form_home-details',
        controls: [
          sizeOrSegmentationField,
          {
            id: 'stories',
            heapId: 'plan_stories',
            label: 'Stories',
            type: InputType.buttonSelect,
            parentClass: 'w-full',
            required: true,
            settings: {
              description:
                'Select the number of stories contained within this floor plan. If the floor plan contains a staircase, you can consider it two stories',
              titleSize: LabelSizes.extraLarge,
              options: [],
              stories: 0,
            },
          },
          {
            id: 'garage_loading',
            heapId: 'plan_garage-loading',
            label: 'Garage Loading',
            type: InputType.buttonSelect,
            parentClass: 'w-full',
            required: true,
            settings: {
              description:
                'Select the loading condition to determine the alignment of the driveway and garage in relation to the front of the lot.',
              titleSize: LabelSizes.extraLarge,
              options: [],
            },
          },
          {
            id: 'percentage',
            heapId: 'plan_garage-percentage',
            label: 'Percentage',
            type: InputType.number,
            parentClass: 'w-full pr-6',
            required: false,
            settings: {
              description: 'Enter percent of masonry used on facade of home',
              titleSize: LabelSizes.extraLarge,
            },
          },
        ],
      },
      {
        id: PlanFormSteps.FLOOR_PLAN,
        title: 'Floor Plan',
        step: 3,
        valid: false,
        heapId: 'plan-form_floor-plan',
        controls: [
          {
            id: 'sqft',
            label: 'Base SQFT',
            type: InputType.number,
            parentClass: 'w-1/2 pr-6',
            required: true,
            settings: {
              description: 'Enter base square footage for this plan',
              titleSize: LabelSizes.extraLarge,
            },
          },
          {
            id: 'price',
            label: 'Base Price',
            heapId: 'form-plan_price',
            parentClass: 'w-1/2 pr-6',
            type: InputType.currency,
            required: true,
            settings: {
              description: 'Enter the base (starting price) for this plan',
              titleSize: LabelSizes.extraLarge,
            },
          },
          {
            id: 'beds',
            label: 'Beds',
            parentClass: 'w-1/3 pr-6',
            type: InputType.number,
            required: true,
            settings: {
              description:
                'Select the base number of bedrooms for this floor plan',
              titleSize: LabelSizes.extraLarge,
            },
          },
          {
            id: 'baths',
            label: 'Baths',
            type: InputType.number,
            parentClass: 'w-1/3 pr-6',
            required: true,
            settings: {
              description:
                'Select the base number of bathrooms for this floor plan',
              titleSize: LabelSizes.extraLarge,
              step: 0.5,
            },
          },
          {
            id: 'garage',
            label: 'Garage',
            type: InputType.number,
            parentClass: 'w-1/3 pr-6',
            required: true,
            settings: {
              description:
                'Select the base number of garage spaces for this floor plan',
              titleSize: LabelSizes.extraLarge,
              step: 0.5,
            },
          },
        ],
      },
      {
        id: PlanFormSteps.UPLOADS,
        title: 'Uploads',
        step: 4,
        valid: true,
        heapId: 'plan-form_uploads',
        controls: [
          {
            id: 'file',
            header: 'Documents Required by Developer for Plan Review',
            label: 'Drawing Set',
            type: InputType.fileUpload,
            parentClass: 'w-full pr-6',
            required: false,
            settings: {
              description:
                'For developer review: Upload construction drawing set',
              titleSize: LabelSizes.extraLarge,
              accept: '.pdf',
              files: [],
              category: 'document',
            },
          },
          {
            id: 'virtual_tour_url',
            header: 'Additional Assets to Enhance Homebuyer Map',
            label: '3D Tour',
            type: InputType.text,
            parentClass: 'w-full pr-6',
            required: false,
            settings: {
              description:
                'For homebuyer map: Insert link to Matterport or other 3D tour',
              titleSize: LabelSizes.extraLarge,
            },
          },
          {
            id: 'floor_plan_images',
            label: 'Floor Plan',
            type: InputType.fileUpload,
            parentClass: 'w-1/2 pr-6',
            required: false,
            settings: {
              description: 'For homebuyer map: Upload floor plan',
              titleSize: LabelSizes.extraLarge,
              accept: '.jpeg,.jpg,.png',
              files: [],
              allowedFilesCount: 0,
            },
          },
          {
            id: 'interior_photos',
            label: 'Interior Photos',
            type: InputType.fileUpload,
            parentClass: 'w-1/2 pr-6',
            required: false,
            settings: {
              description: 'For homebuyer map: Upload interior photos',
              titleSize: LabelSizes.extraLarge,
              accept: '.jpeg,.jpg,.png',
              files: [],
            },
          },
        ],
      },
    ]
  }

  constructor(plan?: Plan) {
    if (plan) {
      this.name = plan?.name
      this.description = plan?.description || ''
      this.available = plan?.available
      this.property_type = plan?.property_type
      this.size = plan?.size
      this.garage_loading = plan?.garage_loading
      this.beds = plan?.beds
      this.baths = +plan?.baths
      this.garage = plan?.garage
      this.sqft = plan?.sqft
      this.price = +plan?.price
      this.stories = plan.stories
      this.virtual_tour_url = plan.virtual_tour_url
      this.features = plan?.features
      this.segmentations = plan?.segmentations
      this.builder_id = plan?.builder.id
      this.phases = plan.phases
      this.percentage = plan.percentage
    }
  }
}

export { Options }
