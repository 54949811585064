var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fixed z-50 inset-0 overflow-y-auto",attrs:{"aria-labelledby":"modal-title","role":"dialog","aria-modal":"true"}},[_c('div',{staticClass:"flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"},[_c('transition',{attrs:{"name":"background"}},[(_vm.opened)?_c('div',{staticClass:"fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity",attrs:{"aria-hidden":"true"}}):_vm._e()]),_vm._v(" "),_c('span',{staticClass:"hidden sm:inline-block sm:align-middle sm:h-screen",attrs:{"aria-hidden":"true"}},[_vm._v("\n      ​\n    ")]),_vm._v(" "),_c('transition',{attrs:{"name":"modal"}},[(_vm.opened)?_c('div',{class:[
          'w-full inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-lg md:max-w-2xl',
          {
            'xl:max-w-lg': _vm.maxWidth === 'max-w-lg',
            'xl:max-w-xl': _vm.maxWidth === 'max-w-xl',
            'xl:max-w-2xl': _vm.maxWidth === 'max-w-2xl',
            'xl:max-w-3xl': _vm.maxWidth === 'max-w-3xl',
            'xl:max-w-4xl': _vm.maxWidth === 'max-w-4xl',
            'xl:max-w-5xl': _vm.maxWidth === 'max-w-5xl',
            'xl:max-w-6xl': _vm.maxWidth === 'max-w-6xl',
            'xl:max-w-7xl': _vm.maxWidth === 'max-w-7xl',
          } ]},[(_vm.loading)?_c('div',{staticClass:"w-full h-full bg-gray-900 opacity-80 absolute z-50 flex justify-center items-center"},[_c('Spinner',{attrs:{"color":"rgba(107, 114, 128, var(--tw-bg-opacity))"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"bg-white px-4 pt-5 pb-4 sm:p-6 w-auto"},[_c('div',{staticClass:"sm:flex sm:items-start"},[_vm._t("icon"),_vm._v(" "),_c('div',{staticClass:"mt-3 text-center sm:mt-0 sm:text-left w-full"},[_vm._t("header"),_vm._v(" "),_c('div',{staticClass:"mt-2 w-full"},[_vm._t("body")],2)],2)],2)]),_vm._v(" "),(!_vm.hideActionButtons)?_c('div',{staticClass:"bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"},[_c('button',{class:[
              'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm',
              _vm.style ],attrs:{"type":"button","disabled":!_vm.isFormValid},on:{"click":function($event){return _vm.onClose(true)}}},[_vm._t("actionText")],2),_vm._v(" "),_vm._t("thirdButton"),_vm._v(" "),_c('button',{staticClass:"mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.onClose(false)}}},[_vm._v("\n            "+_vm._s(_vm.cancelButtonText)+"\n          ")])],2):_vm._e()]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }