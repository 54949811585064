/**
 *
 * This function sorts elements first ascendant if numbers and then words alphabetically
 *
 * @param a
 * @param b
 * @returns
 */
export const sortNumbersAscThenWords = (
  a: string | number,
  b: string | number
) => {
  a = a.toString()
  b = b.toString()
  const isNumericA = /^\d+$/.test(a)
  const isNumericB = /^\d+$/.test(b)

  if (isNumericA && isNumericB) {
    return parseInt(a) - parseInt(b)
  } else if (isNumericA) {
    return -1
  } else if (isNumericB) {
    return 1
  } else {
    return a.localeCompare(b)
  }
}

export const formatDate = (stringDate: string): string => {
  if (!stringDate) {
    return ''
  }

  if (stringDate.includes('T')) {
    stringDate = stringDate.replace(/T.+/, '')
  }

  stringDate = stringDate.replaceAll('-', '/')
  const date: Date = new Date(stringDate)
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

export const tomorrowDate = (): Date => {
  const tomorrow = new Date()
  tomorrow.setDate(new Date().getDate() + 1)

  return tomorrow
}

export const getTimeZoneDate = (
  today: Date = new Date(),
  timeZone: string = 'US/Central'
) => {
  const date: Date = new Date(today.toLocaleString('en-US', { timeZone }))
  let removeWeekEndDays: number = 0
  if (date.getDay() === 6) {
    // Saturday
    removeWeekEndDays = 1
  } else if (date.getDay() === 0) {
    // Sunday
    removeWeekEndDays = 2
  }
  // if today is a weekend day from we set today to Friday
  // from this way is more easy to know if today is a valid day
  // for report
  date.setDate(date.getDate() - removeWeekEndDays)
  return date
}

export const isValidSubmissionDate = (
  startDate: Date,
  endDate: Date,
  isLastWeekReportSubmissionsEnabled: boolean = false
) => {
  /*
      Logic for "may submit two weeks back"

      'lastweek' will refer to the last week report submission enabled

      if lastweek is enabled, we'll look at the following logic:

      if the week chosen is in the future, they shouldn't be able to submit
      if today is monday, and the week chosen is two weeks ago, that's fine
      if today is tuesday - sunday, and the week chosen is two weeks ago (or more) that's not fine
      if today is any day, and the week chosen is last week, that's fine
    */
  const today: Date = getTimeZoneDate()
  const chosenDatesAreInTheFuture = startDate > today
  const chosenDatesAreThisWeek = today >= startDate && today <= endDate

  if (chosenDatesAreThisWeek) {
    // If the dates are this week, that's fine.
    return true
  }

  if (chosenDatesAreInTheFuture) {
    // If the dates aren't this week and in the future, not fine
    // There's more logic to go through
    return false
  }

  const isMonday = (date: Date) => date.getDay() === 1
  const subtractDays = (today: Date, days: number) => {
    const date: Date = new Date(today)
    date.setDate(date.getDate() - days)
    return date
  }

  const pastWeekDate: Date = subtractDays(today, 7)
  const twoWeeksAgoDate: Date = subtractDays(today, 14)

  const isLastWeeksReport = pastWeekDate >= startDate && pastWeekDate <= endDate
  const isTwoWeeksAgoReport =
    twoWeeksAgoDate >= startDate && twoWeeksAgoDate <= endDate

  const isMondayBefore23CT = (today: Date) =>
    isMonday(today) && today.getHours() < 23

  if (!isLastWeekReportSubmissionsEnabled) {
    return true
  }

  if (isMondayBefore23CT(today)) {
    return isLastWeeksReport || isTwoWeeksAgoReport
  }

  return false
}

export const isValidSoldDateForCustomContract =
  (isSalesSubmissionRestrictionEnabled: boolean) => (soldDate: string) => {
    if (!soldDate) return false
    const soldDateTyped: Date = new Date(soldDate)
    return isValidSoldDateForContract(
      soldDateTyped,
      isSalesSubmissionRestrictionEnabled
    ).status
  }

export const isValidSoldDateForCustomContractMessage =
  (isSalesSubmissionRestrictionEnabled: boolean): any =>
  (soldDate: string) => {
    if (!soldDate) return ''
    const soldDateTyped: Date = new Date(soldDate)
    return isValidSoldDateForContract(
      soldDateTyped,
      isSalesSubmissionRestrictionEnabled
    ).message
  }

const isValidDateForMonday = (soldDate: Date) => {
  const today: Date = getTimeZoneDate()
  const subtractDays = (today: Date, days: number) => {
    const date: Date = new Date(today)
    date.setDate(date.getDate() - days)
    return date
  }
  const twoWeeksAgoDate: Date = subtractDays(today, 14)
  return twoWeeksAgoDate <= soldDate
    ? {
        status: true,
        message: '',
      }
    : {
        status: false,
        message:
          'Sold date cannot be backdated more than 14 days in the past. Please select suitable date.',
      }
}

const isValidDateForTuesdayToSunday = (soldDate: Date) => {
  const today: Date = getTimeZoneDate()
  const subtractDays = (today: Date, days: number) => {
    const date: Date = new Date(today)
    date.setDate(date.getDate() - days)
    return date
  }
  const pastWeekDate: Date = subtractDays(today, 7)
  return pastWeekDate <= soldDate
    ? {
        status: true,
        message: '',
      }
    : {
        status: false,
        message:
          'Sold date cannot be backdated more than 7 days in the past. Please select suitable date.',
      }
}

export const isValidSoldDateForContract = (
  soldDate: Date,
  isSalesSubmissionRestrictionEnabled: boolean = false
) => {
  /*
        This will only be used for Contract Forms
        Logic for "may submit with sold date backdated two weeks back"

        if isSalesSubmissionRestrictionEnabled is true, we'll look at the following logic:

        if today is monday, and the week chosen is two weeks ago, that's fine
        if today is tuesday - sunday, and the week chosen is two weeks ago (or more) that's not fine
        if today is any day, and the week chosen is last week, that's fine
    */
  if (!isSalesSubmissionRestrictionEnabled) {
    return {
      status: true,
      message: '',
    }
  }

  const today: Date = getTimeZoneDate()
  if (soldDate > today) {
    return {
      status: true,
      message: '',
    }
  }

  const isMonday = (date: Date) => date.getDay() === 1
  const isMondayBefore23CT = (today: Date) =>
    isMonday(today) && today.getHours() < 23

  if (isMondayBefore23CT(today)) {
    return isValidDateForMonday(soldDate)
  } else {
    return isValidDateForTuesdayToSunday(soldDate)
  }
}

const endOfParentURLRegexTests = [
  {
    // .com
    regex: /\w$/,
    addition: '/#/',
  },
  {
    // .com/
    regex: /\w\/$/,
    addition: '#/',
  },
  {
    // .com/#
    regex: /\w\/\#$/,
    addition: '/',
  },
  {
    // .com/#/
    regex: /\w\/\#\/$/,
    addition: '',
  },
]

export const getParentURL = (url: string): any => {
  const parentURL = {
    origin: '',
    extraParams: '',
  }
  const includesQuery = url.includes('?')
  const indexOfQuery = url.indexOf('?')

  parentURL.origin = includesQuery ? url.slice(0, indexOfQuery) : url
  for (const test of endOfParentURLRegexTests) {
    if (test.regex.test(parentURL.origin)) {
      parentURL.origin += test.addition
      break
    }
  }

  parentURL.extraParams = includesQuery ? `&${url.slice(indexOfQuery + 1)}` : ''

  return parentURL
}
