import { Middleware } from '@nuxt/types'
import { storageAvailable } from '~/utils/localStorage'

const AuthMiddleware: Middleware = ({ store, redirect, route }) => {
  if (!storageAvailable()) {
    window.location.href = '/access_disabled/cookies'
  } else if (!store.getters['auth/isAuthenticated']) {
    if (route.path !== '/') {
      store.commit('auth/setRedirectUrl', route.path)
    }
    return redirect('/login')
  }
}

export default AuthMiddleware
