








import { Component, mixins } from 'nuxt-property-decorator'
import { mapState } from 'vuex'
import globalStyling from '~/mixins/globalStyling'
import { rentalMapStore } from '~/store'
import XofYCounter from './XofYCounter.vue'

@Component({
    components: {
        XofYCounter,
    },
    computed: {
        ...mapState('rental-map', {
            propertiesCount: 'propertiesCount',
            totalHomesFiltered: 'totalHomesFiltered',
        }),
    },
    mixins: [globalStyling],
})
export default class RentalsCounter extends mixins(globalStyling) {
    propertiesCount!: number
    totalHomesFiltered!: number
    
    get propertiesShown(): Number {
        return (
        rentalMapStore.totalHomesFiltered  || 0
        )
    }
}

