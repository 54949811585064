


























































import { Component, Emit, Prop } from 'nuxt-property-decorator'
import BaseInputComponent from './BaseInput.vue'

@Component({})
export default class TextInputTypeahead extends BaseInputComponent {
  @Prop({ default: 'text' })
  type: string

  @Prop({ default: 'text' })
  lookup: string

  @Prop({ default: [] })
  options: Array<any>

  @Prop({ default: (): string => null })
  prefix: string

  open: boolean = false
  showAlert: boolean = false

  @Emit('lookup')
  @Emit('input')
  handleInput(event: any): string {
    this.setupOpen(true)
    return event.target.value ? event.target.value : event
  }

  @Emit('input')
  select(option: any): void {
    this.setupOpen(false)
    return option.name
  }

  @Emit('input')
  @Emit('lookup')
  inputClean(): string {
    this.setupOpen(false)
    return ''
  }

  setupOpen(value: boolean): void {
    this.open = value
  }
}
