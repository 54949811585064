import { ReactiveFormConfig, ClientLibrary } from '@rxweb/reactive-forms';
import { ValidationMessages } from '~/enums/validationMessages';

export default () => {  
  ReactiveFormConfig.clientLib = ClientLibrary.Vue;
  
  ReactiveFormConfig.set({
    validationMessage: {
      required: ValidationMessages.REQUIRED,
    }
  });
}
