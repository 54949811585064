import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import Plan from '~/models/plan'
import Elevation from '~/models/elevation'
import Document from '~/models/document'
import Addition from '~/models/addition'
import { getPlan } from '~/api/plans.api'
import BaseState from '~/models/state/baseState'

@Module({
  name: 'plan',
  stateFactory: true,
  namespaced: true,
})
export default class SPlan extends VuexModule implements BaseState {
  plan: Plan
  _elevationSelected: Elevation | null
  _selectedTab: number = 0

  get elevations(): Elevation[] {
    return this.plan?.elevations
  }

  get elevationSelected(): Elevation | null {
    return this._elevationSelected
  }

  get selectedTab(): number {
    return this._selectedTab
  }

  get documents(): Document[] {
    return this.plan?.uploads
  }

  get elevation_photos(): Document[] {
    return this.plan.elevation_photos
  }

  get elevations_documents(): Document[] {
    return this.plan.elevations_documents
  }

  get additions(): Addition[] {
    return this.plan?.additions
  }

  get interior_photos(): Document[] {
    return this.plan?.interior_photos
  }

  get floor_plan_images(): Document[] {
    return this.plan?.floor_plan_images
  }

  get approval_letter(): Document {
    return this.plan.approval_letter
  }

  @Action({ rawError: true })
  async retrievePlan(slug: String): Promise<Plan> {
    const plan = await getPlan(slug)
    this.setPlan(plan)
    return plan
  }

  @Action({ rawError: true })
  setSeletectedTab(tab: number): void {
    this.setTab(tab)
  }

  @Mutation
  setPlan(plan: Plan) {
    this.plan = plan
  }

  @Mutation
  setElevation(elevation: Elevation | null) {
    this._elevationSelected = elevation
  }

  @Mutation
  setTab(tab: number) {
    this._selectedTab = tab
  }

  @Mutation
  clear(): void {
    this.plan = null
    this._elevationSelected = null
    this._selectedTab = 0
  }
}
