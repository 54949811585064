import {
  prop,
  propObject,
  required,
  minNumber,
  email,
} from '@rxweb/reactive-forms'
import FormStep from './FormStep'
import BuyerForm from './buyerForm'
import SpouseForm from './spouseForm'
import RealtorForm from './realtorForm'
import { LabelSizes } from '~/enums/labelSizes'
import { InputType } from '~/enums/inputType'

type StepsData = {
  showVariance: boolean
}
export default class BuildForm {
  @minNumber({ value: 1, message: 'Price is a required field' })
  @required({ message: 'Price is a required field' })
  price: number

  @required() plan_price: number
  @required() elevation_id: number
  @required() sold_on: string
  @prop() children_ages: string[] = []
  @required() close_estimated_on: string
  @propObject() buyer: BuyerForm
  @propObject() spouse: SpouseForm
  @propObject() realtor: RealtorForm
  @prop() variance: boolean

  @prop() variance_name: string
  @prop() variance_phone: string
  @email({ message: 'Provide a valid email' })
  variance_email: string

  @prop() variance_reason: string

  constructor() {
    this.buyer = new BuyerForm()
    this.spouse = new SpouseForm()
    this.realtor = new RealtorForm()
    this.price = 0
    this.plan_price = 0
  }

  getSteps(stepsData?: StepsData): FormStep[] {
    return [
      {
        title: 'Overview',
        step: 1,
        valid: false,
        controls: [
          {
            id: 'sold_on',
            label: 'Sold Date',
            parentClass: 'w-1/2 pr-6',
            type: InputType.date,
            required: true,
            settings: {
              description: 'Date of sale with the homebuyer',
              titleSize: LabelSizes.extraLarge,
            },
          },
          {
            id: 'close_estimated_on',
            label: 'Estimated Close Date',
            parentClass: 'w-1/2 pr-6',
            type: InputType.date,
            required: true,
            settings: {
              description: 'Date the sale is expected to close',
              titleSize: LabelSizes.extraLarge,
            },
          },
        ],
      },
      {
        title: 'Plan/Elevation',
        step: 2,
        valid: false,
        controls: [
          {
            id: 'variance',
            label: 'Variance Request',
            type: InputType.toggle,
            parentClass: 'w-full flex justify-end absolute top-1 right-4',
            required: false,
            settings: {
              titleSize: LabelSizes.small,
              prefix: 'No',
              suffix: 'Yes',
              textSize: LabelSizes.small,
              visible: stepsData?.showVariance,
            },
          },
          {
            id: 'elevation_id',
            label: 'Plan Name',
            type: InputType.elevations,
            parentClass: 'w-full',
            required: true,
            settings: {
              options: [],
              description: 'Select elevation to construct.',
              titleSize: LabelSizes.extraLarge,
            },
          },
        ],
      },
      {
        title: 'Buyer(s) Info',
        step: 3,
        valid: false,
        controls: [
          {
            id: 'buyer',
            type: null,
            nested: true,
            label: '',
            parentClass: 'w-full',
            controls: this.buyer.controls,
            required: true,
            settings: {
              description:
                'Enter information about the buyer to evaluate marketing and assist HOA planning',
            },
          },
          {
            id: 'spouse',
            type: null,
            nested: true,
            label: 'Secondary Buyer',
            parentClass: 'w-full',
            controls: this.spouse.controls,
            required: false,
            settings: {
              description:
                'Enter information about the secondary buyer (if applicable)',
            },
          },
        ],
      },
      {
        title: 'Pricing',
        step: 4,
        valid: false,
        controls: [
          {
            id: 'plan_price',
            label: 'Elevation Price',
            parentClass: 'w-1/2 pr-6 mt-6',
            type: InputType.currency,
            required: false,
            settings: {
              disabled: true,
              description: 'XO base price of the selected elevation',
              titleSize: LabelSizes.extraLarge,
            },
          },
          {
            id: 'price',
            label: 'Sales Price',
            heapId: 'sales-form_price',
            parentClass: 'w-1/2 pr-6 mt-6',
            type: InputType.currency,
            required: true,
            settings: {
              description:
                "Enter new sales price (if different than elevation's base price)",
              titleSize: LabelSizes.extraLarge,
            },
          },
        ],
      },
      {
        title: 'Additional Info',
        step: 5,
        valid: false,
        controls: [
          {
            id: 'variance_name',
            label: 'Variance Name',
            type: InputType.text,
            parentClass: 'w-full pb-2',
            required: false,
            settings: {
              validators: [],
              description:
                'Enter the full name for the review team to understand who to contact for your request',
              preview: true,
              disabled: false,
              visible: false,
            },
          },
          {
            id: 'variance_phone',
            label: 'Variance Phone',
            type: InputType.text,
            parentClass: 'w-full pb-2',
            required: false,
            settings: {
              validators: [],
              description:
                'Enter the phone number for the review team to understand how to contact for your request',
              preview: true,
              disabled: false,
              visible: false,
              isPhoneNumber: true,
            },
          },
          {
            id: 'variance_email',
            label: 'Variance Email',
            type: InputType.text,
            parentClass: 'w-full pb-2',
            required: false,
            settings: {
              validators: [],
              description:
                'Enter the email for the review team to understand how to contact for your request',
              preview: true,
              disabled: false,
              visible: false,
            },
          },
          {
            id: 'variance_reason',
            label: 'Variance Explanation',
            type: InputType.text,
            parentClass: 'w-full pb-2',
            required: false,
            settings: {
              validators: [],
              description:
                'Enter the explanation for the review team to understand your request',
              preview: true,
              disabled: false,
              isTextArea: true,
              visible: false,
            },
          },
          {
            id: 'realtor',
            type: null,
            nested: true,
            label: 'Realtor Information',
            parentClass: 'w-full',
            controls: this.realtor.controls,
            required: false,
            settings: {
              description:
                'Enter information about the realtor involved in the sale (if applicable)',
            },
          },
          {
            id: 'children_ages',
            type: InputType.children,
            nested: null,
            label: 'Children',
            required: false,
            parentClass: 'w-full',
            settings: {
              titleSize: LabelSizes.extraLarge,
              description:
                'Enter number of children in the home (if applicable) to assist HOA and school needs planning',
            },
          },
        ],
      },
    ]
  }
}
