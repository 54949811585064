






























































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import Material from '~/models/material'

@Component({
  components: {},
})
export default class MaterialCard extends Vue {
  @Prop() material: Material
  @Prop({ default: false }) active: boolean
  @Prop({ default: false }) isVariance: boolean

  get materialColor(): String {
    if (!this.material) {
      return ''
    }

    const hexColor = this.material.hex
    if (!hexColor) {
      return 'background-color: #FFFFFF'
    }

    if (hexColor.includes('#')) {
      return `background-color: ${hexColor}`
    }

    return `background-color: #${hexColor}`
  }
}
