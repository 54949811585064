































import { Component, Prop, Vue} from 'nuxt-property-decorator'

@Component({})
export default class ListRow extends Vue {
  @Prop({ type: Boolean, default: false })
  opened: boolean = false

  @Prop({ default: false })
  expansible: Boolean

  @Prop({ type: Boolean, default: true })
  sticky: boolean

  isOpen: boolean = this.opened

  @Prop({
    default: (): any => null,
  })
  customStyle: any

  @Prop({
    default: '',
  })
  customClasses: string

  @Prop({default: false})
  highLight: boolean

  get isHighLighted (): boolean {
    return this.highLight
  }
}
