var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show && !_vm.hasOptions)?_c('nuxt-link',{key:_vm.label,staticClass:"flex items-center duration-200 py-4 px-6 border-l-4",class:[
    _vm.$route.path === _vm.path
      ? 'bg-gray-600 bg-opacity-25 text-gray-100 border-blue-500'
      : 'border-gray-900 text-gray-500 hover:bg-gray-600 hover:bg-opacity-25 hover:text-gray-100' ],attrs:{"to":_vm.path}},[_c('fa',{attrs:{"icon":['axis', _vm.icon]}}),_vm._v(" "),_c('span',{staticClass:"mx-4 text-base"},[_vm._v(_vm._s(_vm.label))])],1):(_vm.show && _vm.hasOptions)?_c('section',[_c('div',{staticClass:"flex items-center duration-200 py-4 px-6 border-l-4",class:[
      _vm.anyPathActive()
        ? 'bg-gray-600 bg-opacity-25 text-gray-100 border-blue-800'
        : 'border-gray-900 text-gray-500 hover:bg-gray-600 hover:bg-opacity-25 hover:text-gray-100' ],on:{"click":_vm.toggleOptions}},[_c('fa',{attrs:{"icon":['axis', _vm.icon]}}),_vm._v(" "),_c('span',{staticClass:"mx-4 text-base"},[_vm._v("\n      "+_vm._s(_vm.label)+"\n      "),_c('fa',{attrs:{"icon":['axis', _vm.isOptionsVisible ? 'arrowDropUp' : 'arrowDropDown']}})],1)],1),_vm._v(" "),(_vm.isOptionsVisible)?_c('div',{class:[
      'transition-all duration-300 ease-in-out overflow-hidden',
      _vm.isOptionsVisible ? 'max-h-40 opacity-100' : 'max-h-0 opacity-0' ]},_vm._l((_vm.nestedOptions),function(option){return _c('nuxt-link',{key:option.id,staticClass:"flex items-center duration-200 py-4 pr-6 pl-10 border-l-4",class:[
        _vm.$route.path === option.path
          ? 'bg-gray-600 bg-opacity-25 text-gray-100 border-blue-500'
          : 'border-gray-900 text-gray-500 hover:bg-gray-600 hover:bg-opacity-25 hover:text-gray-100' ],attrs:{"to":option.path}},[_c('fa',{attrs:{"icon":['axis', option.icon]}}),_vm._v(" "),_c('span',{staticClass:"mx-4 text-base"},[_vm._v("\n        "+_vm._s(option.name)+"\n      ")])],1)}),1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }