import Material from '~/models/material'
import PagedResponse from '~/models/pagedResponse'
import { $axios } from '~/utils/api'

const END_POINT = '/materials'

const getMaterials = (
  page: number = 1,
  planId: number,
  elevationId: number,
  lotId: number,
  styleId: number,
  category: string,
  am: boolean = false,
  materials_controller_id?: string,
  variance: boolean = false
): Promise<PagedResponse<Material>> => {
  let url = `${END_POINT}/list_v2?page=${page}&plan_id=${planId}&elevation_id=${elevationId}&lot_id=${lotId}&style_id=${styleId}&category=${category}&am=${am}&variance=${variance}`
  if (materials_controller_id) {
    url = `${url}&materials_controller=${materials_controller_id}`
  }
  return $axios.$get(url)
}

const searchMaterials = (name: string, filters?: any) => {
  let url: string = `${END_POINT}?name=${name}`
  if (filters) {
    const queryParams: string = Object.keys(filters)
      .map((filter_name: string) => `${filter_name}=${filters[filter_name]}`)
      .join('&')
    url = `${url}&${queryParams}`
  }
  return $axios.$get(url)
}

const getMaterialsWithoutAM = (
  planId: number,
  elevationId: number,
  lotId: number,
  styleId: number
): Promise<Material[]> =>
  $axios.$get(
    `${END_POINT}?plan_id=${planId}&elevation_id=${elevationId}&lot_id=${lotId}&style_id=${styleId}${`&type=secondary`}`
  )

const getMaterial = (id: number): Promise<Material> =>
  $axios.$get(`${END_POINT}/${id}`)

const getMatrixMaterials = (
  primayMaterialId: number,
  lotId: number,
  page: number = 1,
  variance: boolean = false,
  name?: string
): Promise<PagedResponse<Material>> => {
  let url: string = `${END_POINT}/list_matrix?material_id=${primayMaterialId}&lot_id=${lotId}&page=${page}&variance=${variance}`
  if (name) {
    url = `${url}&name=${name}`
  }
  return $axios.$get(url)
}

export {
  getMaterials,
  getMaterialsWithoutAM,
  getMaterial,
  getMatrixMaterials,
  searchMaterials,
}
