import { prop, required } from '@rxweb/reactive-forms'
import { InputType } from '~/enums/inputType'
import { LabelSizes } from '~/enums/labelSizes'

export default class CloseHomeForm {
  @prop() final_price: number = 0

  @prop()
  @required({ message: 'no start date selected' })
  closed_on: string

  @prop()
  additional_price: number

  @prop()
  premium_price: number

  constructor(finalPrice: number) {
    this.final_price = finalPrice
  }

  get controls(): any[] {
    return [
      {
        id: 'final_price',
        label: 'Closing Price',
        type: InputType.currency,
        required: false,
        settings: {
          description: 'Final price of the home',
          titleSize: LabelSizes.small,
        },
      },
      {
        id: 'premium_price',
        label: 'Builder Lot Premium',
        type: InputType.currency,
        required: false,
        settings: {
          description:
            'Price charged to homebuyer for any premiums applied to this lot',
          titleSize: LabelSizes.small,
        },
      },
      {
        id: 'additional_price',
        label: 'Additional Home Options',
        type: InputType.currency,
        required: false,
        settings: {
          description:
            'Additional price charged to homebuyer for any increased enhancements to the home',
          titleSize: LabelSizes.small,
        },
      },
      {
        id: 'closed_on',
        label: 'Closed On',
        type: InputType.date,
        required: true,
        settings: {
          description: 'Actual date the sale closed and buyer received title',
          titleSize: LabelSizes.small,
        },
      },
    ]
  }
}
