import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import BaseState from '~/models/state/baseState'
import { FavouriteRental, FavouriteRentalsCollection } from '~/models'
import { getFavourites } from '~/utils/localStorageRentals'
import { storageAvailable } from '~/utils/localStorage'

@Module({
  name: 'rental-favorites',
  stateFactory: true,
  namespaced: true,
})
export default class RentalFavorites extends VuexModule implements BaseState {
  favs: FavouriteRentalsCollection = {} as FavouriteRentalsCollection

  get favCount(): number {
    // lengths of other favourites types to be added to this calculation in the future
    if (this.favs) {
      return this.favs?.rentals?.length || 0
    }
    return 0
  }

  //, item_type: string
  @Action({ rawError: true })
  addFavorite(item_obj: FavouriteRental): boolean {
    if (item_obj.item_type === 'rental') {
      const rental: FavouriteRental = item_obj as FavouriteRental
      const state: any = this.context.state as any
      if (
        state.favs.rentals.find(
          (elem: FavouriteRental) => elem.id === rental.id
        ) === undefined
      ) {
        this.context.commit('add', item_obj)
        return true
      }
    }
    return false
  }

  @Action({ rawError: true })
  removeFavorite(payload: { item_id: string; item_type: string }): boolean {
    const { item_id, item_type } = payload
    const state: any = this.context.state as any
    if (item_type === 'rental') {
      if (
        state.favs.rentals.find((elem: FavouriteRental) => elem.id === item_id)
      ) {
        this.context.commit('remove', {
          item_id,
          item_type,
        })
      }
    }
    return true
  }

  @Mutation
  add(item_obj: { item_type: string }): void {
    if (item_obj.item_type === 'rental') {
      this.favs.rentals.push(item_obj as FavouriteRental)
    }
  }

  @Mutation
  remove(payload: any): void {
    const { item_id, item_type } = payload
    if (item_type === 'rental') {
      const index = this.favs.rentals.findIndex((elem) => elem.id === item_id)

      if (index >= 0) {
        this.favs.rentals.splice(index, 1)
      }
    }
  }

  @Mutation
  loadFavorites(): void {
    try {
      if (storageAvailable()) {
        const favCol = getFavourites()
        if (favCol !== null) {
          this.favs = favCol
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  @Mutation
  clear(): void {
    this.favs = null
  }
}
