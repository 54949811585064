




























































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Navigation } from '~/models'

@Component({})
export default class NavAction extends Vue {
  @Prop({ default: '' }) path: string
  @Prop({ default: '' }) icon: string
  @Prop({ default: '' }) label: string
  @Prop({ default: true }) show: boolean

  @Prop({ default: false }) hasOptions: boolean
  @Prop({ default: (): Array<Navigation> => [] })
  nestedOptions: Array<Navigation>

  isOptionsVisible: boolean = false

  toggleOptions(): void {
    this.isOptionsVisible = !this.isOptionsVisible
  }

  anyPathActive(): boolean {
    return this.nestedOptions.some((option) => this.$route.path === option.path)
  }
}
