import { prop, required, maxNumber, minNumber } from '@rxweb/reactive-forms'
import { InputType } from '~/enums/inputType'
import { BuilderContract } from '~/models'
import { filtersStore } from '~/store'

export default class BuilderContractForm {
  @prop()
  id: number

  @required({ message: 'Name is a required field' })
  name: string

  @required({ message: 'Builder is a required field' })
  builder: number

  @required({ message: 'Phase is a required field' })
  phase: string

  @prop()
  neighborhood: string

  @prop()
  lot_sizes: number[]

  @required({ message: 'Signed date is a required field' })
  signed_date: string

  @prop()
  sub_completion_date: string

  @prop() description: string
  @prop() status: string

  @prop() initial_earnest_money: number
  @prop() initial_earnest_money_date: string
  @prop() additional_earnest_money: number
  @prop() additional_earnest_money_date: string
  @prop() amenity_fee: number
  @prop() marketing_fee: number
  @prop() base_price_of_lot: number
  @maxNumber({
    value: 100,
    message: 'Escalation max value is 100',
  })
  @minNumber({
    value: 0,
    message: 'Escalation min value is 0',
  })
  @prop()
  escalation: number

  @minNumber({
    value: 0,
    message: 'Inspection period min value is 1',
  })
  @prop()
  inspection_period: number

  get controls(): any[] {
    return [
      {
        id: 'name',
        label: 'Name',
        type: InputType.text,
        required: true,
        settings: {},
      },
      {
        id: 'builder',
        label: 'Builder',
        type: InputType.select,
        required: true,
        settings: {
          options: filtersStore.builders,
        },
      },
      {
        id: 'phase',
        label: 'Phase',
        type: InputType.select,
        required: true,
        settings: {
          options: filtersStore.constructionPhases,
        },
      },
      {
        id: 'neighborhood',
        label: 'Neighborhood',
        type: InputType.select,
        settings: {
          options: filtersStore.lotNeighborhoods,
        },
      },
      {
        id: 'signed_date',
        label: 'Signed Date',
        type: InputType.date,
        required: true,
        settings: {},
      },
      {
        id: 'sub_completion_date',
        label: 'Sub. Completion Date',
        type: InputType.date,
        settings: {},
      },
      {
        id: 'description',
        label: 'Description',
        type: InputType.text,
        settings: {},
      },
      {
        id: 'status',
        label: 'Status',
        type: InputType.select,
        settings: {
          options: filtersStore.builderContractsStatus,
        },
      },
      {
        id: 'initial_earnest_money',
        label: 'Initial Earnest Money',
        type: InputType.currency,
        settings: {},
      },
      {
        id: 'initial_earnest_money_date',
        label: 'Initial Earnest Money Date',
        type: InputType.date,
        settings: {},
      },
      {
        id: 'additional_earnest_money',
        label: 'Additional Earnest Money',
        type: InputType.currency,
        settings: {},
      },
      {
        id: 'additional_earnest_money_date',
        label: 'Additional Earnest Money Date',
        type: InputType.date,
        settings: {},
      },
      {
        id: 'amenity_fee',
        label: 'Amenity Fee',
        type: InputType.currency,
        settings: {},
      },
      {
        id: 'marketing_fee',
        label: 'Marketing Fee',
        type: InputType.currency,
        settings: {},
      },
      {
        id: 'base_price_of_lot',
        label: 'Base Price of Lot',
        type: InputType.currency,
        settings: {},
      },
      {
        id: 'escalation',
        label: 'Escalation',
        type: InputType.number,
        settings: {},
      },
      {
        id: 'inspection_period',
        label: 'Inspection Period (Days)',
        type: InputType.number,
        settings: {},
      },
      {
        id: 'lot_sizes',
        label: 'Lot Sizes',
        type: InputType.select,
        settings: {
          options: filtersStore.lotSizes?.map((lotSize) => {
            return { ...lotSize, enabled: true }
          }),
          isMultiSelect: true,
          defaultValues: this.lot_sizes ?? [],
        },
      },
    ]
  }

  populateFields(builderContract: BuilderContract): void {
    if (!builderContract) {
      return
    }

    this.id = builderContract.id
    this.name = builderContract.name
    this.phase = builderContract.phase
    this.neighborhood = builderContract.neighborhood
    this.signed_date = builderContract.signed_date
    this.sub_completion_date = builderContract.sub_completion_date
    this.description = builderContract.description
    this.status = builderContract.status
    this.lot_sizes = builderContract.lot_sizes
    this.builder = builderContract.builder.id

    this.initial_earnest_money = builderContract.initial_earnest_money
    this.initial_earnest_money_date = builderContract.initial_earnest_money_date
    this.additional_earnest_money = builderContract.additional_earnest_money
    this.additional_earnest_money_date =
      builderContract.additional_earnest_money_date
    this.amenity_fee = builderContract.amenity_fee
    this.marketing_fee = builderContract.marketing_fee
    this.base_price_of_lot = builderContract.base_price_of_lot
    this.escalation = builderContract.escalation
    this.inspection_period = builderContract.inspection_period
  }
}
