














































import { Component, Vue, Emit, Prop } from 'nuxt-property-decorator'
import TextInput from '../shared/inputs/TextInput.vue'
import MapDropdownFilter from '~/components/sales_map_v3/MapDropdownFilter.vue'

@Component({
  components: {
    MapDropdownFilter,
    TextInput,
  },
})
export default class PriceFilter extends Vue {
  @Prop({ default: '' }) title: String
  @Prop({ default: '' }) openedMenu: String
  @Prop({ default: (): any => {} }) priceMin: any
  @Prop({ default: (): any => {} }) priceMax: any
  @Prop({ default: 100000 }) minimumAvailableValue: number
  @Prop({ default: 2000000 }) maximumAvailableValue: number

  inputMaskRe: RegExp = /(\d\d\d)(?=[^$])/g
  replacement: string = '$1,'

  applyMoneyMask(value: number) {
    return value
      .toString()
      .split('')
      .reverse()
      .join('')
      .replace(this.inputMaskRe, this.replacement)
      .split('')
      .reverse()
      .join('')
  }

  get step(): number {
    const delta = this.maximumAvailableValue - this.minimumAvailableValue
    if (delta > 500000) {
      return 25000
    } else if (delta > 100000 && delta <= 500000) {
      return 20000
    } else if (delta > 10000 && delta <= 100000) {
      return 5000
    } else {
      return 1000
    }
  }

  get priceMinValue() {
    return this.priceMin ? this.priceMin.value : this.minimumAvailableValue
  }

  get maskedPriceMinValue() {
    return this.applyMoneyMask(this.priceMinValue)
  }

  get priceMaxValue() {
    return this.priceMax ? this.priceMax.value : this.maximumAvailableValue
  }

  get maskedPriceMaxValue() {
    return this.applyMoneyMask(this.priceMaxValue)
  }

  @Emit('updatePriceMaxFilter')
  updatePriceMaxFilterByRange(e: any) {
    const targetValue = Number(e.target.value)
    if (targetValue <= this.priceMinValue) {
      return this.priceMinValue + this.step
    }
    return targetValue
  }

  @Emit('updatePriceMinFilter')
  updatePriceMinFilterByRange(e: any) {
    const targetValue = Number(e.target.value)
    if (targetValue >= this.priceMaxValue) {
      return this.priceMaxValue - this.step
    }
    return targetValue
  }

  @Emit('updatePriceMinFilter')
  updatePriceMinFilter(e: any) {
    return Number(e.target.value.replace(',', ''))
  }

  @Emit('updatePriceMaxFilter')
  updatePriceMaxFilter(e: any) {
    return Number(e.target.value.replace(',', ''))
  }

  handleChangeMenu(e: any) {
    e ? this.$emit('changeMenu', this.title) : this.$emit('changeMenu', '')
  }
}
