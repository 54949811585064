import { required, notEmpty } from '@rxweb/reactive-forms'

export default class PasswordForm {
  @required()
  @notEmpty()
  password: string

  @required()
  @notEmpty()
  passwordConfirmation: string
}
