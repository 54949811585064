import { prop } from '@rxweb/reactive-forms'
import { FilterFields } from '../filters'
import IFormFilter from './IFormFilter'
import { InputType } from '~/enums/inputType'
import { FilterTypes } from '~/enums/filterTypes'
import FormControl from '~/models/forms/FormControl'
import { getMiscTypes } from '~/api/projects.api'

export default class MiscSubmittalFilters implements IFormFilter {
  @prop() type: FilterFields[]
  @prop() phase: FilterFields[]
  @prop() builders: FilterFields[]
  @prop() misc_available: FilterFields[]
  @prop() order_by: FilterFields[]

  getControls(): FormControl[] {
    return [
      {
        id: FilterTypes.ORDER_BY,
        label: 'Sorting',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          singleSelectionNoDisable: true,
          options: [],
        },
      },
      {
        id: 'misc_available',
        label: 'Availability',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          singleSelectionNoDisable: true,
          options: [
            {
              id: 'true',
              value: 'Available',
            },
            {
              value: 'Unabailable',
              id: 'false',
            },
          ],
        },
      },
      {
        id: FilterTypes.MISC_SUBMITTAL_TYPE,
        label: 'Types',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          options: [],
        },
      },
      {
        id: FilterTypes.BUILDERS,
        label: 'Builders',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          options: [],
        },
      },
      {
        id: FilterTypes.PHASE,
        label: 'Phases',
        type: InputType.checkboxGroup,
        required: false,
        settings: {
          options: [],
        },
      },
    ]
  }

  getQueryParamFor(name: string, isMany: boolean = false) {
    const fixed: { [key: string]: string } = {
      builders: 'builder',
      misc_available: 'available',
    }

    const fixed_name: string = fixed[name] || name
    return isMany ? `${fixed_name}__in` : fixed_name
  }

  refresh(): IFormFilter {
    return new MiscSubmittalFilters()
  }

  populateFields(_miscSubmittalFilter: MiscSubmittalFilters): void {}

  async getData(projectId: number): Promise<Array<FilterFields>> {
    try {
      return await getMiscTypes(projectId)
    } catch (error) {
      console.log('Error', error)
    }
  }
}
