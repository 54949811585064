





import { Component, Vue } from 'nuxt-property-decorator'
import Spinner from './Spinner.vue'

@Component({
  components: {
    Spinner,
  },
})
export default class BottomLoader extends Vue {}
