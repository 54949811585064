










import { Component, Prop, Emit } from 'nuxt-property-decorator'
import Toggle from '../buttons/Toggle.vue'
import BaseInputComponent from './BaseInput.vue'
import TitleInput from './TitleInput.vue'

@Component({
  components: {
    Toggle,
    TitleInput,
  },
})
export default class ToggleInput extends BaseInputComponent {
  @Prop({ default: null })
  prefix: string

  @Prop({ default: null })
  suffix: string

  @Prop({ default: false })
  required: boolean

  @Prop({ default: '' })
  textSize: string

  mounted() {}

  @Emit('validateField')
  handleBlur(): any {}
}
