











import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component({})
export default class MapSearchButton extends Vue {
  @Prop({ default: false }) searchActive: boolean
}
