var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-0 z-50",class:{
    absolute: _vm.isAbsolutePosition,
  }},[_c('div',{staticClass:"w-full mt-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchTerm),expression:"searchTerm"}],staticClass:"w-full px-2 py-1 border border-gray-200 rounded-md",attrs:{"autocomplete":"off","type":"search","name":"search","placeholder":"Search for a Lot","aria-label":"Search through the XO"},domProps:{"value":(_vm.searchTerm)},on:{"keyup":_vm.search,"input":function($event){if($event.target.composing){ return; }_vm.searchTerm=$event.target.value}}})]),_vm._v(" "),(_vm.isLoading)?_c('div',{class:{
      absolute: !_vm.isAbsolutePosition,
    }},[_c('div',{staticClass:"container loading"},[_c('Spinner')],1)]):_vm._e(),_vm._v(" "),(_vm.items.length > 0 && _vm.searchTerm)?_c('div',[(!_vm.isLoading && _vm.items.length <= 0)?_c('div',{class:{
        absolute: !_vm.isAbsolutePosition,
      }},[_vm._m(0)]):_c('div',{staticClass:"container result",class:{
        absolute: !_vm.isAbsolutePosition,
      }},_vm._l((_vm.formattedItems),function(item){return _c('a',{key:item.id,staticClass:"border-solid py-4 px-2 flex items-center cursor-pointer hover:bg-blue-50",on:{"click":function($event){_vm.redirectOnClick ? _vm.redirect(item.link) : _vm.returnItemSelected(item)}}},[(item.img)?_c('div',{staticClass:"w-10 mr-2"},[_c('img',{staticClass:"rounded-md",attrs:{"alt":item.title,"src":item.img}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex flex-col leading-4"},[_c('span',{staticClass:"text-black"},[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('span',{staticClass:"text-blueGray-500 text-sm"},[_vm._v("\n            "+_vm._s(item.description)+"\n          ")])])])}),0)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container notFound"},[_c('span',{staticClass:"font-bold text-gray-600"},[_vm._v(" No Matching Results ")]),_vm._v(" "),_c('span',{staticClass:"text-red-600 px-4 text-sm"},[_vm._v("\n          Sorry, we couldn't find any matching records. Please try different\n          search terms.\n        ")])])}]

export { render, staticRenderFns }