import { Realtor } from '~/models'
import { $axios } from '~/utils/api'

const USERS_ENDPOINT = '/realtor'

const getRealtor = (id: number): Promise<Realtor> =>
  $axios.$get(`${USERS_ENDPOINT}/${id}`)

const createRealtor = (formData: any): Promise<Realtor> =>
  $axios.$post(`${USERS_ENDPOINT}`, formData)

const editRealtor = (id: number, formData: any): Promise<Realtor> =>
  $axios.$put(`${USERS_ENDPOINT}/${id}`, formData)

export { createRealtor, getRealtor, editRealtor }
