



import { Component, Vue, Prop, Emit } from 'nuxt-property-decorator'

@Component({})
export default class BaseInputComponent extends Vue {
  @Prop()
  id: string

  @Prop({ default: '' })
  heapId: string

  @Prop()
  label: string

  @Prop({ default: false })
  disabled: boolean

  @Prop()
  assistiveText: string

  @Prop()
  autocomplete: string

  @Prop()
  error: boolean

  @Prop()
  name: string

  @Prop()
  placeholder: string

  @Prop()
  value: any

  @Prop()
  maxlength: number

  @Prop()
  description: string

  @Prop({ default: '' })
  titleSize: string

  @Prop()
  subTitleSize: string

  @Prop({ default: false })
  required: boolean

  @Prop({ default: '' })
  errorText: string

  @Prop({ default: 1 })
  step: number

  @Emit('blur')
  handleBlur(event: InputEvent): InputEvent {
    return event
  }

  @Emit('change')
  handleChange(event: InputEvent): InputEvent {
    return event
  }

  @Emit('click')
  handleClick(event: InputEvent): InputEvent {
    return event
  }

  @Emit('onError')
  handleError(event: InputEvent): InputEvent {
    return event
  }

  @Emit('focus')
  handleFocus(event: InputEvent): InputEvent {
    return event
  }

  @Emit('input')
  handleInput(event: any): string | number | boolean | any {
    return event
  }

  @Emit('keydown')
  handleKeydown(event: InputEvent): InputEvent {
    return event
  }

  @Emit('keypress')
  handleKeypress(event: InputEvent): InputEvent {
    return event
  }

  @Emit('keyup')
  handleKeyup(event: InputEvent): InputEvent {
    return event
  }

  @Emit('submit')
  handleSubmit(event: InputEvent): InputEvent {
    return event
  }
}
