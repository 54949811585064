import { Middleware } from '@nuxt/types'
import { RoleTypes } from '~/enums/users'
import { Navigation } from '~/models'
import {
  navigation,
  ACCESS_DISABLED_ROUTE,
  showNavOption,
  NavigationSections,
} from '~/utils/navigationAuth'

const AuthorizedMiddleware: Middleware = ({ store, redirect, route }) => {
  const user = store.getters['user/user']
  // prevents infinite loop redirection
  if (!route || !route.path || route.path === ACCESS_DISABLED_ROUTE) {
    return
  }

  const permissionRoutes: Array<Navigation> = navigation
  const urlSplited: Array<string> = route.path.split('/')
  const baseRoute = urlSplited.length > 1 ? `/${urlSplited[1]}` : '/'
  const permissionRoute = permissionRoutes.find((pr) => {
    if (pr.path) {
      return pr.path.includes(baseRoute)
    } else if (pr.options) {
      return pr.options.some((o) => o.path.includes(baseRoute))
    }
    return false
  })

  const project = store.getters['user/project']
  if (!project || !project.config) {
    return redirect(ACCESS_DISABLED_ROUTE)
  }

  if (
    permissionRoute.id === NavigationSections.ADMIN_SETTINGS &&
    !project.config.enable_admin_settings_from_ui
  ) {
    return redirect(ACCESS_DISABLED_ROUTE)
  }

  if (
    permissionRoute.id === NavigationSections.ADMIN_SETTINGS &&
    user.role !== RoleTypes.DEVELOPER
  ) {
    return redirect(ACCESS_DISABLED_ROUTE)
  }

  if (!permissionRoute.enabledConfigKey) {
    return
  }

  if (
    user.is_admin &&
    permissionRoute &&
    permissionRoute.allowedFor === RoleTypes.ADMIN
  ) {
    return
  }

  const isDashboardEnabled =
    project.config[permissionRoute?.enabledConfigKey] === true

  if (isDashboardEnabled) {
    return
  }

  const navRoutesAccesses = permissionRoutes.map((p) => {
    return {
      name: p.name,
      path: p.path,
      canAccess: showNavOption(
        p,
        project,
        user.isDeveloper,
        user,
        user.isGuest,
        user.isAdmin,
        user.isBuilder
      ),
    }
  })

  for (const route of navRoutesAccesses) {
    if (route.canAccess) redirect(route.path)
  }
}

export default AuthorizedMiddleware
