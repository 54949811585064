

































import { Component, Vue, Emit, Prop } from 'nuxt-property-decorator'
import TextInput from '../shared/inputs/TextInput.vue'
import Input from '../shared/inputs/Input.vue'
import MapDropdownFilter from '~/components/sales_map_v3/MapDropdownFilter.vue'

@Component({
  components: {
    MapDropdownFilter,
    TextInput,
    Input,
  },
})
export default class AvailableDateFilter extends Vue {
  @Prop({ default: '' }) title: String
  @Prop({ default: '' }) openedMenu: String
  @Prop({ default: (): any => {} }) moveInDate: any
  @Prop({ default: true }) mustBeFuture: boolean

  isValidDate: boolean = true

  get moveInDateValue() {
    return this.moveInDate?.value
  }

  get errorMessage() {
    return this.mustBeFuture
      ? 'The date should be in the future and in the MM/DD/YYYY format.'
      : 'The date should be in the MM/DD/YYYY format.'
  }

  validateDate(event: any) {
    const now = new Date()
    const dateToUpdateTo = event.target.value
      ? new Date(event.target.value)
      : null

    if (!dateToUpdateTo) return true

    this.isValidDate = this.mustBeFuture && dateToUpdateTo > now
  }

  addDaysShortcut(days: number) {
    const today = new Date()
    let futureDate = today.setDate(today.getDate() + 0)
    if (days) {
      futureDate = today.setDate(today.getDate() + days)
    }
    this.updateMoveInDateFilter(futureDate)
  }

  @Emit('updateMoveInDateFilter')
  updateMoveInDateFilter(e: any) {
    const oneDayMilliseconds = 86400000
    const now = new Date()
    const dateToUpdateTo = new Date(e)
    const dateToUse =
      this.mustBeFuture && dateToUpdateTo < now
        ? now.getTime() - dateToUpdateTo.getTime() < oneDayMilliseconds
          ? e
          : null
        : e
    if (!dateToUse) {
      return null
    }
    return this.$dateFns.format(new Date(dateToUse), 'MM/dd/yyyy')
  }

  handleChangeMenu(event: any) {
    const title = event ? this.title : ''
    this.$emit('changeMenu', title)
  }

  intervalDays(): Array<number> {
    return [30, 60, 90]
  }
}
