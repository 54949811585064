
















import { Vue, Component, Prop } from 'nuxt-property-decorator'

@Component({})
export default class ButtonGroupV3 extends Vue {
  @Prop({ default: (): any[] => [] }) options: any[]
  @Prop({ default: (): any => {} }) selectedOption: any

  isActive(value: number): boolean {
    return this.selectedOption?.value === value
  }
}
