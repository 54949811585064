import {
  prop,
  numeric,
  NumericValueType,
  pattern,
  email,
} from '@rxweb/reactive-forms'
import Buyer from '../buyer'
import { InputType } from '~/enums/inputType'

export default class SpouseForm {
  @prop() employer_name: string

  @prop()
  @numeric({
    acceptValue: NumericValueType.PositiveNumber,
    allowDecimal: false,
    message: 'Zipcode must be greater than 0',
  })
  @pattern({
    expression: { onlyAlpha: /^\d{5}?$/ },
    message: 'Provide a valid zipcode',
  })
  employer_zipcode: string

  @prop() first_name: string
  @prop() last_name: string
  @email({ message: 'Provide a valid email' }) email: string

  @pattern({
    expression: { onlyAlpha: /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/ },
    message: 'Provide a valid phone number',
  })
  phone: string

  @prop() age: string

  get controls(): any[] {
    return [
      {
        id: 'first_name',
        label: 'First Name',
        type: InputType.text,
        settings: {},
      },
      {
        id: 'last_name',
        label: 'Last Name',
        type: InputType.text,
        settings: {},
      },
      {
        id: 'email',
        label: 'Email Address',
        type: InputType.text,
        settings: {},
      },
      {
        id: 'phone',
        label: 'Phone Number',
        type: InputType.text,
        settings: {
          isPhoneNumber: true,
        },
      },
      {
        id: 'age',
        label: 'Age Range',
        type: InputType.select,
        settings: {
          description: 'Select the age range',
          defaultValue: null,
          options: [
            {
              value: 21,
              label: '21 - 25',
            },
            {
              value: 26,
              label: '26 - 30',
            },
            {
              value: 31,
              label: '31 - 36',
            },
            {
              value: 36,
              label: '36 - 40',
            },
            {
              value: 41,
              label: '41 - 45',
            },
            {
              value: 46,
              label: '46 - 50',
            },
            {
              value: 51,
              label: '51 - 55',
            },
            {
              value: 56,
              label: '56 - 60',
            },
            {
              value: 61,
              label: '61 - 65',
            },
            {
              value: 66,
              label: '66 - 70',
            },
            {
              value: 71,
              label: '71+',
            },
          ],
        },
      },
      {
        id: 'employer_name',
        label: 'Employer Name',
        type: InputType.textTypeahead,
        settings: {
          lookup: 'employer',
          options: [],
        },
      },
      {
        id: 'employer_zipcode',
        label: 'Employer Zip Code',
        type: InputType.text,
        settings: {},
      },
    ]
  }

  populateFields(buyer: Buyer | undefined): void {
    if (!buyer) {
      return
    }

    this.first_name = buyer.spouse.first_name
    this.last_name = buyer.spouse.last_name
    this.email = buyer.spouse.email
    this.phone = buyer.spouse.phone
    this.age = buyer.spouse.age
  }
}
