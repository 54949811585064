




















import { Component, Vue, Prop } from 'nuxt-property-decorator'
import Material from '~/models/material'

@Component({
  name: 'MaterialIcon',
})
export default class MaterialIcon extends Vue {
  @Prop() material: Material

  get materialColor(): String {
    if (!this.material) {
      return ''
    }

    const hexColor = this.material.hex
    if (!hexColor) {
      return 'background-color: #FFFFFF'
    }

    if (hexColor.includes('#')) {
      return `background-color: ${hexColor}`
    }

    return `background-color: #${hexColor}`
  }

  get getTooltip() {
    const brand = this.material.brand
    const image = this.material.image_url
    const name = this.material.name
    let content = ''
    if (image) {
      content = `<img src=${image} alt=${name} class="w-full h-full">`
    } else {
      let style = ''
      const hexColor = this.material?.hex
      if (hexColor) {
        if (hexColor.includes('#')) {
          style = `background-color: ${hexColor}`
        }
        style = `background-color: #${hexColor}`
      } else {
        style = `background-color: #FFFFFF`
      }
      if (style) {
        content = `<div class="w-full h-full" style="${style}"> </div>`
      }
    }
    return `
              <div class="w-32 h-36">
                <div class="max-h-32 h-2/3 w-full">
                  ${content}
                </div>
                <div class="h-1/3 text-ellipsis flex flex-col justify-center items-center">
                  <div class="text-sm">${name}</div>
                  <div class="text-xs">${brand}</div>
                </div>
              </div>
            `
  }
}
