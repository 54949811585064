






















































import { Component, Vue, Watch } from 'nuxt-property-decorator'
import { mapGetters } from 'vuex'
import NotificationDialog from '../shared/NotificationDialog.vue'
import Reports from './classes/reports'
import Input from '~/components/shared/inputs/Input.vue'
import { Project, Notification } from '~/models'
import { TrafficReportTypes } from '~/enums/trafficReport'
import NormalTrafficReport from '~/components/traffic_report/NormalTrafficReport.vue'
import AdvancedTrafficReport from '~/components/traffic_report/AdvancedTrafficReport.vue'
import SalesTrafficReport from '~/components/traffic_report/SalesTrafficReport.vue'

@Component({
  components: {
    Input,
    NormalTrafficReport,
    AdvancedTrafficReport,
    SalesTrafficReport,
    NotificationDialog,
  },
  computed: {
    ...mapGetters('user', {
      project: 'project',
      notificationDate: 'notificationDate',
    }),
    ...mapGetters('ui', {
      showTrafficReport: 'showTrafficReport',
    }),
  },
})
export default class TrafficReport extends Vue {
  project: Project
  showTrafficReport: boolean
  showNotification: boolean
  notificationDate: string

  oldReportsEditionDisabled: boolean = false
  trafficReportTypes: typeof TrafficReportTypes = TrafficReportTypes
  notifications: Array<Notification> = []

  showLastWeekNotification: boolean = false

  created() {
    this.wasCreatedLastWeekReport()
  }

  get type(): string {
    if (!this.project || !this.project.config) {
      return ''
    }
    return this.project.config.traffic_report_type
  }

  async wasCreatedLastWeekReport(): Promise<void> {
    try {
      const wasCreated: boolean = await Reports.lastWeekReportCreated()
      if (wasCreated) {
        return
      }

      if (!this.notificationDate) {
        this.showLastWeekNotification = true
        return
      }

      const notificationDate: Date = new Date(this.notificationDate)
      const today: Date = new Date()

      // @ts-ignore
      const milliseconds = notificationDate - today
      const minutes = Math.floor(milliseconds / 60000)

      if (minutes <= 0) {
        this.showLastWeekNotification = true
      }
    } catch (error) {
      console.log(error)
    }
  }

  @Watch('notifications')
  onNotificationsChanged(): void {
    setTimeout(() => {
      this.notifications = []
    }, 1000 * 2)
  }

  onNotoficationsChanged(notifications: Array<Notification>): void {
    if (!this.notifications.length) {
      return
    }
    this.notifications = notifications
  }

  onCloseNotification(): void {
    this.showLastWeekNotification = false
  }
}
