import { BuilderContract, Lot, PagedResponse } from '~/models'
import { $axios } from '~/utils/api'

const END_POINT = '/builder'

// Object to store ongoing requests
const ongoingRequests = {}

const getMaterialCategories = (builderId: number): Promise<string[]> => {
  const requestKey = `${END_POINT}/${builderId}/material_categories`

  // @ts-ignore
  if (!ongoingRequests[requestKey]) {
    // @ts-ignore
    ongoingRequests[requestKey] = $axios.$get(requestKey).finally(() => {
      // @ts-ignore
      delete ongoingRequests[requestKey]
    })
  }
  // @ts-ignore
  return ongoingRequests[requestKey]
}

const getContracts = (builderId: number): Promise<BuilderContract[]> =>
  $axios.$get(`${END_POINT}/${builderId}/contracts`)

const getDevelopmentLots = (
  builderId: number,
  contractId?: number,
  page?: number
): Promise<PagedResponse<Lot>> => {
  let url: string = `${END_POINT}/${builderId}/development_lots`

  if (contractId) {
    url += `${url.includes('?') ? '&' : '?'}contract=${contractId}`
  }

  if (page) {
    url += `${url.includes('?') ? '&' : '?'}page=${page}`
  }

  return $axios.$get(url)
}

export { getMaterialCategories, getContracts, getDevelopmentLots }
