






























































































import { Component, Emit, Prop, Watch } from 'nuxt-property-decorator'
import BaseInputComponent from './BaseInput.vue'
import RealtorFormComponent from '~/components/forms/RealtorForm.vue'
import { Realtor } from '~/models'

@Component({
  components: {
    RealtorFormComponent,
  },
})
export default class RealtorInputTypeahead extends BaseInputComponent {
  @Prop({ default: '' })
  value: number

  @Prop({ default: '' })
  type: string

  @Prop({ default: '' })
  lookup: string

  @Prop()
  options: Array<any>

  @Prop({ default: (): string => null })
  prefix: string

  @Prop({ default: true }) editable: boolean

  open: boolean = false
  showAlert: boolean = false

  realtorSelected: Realtor = null
  searchCriteria: string = ''
  realtorsList: Array<Realtor> = []

  isLoading: boolean = false

  get getRealtorCompleteName(): string {
    return this.realtorSelected
      ? this.realtorSelected.first_name + ' ' + this.realtorSelected.last_name
      : this.searchCriteria
      ? this.searchCriteria
      : ''
  }

  updated() {
    if (this.searchCriteria === '') {
      this.realtorSelected = this.getRealtor(this.value)
    }
  }

  mounted() {
    this.realtorSelected = this.getRealtor(this.value)
  }

  getRealtor(realtor_id: number): Realtor {
    return this.value
      ? this.realtorsList.find((realtor) => realtor.id === realtor_id)
      : null
  }

  @Emit('lookup')
  handleInput(event: any): string {
    this.isLoading = true
    this.cleanRealtorSelected()
    this.setupOpen(true)
    this.searchCriteria = event.target.value ? event.target.value : ''
    return event.target.value ? event.target.value : event
  }

  @Emit('lookup')
  inputClean(): string {
    this.setupOpen(false)
    this.inputPristine()
    return ''
  }

  @Emit('input')
  select(option: any): void {
    this.setupOpen(false)
    this.cleanSearchCriteria()
    return option.id
  }

  @Emit('input')
  inputPristine(): any {
    this.cleanRealtorSelected()
    this.cleanSearchCriteria()
    this.realtorsList = []
    return ''
  }

  @Emit('input')
  @Emit('lookup')
  setRealtorId(event: number): number {
    return event
  }

  setupOpen(value: boolean): void {
    this.open = value
  }

  cleanRealtorSelected(): void {
    this.realtorSelected = null
  }

  cleanSearchCriteria(): void {
    this.searchCriteria = ''
  }

  toggleModal(event: any): void {
    this.setupOpen(false)
    this.cleanRealtorSelected()

    this.showAlert = !this.showAlert

    if (event?.id) {
      this.realtorSelected = event
      if (!this.showAlert) this.setRealtorId(event.id)
    }
  }

  @Watch('options', { deep: true })
  onOptionsChange(options: Array<Realtor>) {
    this.realtorsList = options
    this.isLoading = false
  }

  @Emit('validateField')
  handleBlur(): any {}
}
