import { Elevation, GTMEvent, LotInfo, RentalUnit } from '~/models'
import Plan from '~/models/plan'

export class EventLayers {
  /**
   *
   * @param event all the necessary event data for the layer
   * @param extraData extra data from the object needed for the layer
   * @param host host name, defalt = '*'
   */
  static send(event: GTMEvent, extraData: any = {}, host: string = '*') {
    if (extraData && !(typeof extraData === 'object')) {
      throw new Error('Extra data must be an object')
    }

    const payload = {
      ...event,
      ...extraData,
    }

    if (!window.parent) {
      throw new Error('parent not found on window')
    }

    window.parent.postMessage(payload, host)
  }
}

export class Events {
  static filter(): void {}

  static submitRequestInfo(
    event: string,
    category: string,
    action: string,
    page: string,
    builder?: string,
    lot?: LotInfo | RentalUnit,
    plan?: Plan
  ): void {
    const gtm: any = {
      category,
      action,
      builder,
      page,
    }

    if (lot) {
      gtm.address = lot.address
      gtm.action_type = 'submit'
      gtm.event_label =
        'builder' in lot ? lot.builder?.name || lot.builder : lot.address
      gtm.event_action = 'submit | request information'
    }

    if (plan) {
      const elevations: Array<Elevation> = plan?.elevations || []
      gtm.information = {
        price: plan.price,
        plan: plan.name,
        sqft: plan.sqft,
        elevations: elevations.map((elevation: Elevation) => elevation.name),
      }
    }

    EventLayers.send({
      event,
      gtm,
    })
  }

  static requestInfo(
    event: string,
    category: string,
    action: string,
    builder: string,
    page: string,
    lot?: LotInfo | RentalUnit,
    plan?: Plan
  ): void {
    const gtm: any = {
      category,
      action,
      builder,
      page,
    }

    if (lot) {
      gtm.address = lot.address
    }

    if (plan) {
      const elevations: Array<Elevation> = plan?.elevations || []
      gtm.information = {
        price: plan.price,
        plan: plan.name,
        sqft: plan.sqft,
        elevations: elevations.map((elevation: Elevation) => elevation.name),
      }
    }

    EventLayers.send({
      event,
      gtm,
    })
  }

  static lot(
    event: string,
    action: string,
    lot: LotInfo | RentalUnit,
    category?: string,
    type?: string
  ): void {
    let cat: string = category
    if (!category) {
      cat = 'architectural_submission' in lot ? 'Home' : 'Home Site'
    }

    if ('rental_listing' in lot) {
      cat = 'Rental Unit'
    }

    EventLayers.send({
      event,
      gtm: {
        category: cat,
        action,
        builder: 'builder' in lot ? lot?.builder?.name : null,
        address: lot.address,
        lot_number: 'number' in lot ? lot.number : lot.unit_number,
        lot_size: 'size' in lot ? lot.size : lot.rent_sqft,
        lot_type: 'type' in lot ? lot.type : 'Rental',
        phase: 'phase' in lot ? lot.phase : null,
        neighborhood: 'neighborhood' in lot ? lot.neighborhood : null,
        event_type: type,
      },
    })
  }

  static actions(
    event: string,
    action: string,
    category: string,
    builder?: string,
    lot?: LotInfo | RentalUnit,
    plan?: Plan
  ): void {
    const gtm: any = {
      category,
      action,
      builder,
    }

    if (lot) {
      gtm.address = lot.address
      gtm.lot_number = 'number' in lot ? lot.number : lot.unit_number
      gtm.parcel_id = 'parcel_id' in lot ? lot.parcel_id : null
    }

    if (plan) {
      const elevations: Array<Elevation> = plan?.elevations || []
      gtm.information = {
        price: plan.price,
        plan: plan.name,
        sqft: plan.sqft,
        elevations: elevations.map((elevation: Elevation) => elevation.name),
      }
    }

    EventLayers.send({
      event,
      gtm,
    })
  }

  static pages(event: string, name: string): void {
    EventLayers.send({
      event,
      gtm: {
        name,
      },
    })
  }
}
