







import { Component, mixins } from 'nuxt-property-decorator'
import { mapState } from 'vuex'
import globalStyling from '~/mixins/globalStyling'
import XofYCounter from './XofYCounter.vue'

@Component({
    components: {
        XofYCounter,
    },
    computed: {
        ...mapState('consumer-map', {
            plansCount: 'plansCount',
            totalPlansFiltered: 'totalPlansFiltered',
        }),
    },
    mixins: [globalStyling],
})
export default class PropertiesCounter extends mixins(globalStyling) {

}

