import { prop, propObject, required, minNumber } from '@rxweb/reactive-forms'

import Architectural from '../architectural'
import FormStep from './FormStep'
import BuyerForm from './buyerForm'
import SpouseForm from './spouseForm'
import RealtorForm from './realtorForm'
import { LabelSizes } from '~/enums/labelSizes'
import { InputType } from '~/enums/inputType'
import { ArchitecturalTag } from '~/enums/architecturalTag'

export default class ContractForm {
  @required() @minNumber({ value: 1 }) price: number
  @required() @minNumber({ value: 1 }) base_price: number
  @required() sold_on: string
  @prop() children_ages: string[] = []
  @required() close_estimated_on: string
  @propObject() buyer: BuyerForm
  @propObject() spouse: SpouseForm
  @propObject() realtor: RealtorForm
  @minNumber({ value: 0 }) discounts_applied: number
  @minNumber({ value: 0 }) additional_fees: number

  spec?: Architectural

  constructor(spec?: Architectural) {
    this.buyer = new BuyerForm()
    this.spouse = new SpouseForm()
    this.realtor = new RealtorForm()
    this.price = 0
    this.base_price = 0
    this.discounts_applied = 0
    this.additional_fees = 0

    this.spec = spec
  }

  getSteps(isEdition: boolean = false): FormStep[] {
    return [
      {
        title: 'Overview',
        step: 1,
        valid: false,
        controls: [
          {
            id: 'sold_on',
            label: 'Sold Date',
            parentClass: 'w-1/2 pr-2',
            type: InputType.date,
            required: true,
            settings: {
              show: true,
              description: 'Date of sale with the homebuyer',
              titleSize: LabelSizes.extraLarge,
            },
          },
          {
            id: 'close_estimated_on',
            label: 'Estimated Close Date',
            parentClass: 'w-1/2 pl-2',
            type: InputType.date,
            required: true,
            settings: {
              show: true,
              description: 'Date the sale is expected to close',
              titleSize: LabelSizes.extraLarge,
            },
          },
          {
            id: 'base_price',
            heapId: 'contract-form_price',
            label:
              this.spec && this.spec.orig_sale_type === ArchitecturalTag.BUILD
                ? 'Sales Price'
                : 'Listing Price',
            parentClass: 'w-1/2 pr-2',
            type: InputType.currency,
            required: !isEdition, // this field will only be required in create mode, in edit mode will be disabled
            settings: {
              show: true,
              disabled: isEdition,
              description:
                this.spec && this.spec.orig_sale_type === ArchitecturalTag.BUILD
                  ? 'Price entered when Build was submitted'
                  : 'Listing price of the Spec ',
              titleSize: LabelSizes.extraLarge,
            },
          },
          {
            id: 'price',
            heapId: 'contract-form_price',
            label: `Sales Price ${
              this.spec && this.spec.orig_sale_type === ArchitecturalTag.BUILD
                ? '(Updated)'
                : ''
            }`,
            parentClass: 'w-1/2 pl-2',
            type: InputType.currency,
            required: true,
            settings: {
              show: true,
              disabled: false,
              description:
                this.spec && this.spec.orig_sale_type === ArchitecturalTag.BUILD
                  ? 'Final price paid by homebuyer'
                  : 'Price paid by homebuyer',
              titleSize: LabelSizes.extraLarge,
            },
          },
          {
            id: 'additional_fees',
            label: 'Additional Fees',
            parentClass: '',
            type: InputType.currency,
            required: false,
            settings: {
              show: false,
              titleSize: LabelSizes.extraLarge,
              description:
                'Increased costs due to upgrades, premiums or change orders',
            },
          },
          {
            id: 'discounts_applied',
            label: 'Discounts Applied',
            parentClass: '',
            type: InputType.currency,
            required: false,
            settings: {
              show: false,
              titleSize: LabelSizes.extraLarge,
              description:
                'Discounts offered to the buyer for incentives or offers',
            },
          },
        ],
      },
      {
        title: 'Buyer(s) Info',
        step: 2,
        valid: false,
        controls: [
          {
            id: 'buyer',
            type: null,
            nested: true,
            label: '',
            parentClass: 'w-full',
            controls: this.buyer.controls,
            required: true,
            settings: {
              description:
                'Enter information about the buyer to evaluate marketing and assist HOA planning',
            },
          },
          {
            id: 'spouse',
            type: null,
            nested: true,
            label: 'Secondary Buyer',
            parentClass: 'w-full',
            controls: this.spouse.controls,
            required: false,
            settings: {
              description:
                'Enter information about the secondary buyer (if applicable)',
            },
          },
        ],
      },
      {
        title: 'Additional Info',
        step: 3,
        valid: false,
        controls: [
          {
            id: 'realtor',
            type: null,
            nested: true,
            label: 'Realtor Information',
            parentClass: 'w-full',
            controls: this.realtor.controls,
            required: true,
            settings: {
              show: true,
              description:
                'Enter information about the realtor involved in the sale (if applicable)',
            },
          },
          {
            id: 'children_ages',
            type: InputType.children,
            nested: null,
            label: 'Children',
            required: false,
            parentClass: 'w-full',
            settings: {
              show: true,
              titleSize: LabelSizes.extraLarge,
              description:
                'Enter number of children in the home (if applicable) to assist HOA and school needs planning',
            },
          },
        ],
      },
    ]
  }
}
