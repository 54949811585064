import { prop, email, pattern, required } from '@rxweb/reactive-forms'
import { Realtor } from '~/models'
import { InputType } from '~/enums/inputType'

export default class RealtorForm {
  @prop() first_name: string
  @prop() last_name: string
  @email({ message: 'Provide a valid email' }) email: string

  @prop()
  @pattern({
    expression: { onlyAlpha: /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/ },
    message: 'Provide a valid phone number',
  })
  phone: string

  @prop() agency_name: string
  @prop() agency_branch: string

  get controls(): any[] {
    return [
      {
        id: 'first_name',
        label: 'First Name',
        type: InputType.text,
        settings: {},
      },
      {
        id: 'last_name',
        label: 'Last Name',
        type: InputType.text,
        settings: {},
      },
      {
        id: 'email',
        label: 'Email Address',
        type: InputType.text,
        settings: {},
      },
      {
        id: 'phone',
        label: 'Phone Number',
        type: InputType.text,
        settings: {
          isPhoneNumber: true,
        },
      },
      {
        id: 'agency_name',
        label: 'Company',
        type: InputType.text,
        settings: {},
      },
      {
        id: 'agency_branch',
        label: 'Location',
        type: InputType.text,
        settings: {},
      },
    ]
  }

  populateFields(realtor: Realtor): void {
    if (!realtor) {
      return
    }

    this.first_name = realtor.first_name
    this.last_name = realtor.last_name
    this.email = realtor.email
    this.phone = realtor.phone
  }
}

export class RealtorFormLookup {
  @prop()
  @required({ message: 'First name is a required field' })
  first_name: string

  @prop()
  @required({ message: 'Last name is a required field' })
  last_name: string

  @prop()
  @email({ message: 'Provide a valid email' })
  @required({ message: 'Email is a required field' })
  email: string

  @prop()
  @pattern({
    expression: { onlyAlpha: /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/ },
    message: 'Provide a valid phone number',
  })
  @required({ message: 'Phone is a required field' })
  phone: string

  @prop()
  @required({ message: 'Company is a required field' })
  agency_name: string

  @prop()
  @required({ message: 'Location is a required field' })
  agency_branch: string

  @prop()
  realtor_team: string

  get controls(): any[] {
    return [
      {
        id: 'first_name',
        label: 'First Name',
        type: InputType.text,
        settings: {},
      },
      {
        id: 'last_name',
        label: 'Last Name',
        type: InputType.text,
        settings: {},
      },
      {
        id: 'email',
        label: 'Email Address',
        type: InputType.text,
        settings: {},
      },
      {
        id: 'phone',
        label: 'Mobile Phone Number',
        type: InputType.text,
        settings: {
          isPhoneNumber: true,
        },
      },
      {
        id: 'agency_name',
        label: 'Company (ex. Keller Williams)',
        type: InputType.text,
        settings: {},
      },
      {
        id: 'agency_branch',
        label: 'Office Location (ex. New York)',
        type: InputType.text,
        settings: {},
      },
      {
        id: 'realtor_team',
        label: 'Realtor Team Name (ex. Elite Realtors)',
        type: InputType.text,
        settings: {},
      },
    ]
  }

  populateFields(realtor: Realtor): void {
    if (!realtor) {
      return
    }

    this.first_name = realtor.first_name
    this.last_name = realtor.last_name
    this.email = realtor.email
    this.phone = realtor.phone
    this.realtor_team = realtor.realtor_team

    const { name, branch } = realtor?.agency || {}
    this.agency_name = name
    this.agency_branch = branch
  }
}
