




































import { mapGetters } from 'vuex'
import { Component, Vue } from 'nuxt-property-decorator'
import json from '~/package.json'
import Project from '~/models/project'

@Component({
  computed: {
    ...mapGetters('user', {
      project: 'project',
    }),
  },
})
export default class Footer extends Vue {
  project: Project
  version: string

  created() {
    this.version = json.version
  }

  get thirdPartyButtonText() {
    return this.project?.config?.third_party_button_name
  }

  get thirdPartyButtonUrl() {
    return this.project?.config?.third_party_button_url
  }
}
