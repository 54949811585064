import { prop, required } from '@rxweb/reactive-forms'
import Addition from '../addition'
import { InputType } from '~/enums/inputType'

export default class AdditionForm {
  @required() name: string
  @required() type: string
  @prop() price: number
  @prop() sqft: number
  @prop() applies_to: string

  plan_id: number

  get controls(): any[] {
    return [
      {
        label: 'Name',
        id: 'name',
        type: InputType.text,
        parentClass: 'w-1/2 pr-6',
        required: true,
        settings: {},
      },
      {
        label: 'Type',
        id: 'type',
        type: InputType.select,
        parentClass: 'w-1/2 pl-6',
        required: true,
        settings: {},
        options: [],
      },
      {
        label: 'Price Increase',
        id: 'price',
        type: InputType.currency,
        required: false,
        parentClass: 'w-1/2 pr-6',
        settings: {},
      },
      {
        label: 'A/C SQ FT',
        id: 'sqft',
        type: InputType.number,
        parentClass: 'w-1/2 pl-6',
        required: false,
        settings: {},
      },
      {
        label: 'Applies to',
        id: 'applies_to',
        type: InputType.select,
        parentClass: 'w-1/2 pr-6',
        options: [],
        required: false,
        settings: {},
      },
    ]
  }

  constructor(addition: Addition) {
    this.price = null
    if (addition) {
      this.name = addition.name ? addition.name : undefined
      this.type = addition.type ? addition.type : undefined
      this.price = addition.price ? addition.price : undefined
      this.sqft = addition.sqft ? addition.sqft : undefined
      this.applies_to =
        addition.applies_to && addition.applies_to.length > 0
          ? addition.applies_to
          : undefined
    }
  }
}
