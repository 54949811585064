import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'
import auth from '~/store/auth'
import user from '~/store/user'
import lot from '~/store/lot'
import plan from '~/store/plan'
import constants from '~/store/constants'
import ui from '~/store/ui'
import filters from '~/store/filters'
import project from '~/store/project'
import builder from '~/store/builder'
import consumerMap from '~/store/consumer-map'
import rentalMap from '~/store/rental-map'
import favorites from '~/store/favorites'
import persistedFilters from '~/store/persisted-filters'
import rentalFavorites from '~/store/rental-favorites'
import masterPlan from '~/store/master-plan'

let authStore: auth
let userStore: user
let lotStore: lot
let planStore: plan
let constantsStore: constants
let uiStore: ui
let filtersStore: filters
let projectStore: project
let builderStore: builder
let consumerMapStore: consumerMap
let rentalMapStore: rentalMap
let favoritesStore: favorites
let persistedFiltersStore: persistedFilters
let rentalFavoritesStore: rentalFavorites
let masterPlanStore: masterPlan

function initialiseStores(store: Store<any>): void {
  authStore = getModule(auth, store)
  userStore = getModule(user, store)
  lotStore = getModule(lot, store)
  planStore = getModule(plan, store)
  constantsStore = getModule(constants, store)
  uiStore = getModule(ui, store)
  filtersStore = getModule(filters, store)
  projectStore = getModule(project, store)
  builderStore = getModule(builder, store)
  consumerMapStore = getModule(consumerMap, store)
  rentalMapStore = getModule(rentalMap, store)
  favoritesStore = getModule(favorites, store)
  persistedFiltersStore = getModule(persistedFilters, store)
  rentalFavoritesStore = getModule(rentalFavorites, store)
  masterPlanStore = getModule(masterPlan, store)
}

export {
  initialiseStores,
  authStore,
  userStore,
  lotStore,
  planStore,
  constantsStore,
  uiStore,
  filtersStore,
  projectStore,
  builderStore,
  consumerMapStore,
  rentalMapStore,
  favoritesStore,
  persistedFiltersStore,
  rentalFavoritesStore,
  masterPlanStore,
}
