import {
  createReport,
  updateReport,
  retrieveReport,
  lastWeekReport,
} from '~/api/traffic.api'
import { TrafficReportTypes } from '~/enums/trafficReport'
import { formatDate } from '~/utils/utils'
import { AdvancedReport, NormalReport, PagedResponse, Record } from '~/models'

const emptyReport = (
  startDate: string,
  endDate: string,
  size: number
): NormalReport | AdvancedReport => ({
  type: '',
  size,
  start_date: startDate,
  end_date: endDate,
  created_on: '',
  visits: 0,
  return_visits: 0,
  realtor_visits: 0,
  records: [],
})

const calendarFormatDate = (date: string): string => {
  if (!date || !date.includes('-')) {
    return
  }
  const [year, month, day] = date.split('-')
  return `${month}/${day}/${year}`
}

const fixReportDates = (
  report: NormalReport | AdvancedReport
): NormalReport | AdvancedReport => {
  return {
    ...report,
    start_date: calendarFormatDate(report.start_date),
    end_date: calendarFormatDate(report.end_date),
  }
}

export default class Reports {
  static async retrieve(
    startDate: string,
    endDate: string,
    lotSize: number
  ): Promise<NormalReport | AdvancedReport> {
    try {
      const response: any = await retrieveReport(
        formatDate(startDate),
        formatDate(endDate),
        lotSize
      )

      if (!response.data) {
        return emptyReport(startDate, endDate, lotSize) as NormalReport
      }

      const pagedResponse: PagedResponse<NormalReport | AdvancedReport> =
        response.data

      const reports: Array<NormalReport | AdvancedReport> =
        pagedResponse.results

      if (!reports || !reports.length) {
        return emptyReport(startDate, endDate, lotSize) as NormalReport
      }

      return fixReportDates(reports[0])
    } catch (error) {
      throw new Error(
        `Unable to retrieve Report from dates ${startDate} - ${endDate}`
      )
    }
  }

  static async create(
    report: NormalReport | AdvancedReport,
    type: string,
    ...args: any
  ): Promise<NormalReport | AdvancedReport> {
    try {
      const weekRange: string[] = report.week_range || []

      if (report.week_range) {
        delete report.week_range
      }

      if (weekRange.length === 2) {
        report.start_date = weekRange[0]
        report.end_date = weekRange[1]
      }

      if (args.length && type === TrafficReportTypes.ADVANCED) {
        ;(report as AdvancedReport).records = args.flat(2) as Array<Record>
      }

      return await createReport(report)
    } catch (error) {
      throw new Error('Unable to create Report')
    }
  }

  static async update(
    report: NormalReport | AdvancedReport,
    type: string,
    ...args: any
  ): Promise<NormalReport | AdvancedReport> {
    if (!report.id) {
      throw new Error('Unable to update report, missing ID')
    }

    if (report.week_range) {
      delete report.week_range
    }

    if (args.length && type === TrafficReportTypes.ADVANCED) {
      ;(report as AdvancedReport).records = args.flat(2) as Array<Record>
    }

    return await updateReport(report.id, report)
  }

  static async lastWeekReportCreated(): Promise<boolean> {
    try {
      const response: any = await lastWeekReport()
      return response.data.found
    } catch (error) {
      return false
    }
  }
}
