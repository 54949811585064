


























































































































































import { Component, Vue, Prop, Emit, Watch } from 'nuxt-property-decorator'

@Component({})
export default class Select extends Vue {
  @Prop({ default: '' }) title: String
  @Prop() options: any[]
  @Prop() initial: number | string

  activeOption: any = null
  highlightOption: string | number | null = null
  isActive: boolean = false
  optionName: string = '-'

  @Watch('initial', { immediate: true })
  onInitialchange(newValue: number | string) {
    if (newValue) {
      let option = this.options.filter(({ id, _name }) => id === newValue)
      if (option.length > 0) {
        option = option[0]
        this.activeOption = option
      }
    }
  }

  open(): void {
    this.isActive = !this.isActive
  }

  setActiveOption(option: any): void {
    this.activeOption = option
    this.optionName = option.name
    this.isActive = false
  }

  @Emit('onChange')
  onSelect(option: any): any {
    this.setActiveOption(option)
    return option
  }
}
