import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { getChildren } from '~/api/children.api'
import BaseState from '~/models/state/baseState'
import Subcategory from '~/models/subcategory'

@Module({
  name: 'constants',
  stateFactory: true,
  namespaced: true,
})
export default class Constants extends VuexModule implements BaseState {
  subcategories: Subcategory[] = null
  age_choices: string[][] = null

  get documentSubCategories(): Subcategory[] {
    return this.subcategories?.filter(
      (subcategory: Subcategory) => subcategory.category === 'document'
    )
  }

  get age_choices_list(): string[][] {
    return this.age_choices
  }

  @Action({ rawError: true })
  async initConstants() {
    const children = await getChildren()
    this.setChildren(children.age_choices)
  }

  @Mutation
  setSubcategories(subCategories: Subcategory[]) {
    this.subcategories = subCategories
  }

  @Mutation
  setChildren(age_choices: string[][]) {
    this.age_choices = age_choices
  }

  @Mutation
  clear() {
    this.subcategories = null
    this.age_choices = null
  }
}
