import { prop, required } from '@rxweb/reactive-forms'
import Architectural from '../architectural'
import FormControl from './FormControl'
import { InputType } from '~/enums/inputType'
import { LabelSizes } from '~/enums/labelSizes'

export default class ReviewArchitecturalForm {
  @required({ message: 'This field is required' }) status: string = 'approved'
  @prop() description: string
  @prop() conditions: string
  @prop() files: number[]

  @prop() variance_name: string
  @prop() variance_phone: string
  @prop() variance_email: string
  @prop() variance_reason: string

  constructor(spec?: Architectural) {
    if (spec && spec.variance) {
      this.variance_name = spec.variance_info.name
      this.variance_phone = spec.variance_info.phone
      this.variance_email = spec.variance_info.email
      this.variance_reason = spec.variance_info.reason
    }
  }

  static CONTROLS: Array<FormControl> = [
    {
      id: 'status',
      label: 'Status',
      type: InputType.select,
      required: true,
      settings: {
        options: [
          { label: 'Approve', value: 'approved' },
          { label: 'Deny', value: 'denied' },
        ],
        description:
          'Select the status for this architectural review. This will trigger an email to the builder user.',
        titleSize: LabelSizes.extraLarge,
      },
    },
    {
      id: 'variance_name',
      label: 'Variance Name',
      type: InputType.text,
      parentClass: 'w-full pb-2',
      required: false,
      settings: {
        validators: [],
        description:
          'Enter the full name for the review team to understand who to contact for your request',
        preview: true,
        disabled: true,
      },
    },
    {
      id: 'variance_phone',
      label: 'Variance Phone',
      type: InputType.text,
      parentClass: 'w-full pb-2',
      required: false,
      settings: {
        validators: [],
        description:
          'Enter the phone number for the review team to understand how to contact for your request',
        preview: true,
        disabled: true,
      },
    },
    {
      id: 'variance_email',
      label: 'Variance Email',
      type: InputType.text,
      parentClass: 'w-full pb-2',
      required: false,
      settings: {
        validators: [],
        description:
          'Enter the email for the review team to understand how to contact for your request',
        preview: true,
        disabled: true,
      },
    },
    {
      id: 'variance_reason',
      label: 'Variance Reason',
      type: InputType.text,
      required: false,
      settings: {
        disabled: true,
        description: '',
        titleSize: LabelSizes.extraLarge,
        isTextArea: true,
      },
    },
    {
      id: 'description',
      label: 'Comments',
      type: InputType.text,
      required: false,
      settings: {
        disabled: false,
        description:
          'Include any additional comments to assist the builder with their approval',
        titleSize: LabelSizes.extraLarge,
        isTextArea: true,
      },
    },
    {
      id: 'conditions',
      label: 'Conditions',
      type: InputType.text,
      required: false,
      settings: {
        disabled: false,
        description:
          'Include any additional conditions for the builder to follow',
        titleSize: LabelSizes.extraLarge,
        isTextArea: true,
      },
    },
  ]
}
