export enum LotTypes {
  HOMESITE = 'homesite',
  HOME_SITE = 'home site',
  HOME = 'home',
  PRESALE = 'pre-sale',
  CONTRACT = 'contract',
  CLOSED = 'closed',
  RESERVED = 'reserved',
  RENTAL = 'rental',
  MODEL = 'model',
}

export enum LotStatus {
  AVAILABLE = 'available',
  BUILD = 'build',
  COMING_SOON = 'coming soon',
  CONSTRUCTION = 'construction',
  UNDER_CONTRACT = 'under contract',
  TRAILER = 'trailer',
  MOVE_IN_READY = 'move in ready',
  ARC = 'architectural submission',
  MODEL = 'model',
  OCCUPIED = 'occupied',
  WINDOW = 'window',
  OPEN_SPACE = 'open_space',
  COMMERCIAL = 'commercial',
}

export enum HomeSalesBlockColors {
  // HOME_SITE = '#5fa2ce',
  // HOME = '#1170aa',
  // PRE_SALE = '#fc8d0b',
  // CONTRACT = '#ffbc79',
  // CLOSED = '#c8d0d9',
  // MODEL = '#DC2626',
  // AVAILABLE = '#D42884',
  // UNAVAILABLE = '#25A65D',
  // RESERVED = '#DC2626',
  // DENIED = '#657AB1',
  HOVER = '#3b82f6',
}

export enum HomeSalesStatusLabels {}
// HOME_SITE = 'Home Site',
// HOME = 'Spec Home',
// PRE_SALE = 'Pre Sale',
// CONTRACT = 'Contract',
// CLOSED = 'Closed',
// MODEL = 'Model',
// RESERVED = 'Model',
// AVAILABLE = 'Available',
// UNAVAILABLE = 'Unavailable',
// DENIED = 'Denied',

export enum HomeSalesStatusLabelsLegendOrder {}
// HOME_SITE = 1,
// HOME = 2,
// PRE_SALE = 3,
// CONTRACT = 4,
// CLOSED = 5,
// MODEL = 6,
// RESERVED = 7,
// AVAILABLE = 8,
// UNAVAILABLE = 9,
// DENIED = 10,
