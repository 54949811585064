import { FilterFields, Project } from '~/models'
import { $axios } from '~/utils/api'

const PROJECT_ENDPOINT = '/projects'
const CONSUMER_MAP_ENDPOINT = '/map/consumer/projects'

const getProjects = (id?: number): Promise<Project> =>
  $axios.$get(`${PROJECT_ENDPOINT}${id ? `/${id}` : ''}`)

const getConsumerMapProjects = (id?: number | string): Promise<Project> =>
  $axios.$get(`${CONSUMER_MAP_ENDPOINT}${id ? `/${id}` : ''}`)

const getPhases = (projectId: number) =>
  $axios.$get(`${PROJECT_ENDPOINT}/${projectId}/phases`)

const getMiscTypes = (projectId: number): Promise<Array<FilterFields>> =>
  $axios.$get(`${PROJECT_ENDPOINT}/${projectId}/misc_types`)

export { getProjects, getConsumerMapProjects, getPhases, getMiscTypes }
