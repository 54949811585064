// mixin.ts
import { Component, Vue } from 'nuxt-property-decorator'
@Component
export default class globalStyling extends Vue {
  // this function will determine if some dom element
  // should be displayed or not based on css variables defined

  getStyleVariableValue(cssPropertyName: string): string {
    const element = document.querySelector('.container')
    if (element) {
      const result = window
        .getComputedStyle(element, '')
        .getPropertyValue(cssPropertyName)
      return result
    }
  }

  isVisible(cssPropertyName: string): boolean {
    const propertySetting = this.getStyleVariableValue(cssPropertyName)
    if (propertySetting) {
      const result = propertySetting.trim() === 'false'
      return !result
    }
    return true
  }
}
