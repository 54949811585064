



















































import { Component, Vue } from 'nuxt-property-decorator'
import { Places } from '~/enums/heap'

@Component({})
export default class PlublicLayout extends Vue {
  mounted() {
    // @ts-ignore
    if (this.$heap) this.$heap(null, null, null, Places.PUBLIC_LAYOUT)
  }
}
