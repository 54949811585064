import { FilterFields } from '~/models/filters'
import { $axios } from '~/utils/api'

const FILTERS_ENDPOINT = '/filters'

const getFilters = (
  name: string,
  builder?: string,
  all_builders?: boolean
): Promise<FilterFields[]> => {
  let url: string = `${FILTERS_ENDPOINT}/${name}`
  if (builder) {
    url = `${url}?builder=${builder}`
  }

  if (all_builders) {
    url = `${url}${url.includes('?') ? '&' : '?'}all_builders=${all_builders}`
  }

  return $axios.$get(url)
}

export { getFilters }
