


















































































import { Vue, Component, Prop, Emit } from 'nuxt-property-decorator'
import Table from '~/components/shared/table/Table.vue'
import { Record, TableHeader } from '~/models'
import { TrafficReportTypes } from '~/enums/trafficReport'

@Component({
  components: {
    Table,
  },
})
export default class Sources extends Vue {
  @Prop({ default: '' })
  id: string

  @Prop({ default: '' })
  title: string

  @Prop({ default: '' })
  assistiveText: string

  @Prop({ default: '' })
  containerClass: string

  @Prop({ default: (): Array<Record> => [] })
  data: Array<Record>

  @Prop({ default: (): Array<TableHeader> => [] })
  headers: Array<TableHeader>

  @Prop({ default: false })
  showTotalRow: boolean

  @Prop({ default: '' })
  type: string

  trafficReportTypes: typeof TrafficReportTypes = TrafficReportTypes

  onChanged(): void {
    this.handleInput()
  }

  @Emit('input')
  handleInput(): Array<Record> {
    return this.data
  }

  getTotal(key: string): number {
    return this.data.reduce(
      (previous: number, record: Record) => previous + parseInt(record[key]),
      0
    )
  }
}
