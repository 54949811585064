







import { Component, Vue } from 'nuxt-property-decorator'
import { mapGetters } from 'vuex'
@Component({
  computed: {
    ...mapGetters('ui', {
      isLoading: 'isLoading',
    }),
  },
})
export default class Loader extends Vue {
  isLoading: boolean
}
