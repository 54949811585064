var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex flex-col overflow-auto",class:[_vm.tableClass]},[_c('div',[_c('div',{staticClass:"align-middle inline-block min-w-full"},[_c('div',{staticClass:"shadow border-b border-gray-200"},[_c('table',{staticClass:"min-w-full divide-y divide-gray-200"},[_c('thead',{staticClass:"bg-blueGray-900 text-white"},[_c('tr',[(_vm.selectEnabled)?_c('th',[_c('div',{staticClass:"flex justify-center"},[_c('div',[_c('CheckboxInput',{attrs:{"checked":_vm.selectAll},on:{"change":_vm.handleSelect}})],1)])]):_vm._e(),_vm._v(" "),_vm._l((_vm.headers),function(header){return _c('th',{key:header.id,staticClass:"px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer",class:[
                    !header.sorteable ? 'pointer-events-none' : '',
                    header.class ? header.class : '' ],attrs:{"scope":"col"}},[_c('div',{staticClass:"flex items-center space-x-2",class:{
                      sorteable: header.sorteable,
                      selected: _vm.sortId === header.id,
                    },on:{"click":function($event){header.sorteable ? _vm.handleSort(header) : null}}},[_c('span',[_vm._v("\n                      "+_vm._s(header.text)+"\n                    ")]),_vm._v(" "),(header.sorteable)?_c('fa',{attrs:{"icon":[
                        'axis',
                        _vm.sortId === header.id && _vm.sortOrder === 'desc'
                          ? 'arrowDownward'
                          : 'arrowUpward' ],"size":"lg"}}):_vm._e()],1)])})],2)]),_vm._v(" "),_c('tbody',{staticClass:"bg-white divide-y divide-gray-200"},[_vm._t("default")],2),_vm._v(" "),(_vm.showPagination)?_c('tfoot',{staticClass:"py-2 border-t"},[_c('tr',{staticClass:"text-center"},[_c('td',{attrs:{"colspan":"6"}},[_c('button',{staticClass:"bg-blue-500 hover:bg-blue-700 text-white text-xs font-bold py-2 px-4 rounded mr-2",attrs:{"disabled":_vm.lastPage === 0 || _vm.actualPage === 1},on:{"click":_vm.onPrevious}},[_vm._v("\n                    <\n                  ")]),_vm._v(" "),_c('span',[_vm._v("Page "+_vm._s(_vm.actualPage)+" of\n                    "+_vm._s(_vm.lastPage === 0 ? '1' : _vm.lastPage))]),_vm._v(" "),_c('button',{staticClass:"bg-blue-500 hover:bg-blue-700 text-white text-xs font-bold py-2 px-4 rounded ml-2",attrs:{"disabled":_vm.lastPage === 0 || _vm.actualPage === _vm.lastPage},on:{"click":_vm.onNext}},[_vm._v("\n                    >\n                  ")])])])]):_vm._e()])])])])]),_vm._v(" "),_vm._t("footer")],2)}
var staticRenderFns = []

export { render, staticRenderFns }