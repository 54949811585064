export const sortOptions = [
  { label: 'Price: Low to High', type: 'sort', value: 'price-asc' },
  { label: 'Price: High to Low', type: 'sort', value: 'price-desc' },
  { label: 'Sqft: Low to High', type: 'sort', value: 'sqft-asc' },
  { label: 'Sqft: High to Low', type: 'sort', value: 'sqft-desc' },
  { label: 'Builder: A to Z', type: 'sort', value: 'builder-alpha' },
  {
    label: 'Move-in Date: Earliest to Latest',
    type: 'sort',
    value: 'move-in-date-asc',
  },
]
