






















































































































































import { Component, Vue, Watch, Emit } from 'nuxt-property-decorator'
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-forms'
import { mapGetters } from 'vuex'
import Input from '../shared/inputs/Input.vue'
import AdvancedTraffic from './classes/advancedTraffic'
import Reports from './classes/reports'
import Sources from './table/Sources.vue'
import { InputType } from '~/enums/inputType'
import FormControl from '~/models/forms/FormControl'
import { AdvancedTrafficForm } from '~/models/forms'
import {
  FilterFields,
  Source,
  Record,
  AdvancedReport,
  TableHeader,
  Project,
  Notification,
} from '~/models'
import { retrieveSources, retrieveLotsData } from '~/api/traffic.api'
import {
  SourceCategories,
  TrafficReportTypes,
  NotificationTypes,
} from '~/enums/trafficReport'
import { uiStore } from '~/store'
import { isValidSubmissionDate } from '~/utils/utils'
import Table from '~/components/shared/table/Table.vue'
import { LotTypes } from '~/enums/lots'
import { SaleStatus } from '~/enums/sales'
import { getFilters } from '~/api/filters.api'

type LotData = {
  address: string
  sold_on: string | Date
  cancelled_on: string | Date
}

@Component({
  components: {
    Sources,
    Input,
    Table,
  },
  computed: {
    ...mapGetters('user', {
      project: 'project',
      builderName: 'builderName',
    }),
  },
})
export default class SalesTrafficReport extends Vue {
  // variables from store
  project: Project
  builderName: string

  // types
  inputType: typeof InputType = InputType
  sourceCategories: typeof SourceCategories = SourceCategories

  // local variables
  weekly: Array<Record> = []
  report: AdvancedReport = null
  salesSources: Array<Source> = []
  totalVisits: number = 0
  lotSizes: Array<FilterFields> = []
  requiredSales: boolean = false
  requiredCancellations: boolean = false

  /**
   * Reactive forms
   */
  formGroup: IFormGroup<AdvancedTrafficForm> = null
  controls: Array<FormControl> = AdvancedTrafficForm.CONTROLS

  // Tables Homes and cancellations data
  homesCount: number = 0
  cancelationsCount: number = 0
  homes: Array<LotData> = []
  cancellations: Array<LotData> = []

  created(): void {
    this.setForm()
    this.init()
  }

  get isLastWeekReportSubmissionsEnabled(): boolean {
    if (!this.project || !this.project.config) {
      return true
    }
    return this.project.config.enable_lastweek_report_submission
  }

  get salesHeaders(): Array<TableHeader> {
    return AdvancedTraffic.SALES_HEADERS
  }

  get homeSalesHeaders(): Array<TableHeader> {
    return AdvancedTraffic.HOME_SALES_HEADERS
  }

  get cancellationHeaders(): Array<TableHeader> {
    return AdvancedTraffic.CANCELLATION_HEADERS
  }

  async init(): Promise<void> {
    const response: any = await retrieveSources(SourceCategories.SALES)
    this.salesSources = response.data
    this.weekly = this.getRecords(this.salesSources)
  }

  @Watch('formGroup.controls.week_range.value', { deep: true })
  onWeekDateRangeChanged(
    newDatesRange: Array<string>,
    oldDatesRange: Array<string>
  ): void {
    this.notify([])
    if (!newDatesRange || !newDatesRange.length) {
      return
    }

    if (!oldDatesRange || !oldDatesRange.length) {
      this.getReport()
      this.getHomesAndCancellations()
      return
    }

    if (newDatesRange[0] === oldDatesRange[0]) {
      return
    }

    this.getHomesAndCancellations()
    this.getReport()
  }

  @Watch('formGroup.controls.size.value', { deep: true })
  onLotSizeChanged(newSize: string, oldSize: string): void {
    this.notify([])
    if (!oldSize) {
      return
    }

    if (parseInt(newSize, 10) === parseInt(oldSize, 10)) {
      return
    }

    this.getHomesAndCancellations()
    this.getReport()
  }

  @Emit('onNotification')
  notify(notifications: Array<Notification>): Array<Notification> {
    return notifications
  }

  async getHomesAndCancellations(): Promise<void> {
    if (!this.isFormValid()) {
      return
    }

    const data: any = this.formGroup.value
    const dateRange: Array<string> = data.week_range
    let response: any = await retrieveLotsData(
      LotTypes.HOME,
      data.size,
      dateRange,
      null
    )
    this.homes = response.data
    this.homesCount = this.homes.length
    response = await retrieveLotsData(
      SaleStatus.CANCELLED,
      data.size,
      null,
      dateRange
    )
    this.cancellations = response.data
    this.cancelationsCount = this.cancellations.length
  }

  setForm(): void {
    this.formGroup = null

    const form: AdvancedTrafficForm = new AdvancedTrafficForm(this.report)
    if (!form) {
      return
    }
    this.setSizes()
    this.formGroup = new RxFormBuilder().formGroup(
      form
    ) as IFormGroup<AdvancedTrafficForm>
  }

  isFormValid(): boolean {
    if (!this.formGroup) {
      return false
    }
    return this.formGroup.valid
  }

  getErrors(): void {
    if (!this.formGroup.valid) {
      const allErrors: any = this.formGroup.getErrorSummary(true)
      this.notify(
        Object.values(allErrors).map((error: any) => ({
          message: error,
          type: NotificationTypes.ERROR,
        }))
      )
    }
  }

  async setSizes(): Promise<void> {
    this.lotSizes = await getFilters('lot_sizes', this.builderName)
    this.controls = this.controls.map((control: FormControl) => {
      if (control.id === 'size') {
        control.settings.options = this.lotSizes
      }
      return control
    })
  }

  getRecords(sources: Array<Source>): Array<Record> {
    const emptyRecord = (source: Source): Record => ({
      source,
      new: 0,
      returned: 0,
      realtors: 0,
    })

    if (!this.report || !this.report.records.length) {
      return sources.map((source: Source) => emptyRecord(source))
    }

    return sources.map((source: Source) => {
      const record: Record = this.report.records.find(
        (record: Record) => record.source.key === source.key
      )

      if (!record) {
        return emptyRecord(source)
      }
      return record
    })
  }

  async getReport(): Promise<void> {
    if (!this.isFormValid()) {
      // Setting this because the required fields in
      // advanced and normal forms are the same fields
      // we need to try to retrieve the report
      return
    }

    try {
      const data = this.formGroup.value
      this.report = (await Reports.retrieve(
        data.week_range[0],
        data.week_range[1],
        data.size
      )) as AdvancedReport

      if (this.report && this.report.id) {
        this.requiredSales = true
        this.requiredCancellations = true
      } else {
        this.requiredSales = false
        this.requiredCancellations = false
      }

      this.weekly = this.getRecords(this.salesSources)
    } catch (error) {
      console.log(error)
    }
  }

  isAbleToSubmit(): boolean {
    if (!this.isFormValid()) {
      return false
    }

    let startDate: Date
    let endDate: Date
    if (this.report) {
      startDate = new Date(this.report.start_date)
      endDate = new Date(this.report.end_date)
    } else {
      const data = this.formGroup.value
      startDate = new Date(data.week_range[0])
      endDate = new Date(data.week_range[1])
    }

    return isValidSubmissionDate(
      startDate,
      endDate,
      this.isLastWeekReportSubmissionsEnabled
    )
  }

  async create(): Promise<void> {
    if (!this.isFormValid()) {
      this.getErrors()
      return
    }

    try {
      const data: any = this.formGroup.value
      const records: Record[][] = [this.weekly]
      await Reports.create(data, TrafficReportTypes.ADVANCED, records)
      this.$toast.success('Submitted')
      uiStore.closeTrafficReport()
    } catch (error) {
      console.log(error)
    }
  }

  async update(): Promise<void> {
    if (!this.isFormValid()) {
      this.getErrors()
      return
    }

    try {
      const data: any = this.formGroup.value
      data.id = this.report.id
      const records: Array<Array<Record>> = [this.weekly]
      await Reports.update(data, TrafficReportTypes.ADVANCED, records)
      this.$toast.success('Updated')
      uiStore.closeTrafficReport()
    } catch (error) {
      console.log(error)
    }
  }

  cancel(): void {
    uiStore.closeTrafficReport()
  }
}
