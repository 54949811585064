































import { Component, Vue } from 'nuxt-property-decorator'
@Component({
  layout: 'default',
})
export default class Error extends Vue {}
