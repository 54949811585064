import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import BaseState from '~/models/state/baseState'

@Module({
  name: 'master-plan',
  stateFactory: true,
  namespaced: true,
})
export default class MasterPlan extends VuexModule implements BaseState {
  keywords: string = null

  get searchCriteria(): string {
    return this.keywords
  }

  @Action({ rawError: true })
  updateSearchCriteria(value: string) {
    this.setSearchCriteria(value)
  }

  @Mutation
  setSearchCriteria(value: string) {
    this.keywords = value
  }

  @Mutation
  clear(): void {
    this.keywords = null
  }
}
