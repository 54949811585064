




















































































import { Component, Vue, Watch, Emit } from 'nuxt-property-decorator'
import { RxFormBuilder, IFormGroup } from '@rxweb/reactive-forms'
import { mapGetters } from 'vuex'
import AdvancedTraffic from './classes/advancedTraffic'
import Reports from './classes/reports'
import Sources from './table/Sources.vue'
import Input from '~/components/shared/inputs/Input.vue'
import { InputType } from '~/enums/inputType'
import FormControl from '~/models/forms/FormControl'
import { AdvancedTrafficForm } from '~/models/forms'
import {
  FilterFields,
  Source,
  Record,
  AdvancedReport,
  TableHeader,
  Project,
  Notification,
} from '~/models'
import { retrieveSources } from '~/api/traffic.api'
import {
  SourceCategories,
  TrafficReportTypes,
  NotificationTypes,
} from '~/enums/trafficReport'
import { uiStore } from '~/store'
import { isValidSubmissionDate } from '~/utils/utils'
import { getFilters } from '~/api/filters.api'

@Component({
  components: {
    Sources,
    Input,
  },
  computed: {
    ...mapGetters('user', {
      project: 'project',
      builderName: 'builderName',
    }),
  },
})
export default class AdvancedTrafficReport extends Vue {
  // variables from store
  project: Project
  builderName: string

  // types
  inputType: typeof InputType = InputType
  sourceCategories: typeof SourceCategories = SourceCategories

  // local variables
  weekly: Array<Record> = []
  referral: Array<Record> = []
  report: AdvancedReport = null
  weeklySources: Array<Source> = []
  referralSources: Array<Source> = []
  totalVisits: number = 0
  lotSizes: Array<FilterFields> = []

  // Reactive forms
  formGroup: IFormGroup<AdvancedTrafficForm> = null
  controls: Array<FormControl> = AdvancedTrafficForm.CONTROLS

  created(): void {
    this.setForm()
    this.init()
  }

  get isLastWeekReportSubmissionsEnabled(): boolean {
    if (!this.project || !this.project.config) {
      return true
    }
    return this.project.config.enable_lastweek_report_submission
  }

  get weeklyHeaders(): Array<TableHeader> {
    return AdvancedTraffic.WEEKLY_HEADERS
  }

  get referralHeaders(): Array<TableHeader> {
    return AdvancedTraffic.REFERRAL_HEADERS
  }

  async init(): Promise<void> {
    let response: any = await retrieveSources(SourceCategories.WEEKLY)
    this.weeklySources = response.data

    response = await retrieveSources(SourceCategories.REFERRAL)
    this.referralSources = response.data
    this.fulfillData()
  }

  @Watch('formGroup.controls.week_range.value', { deep: true })
  onWeekDateRangeChanged(
    newDatesRange: Array<string>,
    oldDatesRange: Array<string>
  ): void {
    this.notify([])
    if (!newDatesRange || !newDatesRange.length) {
      return
    }

    if (!oldDatesRange || !oldDatesRange.length) {
      this.getReport()
      return
    }

    if (newDatesRange[0] === oldDatesRange[0]) {
      return
    }
    this.getReport()
  }

  @Watch('formGroup.controls.size.value', { deep: true })
  onLotSizeChanged(newSize: string, oldSize: string): void {
    this.notify([])
    if (!oldSize) {
      return
    }

    if (parseInt(newSize, 10) === parseInt(oldSize, 10)) {
      return
    }

    this.getReport()
  }

  @Watch('lotSizes', { deep: true })
  onLotSizesChanged(): void {}

  @Emit('onNotification')
  notify(notifications: Array<Notification>): Array<Notification> {
    return notifications
  }

  setForm(): void {
    this.formGroup = null

    const form: AdvancedTrafficForm = new AdvancedTrafficForm(this.report)
    if (!form) {
      return
    }
    this.setSizes()
    this.formGroup = new RxFormBuilder().formGroup(
      form
    ) as IFormGroup<AdvancedTrafficForm>
  }

  isFormValid(): boolean {
    if (!this.formGroup) {
      return false
    }
    return this.formGroup.valid
  }

  getErrors(): void {
    if (!this.formGroup.valid) {
      const allErrors: any = this.formGroup.getErrorSummary(true)
      this.notify(
        Object.values(allErrors).map((error: any) => ({
          message: error,
          type: NotificationTypes.ERROR,
        }))
      )
    }
  }

  async setSizes(): Promise<void> {
    this.lotSizes = await getFilters('lot_sizes', this.builderName)
    this.controls = this.controls.map((control: FormControl) => {
      if (control.id === 'size') {
        control.settings.options = this.lotSizes
      }
      return control
    })
  }

  getRecords(sources: Array<Source>): Array<Record> {
    const emptyRecord = (source: Source): Record => ({
      source,
      new: 0,
      returned: 0,
      realtors: 0,
    })

    if (!this.report || this.report.records.length === 0) {
      return sources.map((source: Source) => emptyRecord(source))
    }

    return sources.map((source: Source) => {
      const record: Record = this.report.records.find(
        (record: Record) => record.source.key === source.key
      )

      if (!record) {
        return emptyRecord(source)
      }
      return record
    })
  }

  async getReport(): Promise<void> {
    if (!this.isFormValid()) {
      // Setting this because the required fields in
      // advanced and normal forms are the same fields
      // we need to try to retrieve the report
      return
    }

    try {
      const data = this.formGroup.value
      const _report: AdvancedReport = (await Reports.retrieve(
        data.week_range[0],
        data.week_range[1],
        data.size
      )) as AdvancedReport

      if (!this.report) {
        this.report = _report
        if (_report.records.length !== 0) {
          this.fulfillData()
        }
        return
      }

      if (this.report.records.length === 0 && _report.records.length === 0) {
        this.report = _report
        return
      }

      this.report = _report
      this.fulfillData()
    } catch (error) {
      console.log(error)
    }
  }

  isAbleToSubmit(): boolean {
    if (!this.isFormValid()) {
      return false
    }

    let startDate: Date
    let endDate: Date
    if (this.report) {
      startDate = new Date(this.report.start_date)
      endDate = new Date(this.report.end_date)
    } else {
      const data = this.formGroup.value
      startDate = new Date(data.week_range[0])
      endDate = new Date(data.week_range[1])
    }

    return isValidSubmissionDate(
      startDate,
      endDate,
      this.isLastWeekReportSubmissionsEnabled
    )
  }

  async create(): Promise<void> {
    if (!this.isFormValid()) {
      this.getErrors()
      return
    }
    try {
      const data: any = this.formGroup.value
      const records: Record[][] = [this.weekly, this.referral]
      await Reports.create(data, TrafficReportTypes.ADVANCED, records)
      this.$toast.success('Submitted')
      uiStore.closeTrafficReport()
    } catch (error) {
      console.log(error)
    }
  }

  async update(): Promise<void> {
    if (!this.isFormValid()) {
      this.getErrors()
      return
    }

    try {
      const data: any = this.formGroup.value
      data.id = this.report.id
      const records: Array<Array<Record>> = [this.weekly, this.referral]
      await Reports.update(data, TrafficReportTypes.ADVANCED, records)
      this.$toast.success('Updated')
      uiStore.closeTrafficReport()
    } catch (error) {
      console.log(error)
    }
  }

  cancel(): void {
    uiStore.closeTrafficReport()
  }

  fulfillData(): void {
    this.weekly = this.getRecords(this.weeklySources)
    this.referral = this.getRecords(this.referralSources)
  }
}
