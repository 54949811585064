



import { Component, PropSync, Vue } from 'nuxt-property-decorator'

@Component({})
export default class Spinner extends Vue {
  @PropSync('color', { type: String }) colorValue!: string
}
