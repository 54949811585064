import { Middleware } from '@nuxt/types'
import { navigation } from '~/utils/navigationAuth'

const RouterMiddleware: Middleware = ({ app, store, route, redirect }) => {
  const role = store.getters['user/role']
  const isAdmin = store.getters['user/isAdmin']

  if (route.path === '/parcel' && role !== 'developer') {
    return !isAdmin
      ? redirect('/access_disabled')
      : redirect(navigation.find((item) => item?.allowedFor).path)
  }

  store.commit('ui/setFooterState', route.name !== 'lots')
  // @ts-ignore
  app.$intercom('update', {
    last_request_at: new Date().getTime() / 1000,
  })
}

export default RouterMiddleware
