
































































































































import { Component, Vue, Emit, Watch } from 'nuxt-property-decorator'
import { mapGetters } from 'vuex'
import Search from '~/components/shared/Search.vue'
import {
  userStore,
  authStore,
  projectStore,
  filtersStore,
  builderStore,
  uiStore,
} from '~/store'
import routes from '~/constants/routerPath.js'
import Select from '~/components/shared/Select.vue'
import { Project, User, Collaborator } from '~/models'
import Toggle from '~/components/shared/buttons/Toggle.vue'
import { toggleEmailNotifications } from '~/api/user.api'
import {
  ACCESS_DISABLED_ROUTE,
  navigation as NavigationRoutes,
} from '~/utils/navigationAuth'
import { ProjectError } from '~/utils/exceptions'

@Component({
  components: {
    Search,
    Select,
    Toggle,
  },
  computed: {
    ...mapGetters('user', {
      id: 'id',
      role: 'role',
      first_name: 'first_name',
      email: 'email',
      builderName: 'builderName',
      projects: 'projects',
      emailNotifications: 'emailNotifications',
      collaborators: 'collaborators',
      isAbleToEdit: 'isAbleToEdit',
      isBuilder: 'isBuilder',
    }),
    ...mapGetters('project', {
      activeProject: 'activeProject',
    }),
    ...mapGetters('builder', {
      activeBuilder: 'activeBuilder',
    }),
  },
})
export default class Header extends Vue {
  dropdownOpen: boolean = false
  projects: Project[]
  activeProject: number
  role: string
  first_name: string
  email: string
  emailNotifications: boolean
  collaborators: Collaborator[]
  activeBuilder: number
  id: number
  builderName: string
  isAbleToEdit: boolean
  userLoading: boolean = true

  async created() {
    try {
      uiStore.closeTrafficReport()
      await userStore.getUserData() // Fetch user data
      this.userLoading = false // Set loading to false once data is loaded
    } catch (error) {
      console.error('Error loading user data:', error)
      this.userLoading = false // Ensure loading is set to false on error as well
    }
  }

  @Emit()
  toggleNav(): boolean {
    return true
  }

  async logout(): Promise<void> {
    try {
      await authStore.logout()
    } catch (error) {
      console.log(error)
    } finally {
      this.$router.push(routes.login)
    }
  }

  get userProjects(): Project[] {
    return this.projects
  }

  get builderList(): Collaborator[] {
    let builderList: Collaborator[] = []
    if (this.collaborators.length) {
      builderList = this.collaborators.map((collaborator: Collaborator) => ({
        id: collaborator.id,
        name: collaborator.name,
      }))
      builderList.unshift({ id: this.id, name: this.builderName })
    }
    return builderList
  }

  get initialBuilder(): number {
    return this.activeBuilder
  }

  get initialProject(): number {
    return this.activeProject
  }

  get emailNotificationsStatus(): boolean {
    return this.emailNotifications
  }

  @Watch('role', { immediate: true })
  onRoleChange(newRole: string) {
    if (newRole) {
      this.$nextTick(() => {
        this.$forceUpdate() // Force component refresh
      })
    }
  }

  async onChangeProject(project: Project): Promise<void> {
    try {
      const projectData = { id: project.id }
      projectStore.changeActiveProject(projectData)
      await userStore.getUserData()
      filtersStore.clear()
      const redirectRoute = userStore.redirectUrlByProject
      if (ACCESS_DISABLED_ROUTE === redirectRoute) {
        this.$router.push(redirectRoute)
      }
      if (this.$router.currentRoute.name === 'lots-slug') {
        this.$router.push(
          NavigationRoutes.find((nav) => nav.id === 'xo_map').path
        )
        return
      }
      if (this.$router.currentRoute.name === 'plans-slug') {
        this.$router.push(
          NavigationRoutes.find((nav) => nav.id === 'master_plans').path
        )
        return
      }
      this.$router.go(0)
    } catch (error) {
      if (error instanceof ProjectError) {
        this.$toast.error(error.message)
        this.$router.push(ACCESS_DISABLED_ROUTE)
      } else {
        console.log(error)
      }
    }
  }

  onChangeBuilder(builder: Collaborator): void {
    builderStore.changeActiveBuilder(builder.id)
    const activeRoute = this.$router.currentRoute
    filtersStore.clear()
    if (activeRoute.path !== routes.index) {
      this.$router.push(routes.index)
    }
  }

  async onEmailNotificationsToggle(enable: boolean): Promise<void> {
    try {
      const formData = new FormData()
      formData.append('enable', enable as any)
      const user: User = await toggleEmailNotifications(formData)
      userStore.setUser(user)
    } catch (exception) {
      throw new Error(exception)
    }
  }

  openTrafficReport() {
    uiStore.openTrafficReport()
  }
}
