



















import { Component, Vue, Emit, Prop } from 'nuxt-property-decorator'
import TextInput from '../shared/inputs/TextInput.vue'
import MapDropdownFilter from '~/components/sales_map_v3/MapDropdownFilter.vue'

@Component({
  components: {
    MapDropdownFilter,
    TextInput,
  },
})
export default class RentalPriceFilter extends Vue {
  @Prop({ default: '' }) title: String
  @Prop({ default: '' }) openedMenu: String
  @Prop({ default: (): any => { } }) priceMin: any
  @Prop({ default: (): any => { } }) priceMax: any
  @Prop({ default: 1000 }) minimumAvailableValue: number
  @Prop({ default: 10000 }) maximumAvailableValue: number

  step: number = 250
  inputMaskRe: RegExp = /(\d\d\d)(?=[^$])/g
  replacement: string = '$1,'

  applyMoneyMask(value: number) {
    return value.toString().split('').reverse().join('').replace(this.inputMaskRe, this.replacement).split('').reverse().join('')
  }

  get priceMinValue() {
    return this.priceMin ? this.priceMin.value : this.minimumAvailableValue
  }

  get maskedPriceMinValue() {
    return this.applyMoneyMask(this.priceMinValue)
  }

  get priceMaxValue() {
    return this.priceMax ? this.priceMax.value : this.maximumAvailableValue
  }

  get maskedPriceMaxValue() {
    return this.applyMoneyMask(this.priceMaxValue)
  }

  @Emit('updatePriceMaxFilter')
  updatePriceMaxFilterByRange(e: any) {
    let targetValue = Number(e.target.value)
    if (targetValue <= this.priceMinValue) {
      return this.priceMinValue + this.step
    }
    return targetValue
  }

  @Emit('updatePriceMinFilter')
  updatePriceMinFilterByRange(e: any) {
    let targetValue = Number(e.target.value)
    if (targetValue >= this.priceMaxValue) {
      return this.priceMaxValue - this.step
    }
    return targetValue
  }

  @Emit('updatePriceMinFilter')
  updatePriceMinFilter(e: any) {
    return Number(e.target.value.replace(',', ''))
  }

  @Emit('updatePriceMaxFilter')
  updatePriceMaxFilter(e: any) {
    return Number(e.target.value.replace(',', ''))
  }

  handleChangeMenu(e: any) {
    e ? this.$emit('changeMenu', this.title) : this.$emit('changeMenu', '')
  }
}
