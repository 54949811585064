import Document from '~/models/document'
import { $axios } from '~/utils/api'

const END_POINT = '/media'

const postMedia = (form: FormData): Promise<Document> =>
  $axios.$post(END_POINT, form)

const putMedia = (id: number, form: FormData): Promise<Document> =>
  $axios.$put(`${END_POINT}/${id}`, form)

const deleteMedia = (id: number, lot_id?: number): Promise<Document> => {
  let url: string = `${END_POINT}/${id}`
  if (lot_id) {
    url = `${url}?lot_id=${lot_id}`
  }
  return $axios.$delete(url)
}

// tengo que agregar un GET aqui para usarlo cuando cargo los files como additional files
const getMedia = (id: number): Promise<Document> =>
  $axios.$get(END_POINT + `/${id}`)

export { postMedia, putMedia, deleteMedia, getMedia }
