import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { LotInfo, RentalUnit } from '~/models'
import { PublicProject } from '~/models/project'

@Module({
  name: 'rental-map',
  stateFactory: true,
  namespaced: true,
})
export default class RentalMap extends VuexModule {
  activeView: 'map' | 'grid' = 'map'
  propertiesCount: number = 0
  totalHomesFiltered: number = 0
  totalHomeSitesFiltered: number = 0
  plansCount: number = 0
  totalPlansFiltered: number = 0
  filterVisibilitySettings: Object = {}
  debouncedFilteredRequests: Function = null
  currentProject: PublicProject = null
  lotInfo: LotInfo = null
  rentalInfo: RentalUnit = null
  filterParams: any = null
  loadingRentals: boolean = false
  loadingHomeSites: boolean = false
  parentPageBaseURL: string = ''

  get propertiesShown(): number {
    return this.totalHomesFiltered + this.totalHomeSitesFiltered || 0
  }

  get currentRentalProject(): PublicProject {
    return this.currentProject
  }

  @Action({ rawError: true })
  handleFilterChange(filters: any[]): void {
    this.debouncedFilteredRequests(filters)
  }

  @Mutation
  setDebouncedFilteredRequests(debounce: Function) {
    this.debouncedFilteredRequests = debounce
  }

  @Mutation
  setPropertiesCount(properties: number) {
    this.propertiesCount = properties
  }

  @Mutation
  setTotalHomeSitesFiltered(totalHomeSitesFiltered: number) {
    this.totalHomeSitesFiltered = totalHomeSitesFiltered
  }

  @Mutation
  setTotalHomesFiltered(totalHomesFiltered: number) {
    this.totalHomesFiltered = totalHomesFiltered
  }

  @Mutation
  setPlansCount(plans: number) {
    this.plansCount = plans
  }

  @Mutation
  setTotalPlansFiltered(totalPlansFiltered: number) {
    this.totalPlansFiltered = totalPlansFiltered
  }

  @Mutation
  setFilterVisibilitySettings(filterVisibilitySettings: Object) {
    this.filterVisibilitySettings = filterVisibilitySettings
  }

  @Mutation
  setCurrentProject(project: PublicProject) {
    this.currentProject = project
  }

  @Mutation
  setRentalInfo(rentalInfo: RentalUnit) {
    this.rentalInfo = rentalInfo
  }

  @Mutation
  setFilterParams(filterParams: any) {
    this.filterParams = filterParams
  }

  @Mutation
  setLoadingRentals(loadingRentals: boolean) {
    this.loadingRentals = loadingRentals
  }

  @Mutation
  setLoadingHomeSites(loadingHomeSites: boolean) {
    this.loadingHomeSites = loadingHomeSites
  }

  @Mutation
  setParentPageBaseURL() {
    let parentPageBaseURL = new window.URLSearchParams(
      window.location.search
    ).get('parent_page_base_url')

    /*
      Allowing parentPageBaseURL to be reset to null/undefined would make it so that
      the app must pass the base url on every view change. If you are trying to test this
      you have to manually empty your local storage to clear.
      
      The client can pass in their base url with or without a trailing slash.

      A #/ will be appended before our own urls to prevent the browser going directly
      to that end point on the client server, and instaed passing that information to our iframe.
    */
    if (parentPageBaseURL) {
      if (parentPageBaseURL.charAt(parentPageBaseURL.length - 1) !== '/') {
        parentPageBaseURL += '/'
      }
      this.parentPageBaseURL = parentPageBaseURL
    }
  }

  @Mutation
  clear() {}
}
