



























import { Component, mixins, Emit, Prop } from 'nuxt-property-decorator'
import MapDropdownFilter from '~/components/sales_map_v3/MapDropdownFilter.vue'
import ButtonGroupV3 from '~/components/sales_map_v3/ButtonGroupV3.vue'
import globalStyling from '~/mixins/globalStyling'

@Component({
  components: {
    MapDropdownFilter,
    ButtonGroupV3,
  },

  mixins: [globalStyling],
})
export default class BedsBathsFilter extends mixins(globalStyling) {
  @Prop({ default: '' }) title: String
  @Prop({ default: '' }) openedMenu: String
  @Prop({ default: (): any[] => [] }) bedRoomsOptions: any[]
  @Prop({ default: (): any => {} }) selectedBedRooms: any
  @Prop({ default: (): any[] => [] }) bathsOptions: any[]
  @Prop({ default: (): any => {} }) selectedBaths: any

  @Emit('updateRoomsFilter')
  updateRoomsFilter() {}

  @Emit('updateBathsFilter')
  updateBathsFilter() {}

  handleChangeMenu(e: any) {
    e ? this.$emit('changeMenu', this.title) : this.$emit('changeMenu', '')
  }
}
