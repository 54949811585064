import {
  prop,
  propObject,
  required,
  minNumber,
  maxNumber,
  numeric,
  NumericValueType,
  email,
} from '@rxweb/reactive-forms'
import MaterialForm from './materialForm'
import FormStep from './FormStep'
import { InputType } from '~/enums/inputType'
import { LabelSizes } from '~/enums/labelSizes'
import { Document } from '~/models'

type StepsData = {
  showSupplementalFields?: boolean
  documents?: Array<Document>
  showVariance: boolean
  isVarianceSelected?: boolean
}

export default class SpecForm {
  @required({ message: 'no elevation selected' })
  elevation_id: number | null

  @required({ message: 'Please fill out this date to proceed' })
  construction_date: string

  @required({ message: 'Please fill out this date to proceed' })
  available_on: string

  @maxNumber({ value: 10, message: 'Beds must be less than 10' })
  @minNumber({ value: 1, message: 'Beds must be greater than 0' })
  @required({ message: 'Beds is a required field' })
  listing_beds: number

  @maxNumber({ value: 10, message: 'Baths must be less than 10' })
  @minNumber({ value: 0.5, message: 'Baths must be greater than 0' })
  @required({ message: 'Baths is a required field' })
  listing_baths: number

  @numeric({
    acceptValue: NumericValueType.PositiveNumber,
    allowDecimal: false,
    message: 'Garage do not accept decimal values',
  })
  @maxNumber({ value: 10, message: 'Garage must be less than 10' })
  @minNumber({ value: 1, message: 'Garage must be greater than 0' })
  @required({ message: 'Garage is a required field' })
  listing_garage: number

  @maxNumber({
    value: 10000,
    message: 'SQFT amount must be less than 10,000',
  })
  @minNumber({
    value: 500,
    message: 'SQFT amount must be greater than 500',
  })
  @required({ message: 'SQFT is a required field' })
  listing_sqft: number

  @numeric({
    acceptValue: NumericValueType.PositiveNumber,
    allowDecimal: false,
    message: 'Price must be greater than 0',
  })
  @maxNumber({
    value: 5000000,
    message: 'Price amount must be less than $5,000,000',
  })
  @required({ message: 'Price is a required field' })
  listing_price: number

  @required({ message: 'Stories is a required field' })
  listing_stories: number

  @required()
  @propObject()
  materials: MaterialForm

  @prop()
  upgrades_ids: number[]

  @required()
  files_ids: number[]

  @prop()
  custom: any[]

  @prop() variance: boolean

  @prop()
  features: Array<string> = []

  @prop()
  to_model: boolean

  @prop() variance_name: string
  @prop() variance_phone: string
  @email({ message: 'Provide a valid email' })
  variance_email: string

  @prop() variance_reason: string

  constructor() {
    this.materials = new MaterialForm()
    this.upgrades_ids = []
  }

  /**
   * Returns SPEC form steps.
   *  if show supplemental fields is true,
   *  it returns next steps: [Overview, Plan/Elevation, Additions, Materials, Additional Detail, Uploads]
   *  if not it returns: [Overview, Plan/Elevation, Additions, Materials, Uploads]
   * @param showSupplementalFields boolean
   * @returns FormStep[...]
   */
  getSteps(stepsData?: StepsData): FormStep[] {
    const steps: FormStep[] = [
      {
        title: 'Overview',
        step: 1,
        valid: false,
        controls: [
          {
            id: 'construction_date',
            type: InputType.date,
            label: 'Construction Date',
            parentClass: 'w-1/2 pr-6',
            required: true,
            settings: {
              description: 'Date home expected to start construction',
              titleSize: LabelSizes.extraLarge,
            },
          },
          {
            id: 'available_on',
            type: InputType.date,
            label: 'Available On Date',
            parentClass: 'w-1/2 pl-6',
            required: true,
            settings: {
              description: 'Date home will be ready for move-in',
              titleSize: LabelSizes.extraLarge,
            },
          },
          {
            id: 'to_model',
            type: InputType.toggle,
            label: 'Model Submittal',
            parentClass: 'w-1/2 pt-4',
            required: false,
            settings: {
              prefix: 'No',
              suffix: 'Yes',
              description: '',
              titleSize: LabelSizes.extraLarge,
            },
          },
          {
            id: 'features',
            label: 'Spec Home Features',
            type: InputType.text,
            parentClass: 'w-full pt-6',
            required: false,
            settings: {
              description:
                'Enter the unique features of this spec home that will display to consumers on the map.',
              titleSize: LabelSizes.extraLarge,
              isTextArea: true,
              isFeatureList: true,
            },
          },
        ],
      },
      {
        title: 'Plan/Elevation',
        step: 2,
        valid: false,
        controls: [
          {
            id: 'variance',
            label: 'Variance Request',
            type: InputType.toggle,
            parentClass: 'w-full flex justify-end absolute top-1 right-4',
            required: false,
            settings: {
              titleSize: LabelSizes.small,
              prefix: 'No',
              suffix: 'Yes',
              textSize: LabelSizes.small,
              visible: stepsData?.showVariance,
            },
          },
          {
            id: 'elevation_id',
            label: 'Plan',
            type: InputType.elevations,
            parentClass: 'w-full',
            required: true,
            settings: {
              options: [],
              titleSize: LabelSizes.extraLarge,
              description: 'Select elevation to construct.',
            },
          },
        ],
      },
      {
        title: 'Details',
        step: 3,
        valid: false,
        controls: [
          {
            id: 'listing_stories',
            type: InputType.buttonSelect,
            label: 'Stories',
            parentClass: 'w-full',
            required: true,
            settings: {
              description: 'Select the number of stories.',
              titleSize: LabelSizes.extraLarge,
              options: [
                {
                  value: 1,
                  label: '1',
                },
                {
                  value: 2,
                  label: '2',
                },
                {
                  value: 3,
                  label: '3',
                },
              ],
            },
          },
          {
            id: 'listing_beds',
            type: InputType.number,
            label: 'Beds',
            parentClass: 'w-1/3 pr-6',
            required: true,
            settings: {
              description: 'Enter the number of bedrooms.',
              titleSize: LabelSizes.extraLarge,
            },
          },
          {
            id: 'listing_baths',
            type: InputType.number,
            label: 'Baths',
            parentClass: 'w-1/3 pr-6',
            required: true,
            settings: {
              description: 'Enter the number of bathrooms.',
              titleSize: LabelSizes.extraLarge,
              step: 0.5,
            },
          },
          {
            id: 'listing_garage',
            type: InputType.number,
            label: 'Garage',
            parentClass: 'w-1/3',
            required: true,
            settings: {
              description: 'Enter the number of garage spaces.',
              titleSize: LabelSizes.extraLarge,
            },
          },
          {
            id: 'listing_sqft',
            type: InputType.number,
            label: 'SqFt',
            parentClass: 'w-1/2 pt-6 pr-6',
            required: true,
            settings: {
              description: 'Enter the square footage.',
              titleSize: LabelSizes.extraLarge,
            },
          },
          {
            id: 'listing_price',
            type: InputType.currency,
            label: 'Price',
            parentClass: 'w-1/2 pt-6',
            required: true,
            settings: {
              description: 'Enter the price.',
              titleSize: LabelSizes.extraLarge,
            },
          },
        ],
      },
      {
        title: 'Materials',
        step: 4,
        valid: false,
        controls: [
          {
            id: 'materials',
            type: null,
            nested: true,
            label: 'materials',
            controls: this.materials.getControls(stepsData.isVarianceSelected),
            parentClass: 'w-full',
            required: true,
          },
        ],
      },
    ]

    let step = steps.length + 1
    if (stepsData && stepsData.showSupplementalFields) {
      steps.push(this.getAdditionalDetailsStep(step))
      step++
    }
    steps.push(this.getUploadsStep(step, stepsData))
    return steps
  }

  getAdditionalDetailsStep(page: number): FormStep {
    return {
      title: 'Additional Detail',
      step: page,
      valid: false,
      controls: [],
    }
  }

  getUploadsStep(page: number, stepsData: StepsData): FormStep {
    return {
      title: 'Uploads',
      step: page,
      valid: false,
      controls: [
        {
          id: 'variance_name',
          label: 'Variance Name',
          type: InputType.text,
          parentClass: 'w-full pb-2',
          required: true,
          settings: {
            validators: [],
            description:
              'Enter the full name for the review team to understand who to contact for your request',
            preview: true,
            disabled: false,
            visible: false,
          },
        },
        {
          id: 'variance_phone',
          label: 'Variance Phone',
          type: InputType.text,
          parentClass: 'w-full pb-2',
          required: true,
          settings: {
            validators: [],
            description:
              'Enter the phone number for the review team to understand how to contact for your request',
            preview: true,
            disabled: false,
            visible: false,
            isPhoneNumber: true,
          },
        },
        {
          id: 'variance_email',
          label: 'Variance Email',
          type: InputType.text,
          parentClass: 'w-full pb-2',
          required: true,
          settings: {
            validators: [],
            description:
              'Enter the email for the review team to understand how to contact for your request',
            preview: true,
            disabled: false,
            visible: false,
          },
        },
        {
          id: 'variance_reason',
          label: 'Variance Explanation',
          type: InputType.text,
          parentClass: 'w-full pb-2',
          required: true,
          settings: {
            validators: [],
            description:
              'Enter the explanation for the review team to understand your request',
            preview: true,
            disabled: false,
            isTextArea: true,
            visible: false,
          },
        },
        {
          id: 'files_ids',
          label: 'Documents',
          type: InputType.fileUpload,
          parentClass: 'w-full',
          required: true,
          settings: {
            accept: '.pdf',
            files: stepsData ? stepsData.documents : [],
            description:
              "Upload all plans required by your community's Developer and/or Review Committee",
            titleSize: LabelSizes.extraLarge,
            category: 'document',
            hasRequiredDocuments: true,
            isSubcategoryRequired: false,
          },
        },
      ],
    }
  }
}
