enum MiscSubmittalStatus {
  APPROVED = 'approved',
  DENIED = 'denied',
  PENDING = 'pending',
}

enum EventTypes {
  CREATED = 'created',
  UPDATED = 'updated',
  REMOVED = 'removed',
  REVIEWED = 'reviewed',
}

enum Events {
  MISC_SUBMITTAL_EVENT = 'misc_submittal_event',
}

export { MiscSubmittalStatus, Events, EventTypes }
