export enum TrafficReportTypes {
  NORMAL = 'normal',
  ADVANCED = 'advanced',
  SALES = 'sales',
}

export enum SourceCategories {
  WEEKLY = 'weekly',
  REFERRAL = 'referral',
  SALES = 'sales',
}

export const enum NotificationTypes {
  ERROR = 'error',
  SUCCESS = 'success',
}
