































import { Component, Emit, Prop, Vue, Watch } from 'nuxt-property-decorator'

@Component({})
export default class Toggle extends Vue {
  @Prop({ default: false }) enabled: boolean

  isEnabled: boolean

  constructor() {
    super()
    this.isEnabled = this.enabled
  }

  @Watch('enabled')
  onEnakedchanged(enabled: boolean): void {
    this.isEnabled = enabled
  }

  @Emit('click')
  handleClick(event: any): boolean {
    event.preventDefault()
    event.stopPropagation()

    this.isEnabled = !this.isEnabled
    return this.isEnabled
  }
}
