





































































import { mixins, Component } from 'nuxt-property-decorator'
import globalStyling from '~/mixins/globalStyling'
import { ConsumerMapRoutes } from '~/enums/consumerMap'
import { getLocalStorageItem, storageAvailable } from '~/utils/localStorage'
import { rentalFavoritesStore } from '~/store'

@Component({
  name: 'MobileNavBar',
  mixins: [globalStyling],
})
export default class MobileNavBar extends mixins(globalStyling) {
  isShareWithMeVisible: boolean = false

  mounted() {
    this.isShareWithMeVisible =
      storageAvailable() && !!getLocalStorageItem('shared-with-me')
  }

  getActiveRoute(): string {
    return this.$route.path.split('/').pop()
  }

  get isFavoritesOrSharedRoute(): boolean {
    return (
      this.getActiveRoute() === ConsumerMapRoutes.FAVORITES ||
      this.getActiveRoute() === ConsumerMapRoutes.SHARED
    )
  }

  get isFavoritesRoute(): boolean {
    return (
      this.getActiveRoute() === ConsumerMapRoutes.FAVORITES &&
      !this.isShareWithMeVisible
    )
  }

  get favCount() {
    return rentalFavoritesStore.favCount
  }
}
