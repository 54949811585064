import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import BaseState from '~/models/state/baseState'

@Module({
  name: 'ui',
  stateFactory: true,
  namespaced: true,
})
export default class Ui extends VuexModule implements BaseState {
  loading: boolean = null
  navState: boolean = null
  filtering: boolean = null
  materialsLoader: boolean = false
  footerState: boolean = true
  elevationsLoading: boolean = false
  trafficReportState: boolean = false
  isListingsVisible: boolean = true

  get isLoading() {
    return this.loading
  }

  get isMaterialsLoding() {
    return this.materialsLoader
  }

  get isFiltering() {
    return this.filtering
  }

  get showFooter() {
    return this.footerState
  }

  get areElevationsLoading(): boolean {
    return this.elevationsLoading
  }

  get showTrafficReport(): boolean {
    return this.trafficReportState
  }

  @Action({ rawError: true })
  openTrafficReport() {
    this.setTrafficReportState(true)
  }

  @Action({ rawError: true })
  closeTrafficReport() {
    this.setTrafficReportState(false)
  }

  @Action({ rawError: true })
  updateLoding(loading: boolean) {
    this.setLoading(loading)
  }

  @Action({ rawError: true })
  updateFilteringLoding(loading: boolean) {
    this.setFiltering(loading)
  }

  @Action({ rawError: true })
  updateMaterialsLoader(loading: boolean) {
    this.setMaterialLoader(loading)
  }

  @Action({ rawError: true })
  updatingElevations(loading: boolean) {
    this.setUpdatingElevations(loading)
  }

  @Mutation
  setFooterState(footer: boolean) {
    this.footerState = footer
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading
  }

  @Mutation
  setNavState(nav: boolean) {
    this.navState = nav
  }

  @Mutation
  setFiltering(loading: boolean) {
    this.filtering = loading
  }

  @Mutation
  setMaterialLoader(loading: boolean) {
    this.materialsLoader = loading
  }

  @Mutation
  setUpdatingElevations(loading: boolean) {
    this.elevationsLoading = loading
  }

  @Mutation
  setTrafficReportState(show: boolean) {
    this.trafficReportState = show
  }

  @Mutation
  setIsListingsVisible(visible: boolean) {
    this.isListingsVisible = visible
  }

  @Mutation
  clear() {
    this.loading = null
    this.navState = null
    this.filtering = null
    this.footerState = true

    this.materialsLoader = false
    this.elevationsLoading = false
    this.trafficReportState = false
    this.isListingsVisible = true
  }
}
