






























import { Component, Prop, Emit } from 'nuxt-property-decorator'
import BaseInputComponent from './BaseInput.vue'

@Component({})
export default class RichTextField extends BaseInputComponent {
  content: string = ''
  customToolbar: Array<Array<any>> = [
    ['bold', 'italic', 'underline', 'strike'],

    [
      { align: '' },
      { align: 'center' },
      { align: 'right' },
      { align: 'justify' },
    ],

    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],

    [{ indent: '-1' }, { indent: '+1' }],

    [{ color: [] }, { background: [] }],

    ['clean'],
  ]

  mounted() {
    this.content = this.value || ''
  }

  @Prop({ default: (): string => null })
  prefix: string

  @Emit('input')
  handleInput(event: any): string | number | boolean | any {
    return event
  }

  @Emit('validateField')
  handleBlur(): any {}
}
