import { Middleware } from '@nuxt/types'

const clearState = (store: any) => {
  Object.keys(store.state).forEach((module) => {
    store.commit(`${module}/clear`)
  })
}

const StoreMiddleware: Middleware = ({ store }) => {
  clearState(store)
}

export default StoreMiddleware
