


































































import { Component, Emit, Prop, Watch } from 'nuxt-property-decorator'
import BaseInputComponent from './BaseInput.vue'

@Component({
  components: {},
})
export default class DateRangeInput extends BaseInputComponent {
  @Prop({ default: 'MM/dd/yyyy' })
  format: string

  @Prop({ default: 'left' })
  opens: string

  @Prop({ default: 'range' })
  singleDatePicker: string

  @Prop({ default: false })
  weekRange: boolean

  @Prop({ default: true })
  showSideRanges: boolean

  dateRange: any = {}

  mounted() {}

  @Watch('value')
  onValueChanged() {
    this.dateRange = this.value
      ? {
          startDate: this.value[0],
          endDate: this.value[1],
        }
      : { startDate: null, endDate: null }
  }

  @Emit('input')
  handleInput(date: any): any[] {
    if (this.weekRange) {
      this.select(date)
    }

    if (this.dateRange.startDate && this.dateRange.endDate) {
      let startDate: string = this.$dateFns.format(
        this.dateRange.startDate,
        this.format
      )

      let endDate: string = this.$dateFns.format(
        this.dateRange.endDate,
        this.format
      )

      if (this.weekRange) {
        const ranges: any = this.weekRangeDates(new Date(startDate))
        this.dateRange = ranges

        startDate = this.$dateFns.format(ranges.startDate, this.format)
        endDate = this.$dateFns.format(ranges.endDate, this.format)
      }

      return [startDate, endDate]
    }
  }

  @Emit('validateField')
  handleBlur(): any {}

  weekRangeDates(date: Date): any {
    const weekDay: number = date.getDay()

    let startDate: any = date.setDate(date.getDate() - weekDay + 1)
    startDate = new Date(startDate)

    let endDate: any = date.setDate(startDate.getDate() + 6)
    endDate = new Date(endDate)

    return { startDate, endDate }
  }

  onUpdate(ranges: any) {
    this.handleInput(ranges.startDate)
  }

  select(date: Date): void {
    if (this.weekRange) {
      let startDate: any = this.dateRange?.startDate
      let endDate: any = this.dateRange?.endDate

      if (startDate && endDate) {
        startDate = new Date(startDate)
        endDate = new Date(endDate)
        if (
          date.getDate() > startDate.getDate() &&
          date.getDate() < endDate.getDate()
        ) {
          this.dateRange = {
            startDate,
            endDate,
          }
          return
        }
      }
      const ranges = this.weekRangeDates(date)
      this.dateRange = ranges
    }
  }

  @Emit('input')
  clearDate(): void {
    this.dateRange.startDate = null
    this.dateRange.endDate = null
    return null
  }
}
