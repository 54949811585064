export enum SaleStatus {
  PRE = 'pre',
  CLOSED = 'closed',
  CANCELLED = 'cancelled',
  CONTRACT = 'contract',
}

export enum SaleReviewStatus {
  APPROVED = 'approved',
  DENIED = 'denied',
}
