export default ({ app, store }) => {
  // Watch for changes to the active project
  store.watch(
    (state) => state.project.currentProject, // Assuming this is where your project data is stored
    (newProject) => {
      if (newProject && newProject.google_tag_manager_id) {
        // Override the GTM ID in the runtime config
        app.$config.gtm.id = newProject.google_tag_manager_id

        // Remove the existing GTM script if it does not match the new GTM ID
        const existingScript = document.querySelector(
          `script[src*="gtm.js?id="]:not([src*="${newProject.google_tag_manager_id}"])`
        )
        if (existingScript) {
          existingScript.remove()
        }

        // Inject the new GTM script with the correct ID
        const script = document.createElement('script')
        script.src = `https://www.googletagmanager.com/gtm.js?id=${newProject.google_tag_manager_id}`
        script.async = true
        document.head.appendChild(script)

        // Add the noscript fallback
        const noscript = document.createElement('noscript')
        noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${newProject.google_tag_manager_id}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
        document.body.appendChild(noscript)
      }
    },
    { immediate: true } // Trigger the watcher immediately
  )
}
