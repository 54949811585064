













import { Component, Prop, mixins } from 'nuxt-property-decorator'
import globalStyling from '~/mixins/globalStyling'

@Component({
    mixins: [globalStyling],
})
export default class XofYCounter extends mixins(globalStyling) {
    @Prop({ default: 0 }) current: number
    @Prop({ default: 0 }) total: number
    @Prop({ default: '' }) name: string
}
