













import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
@Component({})
export default class IconButton extends Vue {
  @Prop({ default: false })
  disabled: boolean

  @Emit('click')
  onClick(event: MouseEvent): MouseEvent {
    return event
  }
}
