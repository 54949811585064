




























import { Component, Vue, Emit } from 'nuxt-property-decorator'
import { setNotificationTime } from '~/api/user.api'
import { userStore } from '~/store'

@Component({})
export default class NotificationDialog extends Vue {
  setNotificationDateTime: Function

  notficationTime: string = '30'
  options: any = [
    {
      label: '30 min',
      value: '30',
    },
    {
      label: '1 hour',
      value: '60',
    },
    {
      label: '3 hours',
      value: '180',
    },
  ]

  onRemindLater(): void {
    const addZero = (value: string) =>
      value.length === 1 ? `0${value}` : value

    try {
      const today: Date = new Date()
      today.setMinutes(today.getMinutes() + parseInt(this.notficationTime))

      const hours: string = today.getHours().toString()
      const minutes: string = today.getMinutes().toString()

      const formatedDate: string = `${today.getFullYear()}-${addZero(
        (today.getMonth() + 1).toString()
      )}-${today.getDate()}`

      const formated = `${formatedDate} ${addZero(hours)}:${addZero(
        minutes.toString()
      )}:00`

      setNotificationTime({ notification: formated })
      userStore.setNotificationDateTime(formated)
    } catch (error) {
      console.log(error)
    } finally {
      this.close()
    }
  }

  @Emit('onClose')
  close(): void {}
}
