import { prop, required } from '@rxweb/reactive-forms'
import { MiscSubmittal } from '../miscSubmittal'
import FormControl from './FormControl'
import { InputType } from '~/enums/inputType'

export default class MiscSubmittalForm {
  @required() name: string
  @required() type: number
  @prop() phase: string
  @prop() builder: number
  @prop() comments: string
  @prop() files: Array<number>

  constructor(miscSubmittal: MiscSubmittal) {
    if (miscSubmittal) {
      this.name = miscSubmittal.name
      this.type = miscSubmittal.type.id
      this.phase = miscSubmittal.phase
      this.comments = miscSubmittal.comments
      this.builder = miscSubmittal?.builder?.id
      this.files = miscSubmittal?.documents
        ?.filter((document) => document.is_autogenerated === false)
        .map((document) => document.id)
    }
  }

  static CONTROLS: Array<FormControl> = [
    {
      label: 'Submission Name',
      id: 'name',
      type: InputType.text,
      parentClass: 'w-full mb-2',
      required: true,
      settings: {},
    },
    {
      label: 'Type',
      id: 'type',
      type: InputType.select,
      parentClass: 'w-full mb-2',
      required: true,
      settings: {
        description: 'Select the type of submittal',
        options: [],
      },
    },
    {
      label: 'Phase',
      id: 'phase',
      type: InputType.select,
      parentClass: 'w-full mb-2',
      required: false,
      settings: {
        description: 'Select the phase',
        options: [],
      },
    },
    {
      label: 'Builder',
      id: 'builder',
      type: InputType.select,
      parentClass: 'w-full mb-2',
      required: false,
      settings: {
        description: 'Select the builder',
        options: [],
      },
    },
    {
      label: 'Comments',
      id: 'comments',
      type: InputType.text,
      parentClass: 'w-full mb-2',
      required: false,
      settings: {
        description:
          'include any additional comments to assist the review team with their review',
        isTextArea: true,
      },
    },
    {
      label: 'Include a File',
      id: 'files',
      type: InputType.fileUpload,
      parentClass: 'w-full',
      required: false,
      settings: {
        description:
          'Upload any additional feedback to further assist builder with approval',
      },
    },
  ]
}
