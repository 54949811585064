import { prop, required } from '@rxweb/reactive-forms'
import FormControl from './FormControl'
import { InputType } from '~/enums/inputType'
import { userStore } from '~/store'
import { Project } from '~/models'

export default class MaterialForm {
  @required({ message: 'no primary material selected' })
  primary_material_id: number | null

  @prop()
  secondary_materials_ids: number[] | number

  @prop()
  roof_material_id: number | null

  @prop()
  trim_color_id: number | null

  @prop()
  door_color_id: number | null

  get isMatrixMaterials(): boolean {
    const project = userStore.project
    if (!project || !project.config) {
      return false
    }

    return project.config.secondary_material_matrix
  }

  get secondaryMaterialToolTipMessage() {
    if (this.isMatrixMaterials) {
      return 'Select primary material first'
    }
    return 'Unfortunately, you have no materials assigned for this material type. Please reach out to XO Help if you would like to add materials to your selections'
  }

  get project(): Project {
    return userStore.project
  }

  getControls(isVarianceSelected: boolean = false): FormControl[] {
    return [
      {
        id: 'primary_material_id',
        label: 'Primary Exterior Color',
        type: InputType.materials,
        required: true,
        settings: {
          isVarianceSelected,
          showSelectors: true,
          description:
            "Select home's primary material (use default or select alternate)",
          filterMaterials: false,
          options: [],
          enabled: true,
          toolTipMessage: '',
          filterByCategory: false,
          clearOnParentChanged: false,
        },
      },
      {
        id: 'secondary_materials_ids',
        label: 'Secondary Exterior Color',
        type: InputType.materials,
        required: userStore.isSpecialFeatureEnabled,
        settings: {
          isVarianceSelected,
          showSelectors: !userStore.isSpecialFeatureEnabled,
          description: userStore.isSpecialFeatureEnabled
            ? ''
            : "Select home's secondary material (use default or select alternate)",
          filterMaterials: false,
          options: [],
          enabled: !this.isMatrixMaterials,
          toolTipMessage: this.secondaryMaterialToolTipMessage,
          filterByCategory: !this.isMatrixMaterials,
          clearOnParentChanged: this.isMatrixMaterials,
        },
      },
      {
        id: 'roof_material_id',
        label: 'Roof Exterior Color',
        type: InputType.materials,
        required: false,
        settings: {
          description: "Select the home's roof color",
          options: [],
          enabled: true,
          toolTipMessage:
            'Unfortunately, you have no materials assigned for this material type. Please reach out to XO Help if you would like to add materials to your selections',
          filterByCategory: false,
          clearOnParentChanged: false,
        },
      },
      {
        id: 'trim_color_id',
        label: 'Trim Exterior Color',
        type: InputType.materials,
        required: false,
        settings: {
          description: "Select the home's trim color",
          options: [],
          defaultMaterial: 'trim',
          enabled: true,
          toolTipMessage:
            'Unfortunately, you have no materials assigned for this material type. Please reach out to XO Help if you would like to add materials to your selections',
          filterByCategory: false,
          clearOnParentChanged: false,
        },
      },
      {
        id: 'door_color_id',
        label: 'Door Exterior Color',
        type: InputType.materials,
        required: false,
        settings: {
          description: "Select the home's door color",
          options: [],
          defaultMaterial: 'siding',
          enabled: true,
          toolTipMessage:
            'Unfortunately, you have no materials assigned for this material type. Please reach out to XO Help if you would like to add materials to your selections',
          filterByCategory: false,
          clearOnParentChanged: false,
        },
      },
    ]
  }
}
