const planRoutes = {
  plans: '/plans',
  planPanel: (slug) => `/plans/${slug}`,
  addElevation: (slug) => `/plans/${slug}/elevations/add`,
  editElevation: (planSlug, elevationSlug) =>
    `/plans/${planSlug}/elevations/${elevationSlug}/edit`,
  addPlan: '/plans/add',
  editPlan: (slug) => `/plans/${slug}/edit`,
  reviewElevation: (slug) => `/plans/elevations/${slug}/review`,
}

const lotsRoutes = {
  lots: '/lots',
  lotPanel: (slug) => `/lots/${slug}`,
  lotManagement: (slug) => `/lots/${slug}/lot_management`,
  specForm: (slug) => `/lots/${slug}/spec`,
  buildForm: (slug) => `/lots/${slug}/sales/build`,
  contractForm: (slug) => `/lots/${slug}/sales/contract`,
  cancelSaleForm: (slug, id) => `/lots/${slug}/sales/${id}/cancel`,
  closeHomeForm: (slug, id) => `/lots/${slug}/sales/${id}/close_home`,
  reviewArchitecturalSubmission: (slug, id) =>
    `/lots/${slug}/architecturals/${id}/review`,
  construction: (slug) => `/lots/${slug}/construction/`,
}

const neighbors = {
  neighborsMapEditor: '/neighbors/editor',
}

const routes = {
  index: '/',
  login: '/login',
  passwordReset: '/password/reset',
  passwordResetDone: '/password/reset/done',
  sale: '/sale',
  archSubmissions: '/architectural_submissions',
  health_check: '/health_check',
  ...lotsRoutes,
  ...planRoutes,
  ...neighbors,
}

export default routes
