









import { Vue, Component, Prop } from 'nuxt-property-decorator'

@Component({})
export default class FiltersBox extends Vue {
  @Prop({ default: '' }) text: string
}
