















































































































































import {
  Component,
  Emit,
  Prop,
  Vue,
  Watch,
  InjectReactive,
} from 'nuxt-property-decorator'
import { mapGetters } from 'vuex'
import SelectInput from './SelectInput.vue'
import TitleInput from './TitleInput.vue'
import MaterialIcon from '~/components/shared/MaterialIcon.vue'
import MaterialCard from '~/components/materials/MaterialCard.vue'
import ListRow from '~/components/shared/list/ListRow.vue'
import ActionAlert from '~/components/shared/alerts/ActionAlert.vue'
import { Lot, Material, Project } from '~/models'
import { didTheScrollReachTheBottom } from '~/utils/scroll'
import BottomLoader from '~/components/shared/BottomLoader.vue'
import { getMaterial, searchMaterials } from '~/api/materials.api'
import Plan from '~/models/plan'
import ToggleInput from '~/components/shared/inputs/ToggleInput.vue'

@Component({
  components: {
    MaterialCard,
    MaterialIcon,
    ListRow,
    SelectInput,
    ActionAlert,
    TitleInput,
    BottomLoader,
    ToggleInput,
  },
  computed: {
    ...mapGetters('user', {
      project: 'project',
      isSpecialFeatureEnabled: 'isSpecialFeatureEnabled',
      isMatrixMaterials: 'isMatrixMaterials',
    }),
  },
})
export default class MaterialInput extends Vue {
  isMatrixMaterials: boolean
  selected: number
  showAlert: boolean = false
  selectedCategory: string = ''
  filteredMaterials: Material[] = []
  materialsContainer: string = 'materialsContainer'
  materialsListContainer: string = 'materialsListContainer'
  selectedMaterial: Material = null
  project: Project
  titleMaterial: string = ''

  @InjectReactive() readonly secondaryMaterialLabel: string
  isSpecialFeatureEnabled: boolean

  search: string = ''
  scrollDebounce: Function
  onSearchMaterial: Function = null

  @Prop()
  id: any

  @Prop()
  heapId: string

  @Prop()
  label: string

  @Prop({ default: 0 })
  value: number

  @Prop({ default: (): Material[] => [] })
  materials: Material[]

  @Prop()
  description: string

  @Prop({ default: false })
  showSelectors: boolean

  @Prop()
  defaultMaterial: string

  @Prop({ default: (): any => [] })
  availableCagegories: string[]

  @Prop({ default: false })
  required: boolean

  @Prop({ default: true })
  filterMaterials: boolean

  @Prop({ default: true })
  enabled: boolean

  @Prop({ default: '' })
  toolTipMessage: string

  @Prop({ default: true })
  filterByCategory: boolean

  @Prop({ default: false })
  clearOnParentChanged: boolean

  @Prop({ default: false })
  isLoading: boolean

  @Prop({ default: null })
  plan: Plan

  @Prop({ default: null })
  lot: Lot

  @Prop({ default: false })
  isVarianceSelected: boolean

  variance: boolean = false

  constructor() {
    super()
    this.selected = this.value
    this.titleMaterial = this.label
    this.variance = this.isVarianceSelected
    this.scrollDebounce = this.debounce(this.handleScroll, 1000)
    this.onSearchMaterial = this.debounceMaterialsSearch(
      this.searchMaterial,
      500
    )
  }

  mounted() {
    const root: any = this.$root
    if (root) {
      root.$on('clear_material_selected', () => this.clearSelectedMaterial())
    }
  }

  get hasMaterials(): boolean {
    const filteredMaterialsCount: number = this.filteredMaterials.length
    const materialsCount: number = this.materials.length

    if (
      this.isMatrixMaterials &&
      this.id === 'secondary_materials_ids' &&
      this.search
    ) {
      return true
    }

    if (filteredMaterialsCount <= 0 && materialsCount <= 0) {
      return false
    }

    if (this.search.length > 0 && filteredMaterialsCount <= 0) {
      return true
    }

    return filteredMaterialsCount > 0
  }

  get tippyOption(): any {
    return {
      trigger: this.hasMaterials ? '' : 'mouseenter',
    }
  }

  get isEnabled(): boolean {
    return this.enabled && this.hasMaterials
  }

  get showVariance(): boolean {
    if (!this.project.config) {
      return false
    }
    const allowedMaterials: Array<string> = [
      // 'secondary_materials_ids',
      // 'primary_material_id',
    ]

    return (
      this.project.config.enable_spec_variance &&
      allowedMaterials.includes(this.id)
    )
  }

  // TODO: Remove this watch because it was added to get varince logic from plans toggle
  @Watch('isVarianceSelected')
  onPlansVarianceChanged(): void {
    this.variance = this.isVarianceSelected
  }

  @Watch('search')
  onSearchChanged(): void {
    if (!this.onSearchMaterial && !this.plan) {
      return
    }

    this.onSearchMaterial(
      this.search.toLowerCase(),
      this.plan,
      this.lot,
      this.variance
    )
  }

  @Watch('materials')
  onMaterialChanged(): void {
    this.setMaterial()
    this.filterMaterial(this.selectedCategory || this.defaultMaterial)
  }

  @Watch('value')
  onValueChanged(): void {
    this.selected = this.value
  }

  @Watch('defaultMaterial')
  onDefaultChanged(): void {
    this.filterMaterial(this.defaultMaterial)
  }

  @Watch('selectedCategory')
  onSelectCategoryChanged(): void {
    if (this.filterMaterials) {
      this.filterMaterial(this.selectedCategory)
    } else {
      this.onCategoryChanged()
    }
  }

  @Watch('secondaryMaterialLabel')
  onLabelMaterialchanged(): void {
    if (this.isSpecialFeatureEnabled && this.id === 'secondary_materials_ids') {
      this.titleMaterial = this.secondaryMaterialLabel
    }
  }

  @Emit('input')
  handleClick(): number {
    return this.selected
  }

  @Emit('nextPage')
  handleScroll(event: any, retrieveMoreMaterials: boolean = false): boolean {
    if (this.search) {
      return false
    }

    if (retrieveMoreMaterials) {
      return true
    }

    const materialsListHeight: number = (
      this.$refs.materialsListContainer as HTMLElement
    ).clientHeight

    return didTheScrollReachTheBottom(event.target, materialsListHeight)
  }

  @Emit('categoryChanged')
  onCategoryChanged(): string {
    return this.selectedCategory
  }

  @Emit('validateField')
  handleBlur(): any {}

  async setMaterial(): Promise<void> {
    let material: Material = this.materials.find(
      (material) => material.id === this.selected
    )

    try {
      if (!material) {
        if (this.selected) {
          material = await getMaterial(this.selected)
        }
      }
    } catch (error) {
      console.log(error)
    }

    this.selectedMaterial = material
  }

  get materialCategories(): any[] {
    return this.availableCagegories.map((category) => ({
      value: category,
      label: category,
    }))
  }

  materialSelected(materialId: number): void {
    if (this.selected === materialId) this.selected = null
    else {
      this.selected = materialId
      this.setMaterial()
    }
  }

  onAlertClose(value: boolean): void {
    if (!value) {
      this.selected = null
      this.selectedMaterial = null
    }
    this.handleClick()
    this.showAlert = false
    this.search = ''
  }

  categoryChanged($event: any): void {
    this.selectedCategory = $event?.target
      ? $event.target.value === -1
        ? this.defaultMaterial
        : $event.target.value
      : $event
    if (this.selectedMaterial || this.selected) {
      this.selected = null
      this.selectedMaterial = null
      this.handleClick()
    }
  }

  async searchMaterial(
    name: string,
    plan: Plan,
    lot: Lot,
    variance: boolean,
    category: string
  ): Promise<void> {
    try {
      let enableAM: boolean = true
      if (!this.project.config.enable_spec_variance && variance) {
        enableAM = false
      }

      const data: any = {
        plan_id: plan.id,
        lot_id: lot.id,
        category,
        check_am: enableAM,
      }

      if (this.isMatrixMaterials && this.id === 'secondary_materials_ids') {
        data.name = name
        this.searchMatrixMaterial(data)
      } else {
        if (this.id === 'trim_color_id') {
          data.check_am = false
        }
        const response = await searchMaterials(name, data)
        this.filteredMaterials = response
      }
    } catch (error) {
      console.log(`Error searching material: ${error}`)
    }
  }

  @Emit('onSearchMatrixMaterial')
  searchMatrixMaterial(data: any): any {
    return data
  }

  filterMaterial(category: string): void {
    const uniqueIds = new Set()
    const uniqueArray = this.materials.filter((obj) => {
      if (!uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id)
        return true
      }
      return false
    })

    if (this.filterByCategory) {
      this.filteredMaterials = uniqueArray.filter((material) =>
        material.types?.includes(category)
      )
    } else {
      this.filteredMaterials = uniqueArray
    }
  }

  showMaterialsList(): void {
    if (!this.hasMaterials) {
      return
    }
    this.showAlert = true
  }

  retrieveMoreItems(): void {
    if (this.$refs.materialsContainer && this.$refs.materialsListContainer) {
      const containerHeight: number = (
        this.$refs.materialsContainer as HTMLElement
      ).clientHeight

      const materialsListHeight: number = (
        this.$refs.materialsListContainer as HTMLElement
      ).clientHeight

      if (containerHeight >= materialsListHeight) {
        this.handleScroll(null, true)
      }
    }
  }

  clearSelectedMaterial() {
    if (this.clearOnParentChanged) {
      this.selectedMaterial = null
      this.selected = null
    }
  }

  debounceMaterialsSearch(callFunction: Function, wait: number): Function {
    let timeout: any = null
    return (name: string, plan: Plan, lot: Lot, variance: boolean): void => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        timeout = null
        if (!lot) {
          return
        }

        if (!plan) {
          return
        }

        const category: string = this.selectedCategory || this.defaultMaterial
        if (!this.search && !this.isMatrixMaterials) {
          this.filterMaterial(category)
        } else {
          callFunction(name, plan, lot, variance, category)
        }
      }, wait)
    }
  }

  debounce(callFunction: any, delay: number): Function {
    let timeout: any = null
    return (event: any, retrieveMoreMaterials: boolean = false): void => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        callFunction(event, retrieveMoreMaterials)
      }, delay)
    }
  }

  handleSubmit(event: InputEvent): Event {
    // @ts-ignore
    if (event.key === 'Enter' || event.keyCode === 13) {
      event.preventDefault()
    }
    return event
  }

  @Watch('variance')
  @Emit('onMaterialVarianceChanged')
  onVarianceChanged(): boolean {
    if (this.selectedMaterial && this.selectedMaterial.disabled) {
      this.selected = null
      this.selectedMaterial = null
      this.handleClick()
    }
    return this.variance
  }

  onToggleVariance(value: boolean): void {
    this.variance = value
  }
}
